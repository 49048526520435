import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/Home.vue')
  },
  {
    path: '/Home2',
    name: 'Home2',
    component: () => import('../views/home/Home2.vue')
  },
  {
    path: '/Official',
    name: 'Official',
    component: () => import('../views/official/Official.vue')
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../views/user/User.vue')
  },
  {
    path: '/Wallet',
    name: 'Wallet',
    component: () => import('../views/wallet/Wallet.vue')
  },
  {
    path: '/Chongzhi',
    name: 'Chongzhi',
    component: () => import('../views/wallet/Chongzhi.vue')
  },
  {
    path: '/Tixian',
    name: 'Tixian',
    component: () => import('../views/wallet/Tixian.vue')
  },
  {
    path: '/Bill',
    name: 'Bill',
    component: () => import('../views/wallet/Bill.vue')
  },
  {
    path: '/Mining',
    name: 'Mining',
    component: () => import('../views/mining/Mining.vue')
  },
  {
    path: '/AiData',
    name: 'AiData',
    component: () => import('../views/ai/AiData.vue')
  },
  {
    path: '/Ai',
    name: 'Ai',
    component: () => import('../views/ai/index.vue')
  },
  {
    path: '/Notice',
    name: 'Notice',
    component: () => import('../views/ai/Notice.vue')
  },
  {
    path: '/NoticeDetails',
    name: 'NoticeDetails',
    component: () => import('../views/ai/NoticeDetails.vue')
  },
  {
    path: '/RobotUserRecord',
    name: 'RobotUserRecord',
    component: () => import('../views/mining/RobotUserRecord.vue')
  },
  {
    path: '/Help',
    name: 'Help',
    component: () => import('../views/official/Help.vue')
  },
  {
    path: '/NodeRecord',
    name: 'NodeRecord',
    component: () => import('../views/wallet/NodeRecord.vue')
  },
  {
    path: '/Market',
    name: 'Market',
    component: () => import('../views/market/Market.vue')
  },
  {
    path: '/Bonus',
    name: 'Bonus',
    component: () => import('../views/wallet/Bonus.vue')
  },
  {
    path: '/Team',
    name: 'Team',
    component: () => import('../views/user/Team.vue')
  },
  {
    path: '/Convert',
    name: 'Convert',
    component: () => import('../views/wallet/Convert.vue')
  },
  {
    path: '/TeamCount',
    name: 'TeamCount',
    component: () => import('../views/user/TeamCount.vue')
  },
  {
    path: '/NodePower',
    name: 'NodePower',
    component: () => import('../views/wallet/NodePower.vue')
  },
  {
    path: '/ChongzhiRecord',
    name: 'ChongzhiRecord',
    component: () => import('../views/wallet/ChongzhiRecord.vue')
  },
  {
    path: '/TixianRecord',
    name: 'TixianRecord',
    component: () => import('../views/wallet/TixianRecord.vue')
  },
  {
    path: '/Zhuanzhang',
    name: 'Zhuanzhang',
    component: () => import('../views/wallet/Zhuanzhang.vue')
  },
  {
    path: '/ZhuanzhangRecord',
    name: 'ZhuanzhangRecord',
    component: () => import('../views/wallet/ZhuanzhangRecord.vue')
  },
  {
    path: '/Trade',
    name: 'Trade',
    component: () => import('../views/trade/index.vue')
  },
  {
    path: '/Shandui',
    name: 'Shandui',
    component: () => import('../views/wallet/Shandui.vue')
  },
  {
    path: '/AiTradeDetail',
    name: 'AiTradeDetail',
    component: () => import('../views/ai/AiTradeDetail.vue')
  },
  {
    path: '/Subscribe',
    name: 'Subscribe',
    component: () => import('../views/ai/Subscribe.vue')
  },
  {
    path: '/aiLogin',
    name: 'aiLogin',
    component: () => import('../views/ai/aiLogin.vue')
  },
  {
    path: '/Trading',
    name: 'Trading',
    component: () => import('../views/ai/Trading.vue')
  },
  {
    path: '/TradeAccount',
    name: 'TradeAccount',
    component: () => import('../views/ai/TradeAccount.vue')
  },
  {
    path: '/Positions',
    name: 'Positions',
    component: () => import('../views/ai/Positions.vue')
  },
  {
    path: '/ClosedPositions',
    name: 'ClosedPositions',
    component: () => import('../views/ai/ClosedPositions.vue')
  },
  {
    path: '/Database',
    name: 'Database',
    component: () => import('../views/ai/Database.vue')
  },
  {
    path: '/AiConfig',
    name: 'AiConfig',
    component: () => import('../views/ai/AiConfig.vue')
  },
  {
    path: '/PledgeRecord',
    name: 'PledgeRecord',
    component: () => import('../views/pledge/PledgeRecord.vue')
  },
  {
    path: '/PledgeTest',
    name: 'PledgeTest',
    component: () => import('../views/pledge/PledgeTest.vue')
  },
  {
    path: '/NodeOrder',
    name: 'NodeOrder',
    component: () => import('../views/node/NodeOrder.vue')
  },
  {
    path: '/NewRobotOrder',
    name: 'NewRobotOrder',
    component: () => import('../views/newrobot/NewRobotOrder.vue')
  },
  {
    path: '/PledgeOrder',
    name: 'PledgeOrder',
    component: () => import('../views/pledge/PledgeOrder.vue')
  },
  {
    path: '/PledgeCcaiOrder',
    name: 'PledgeCcaiOrder',
    component: () => import('../views/pledgeCcai/PledgeCcaiOrder.vue')
  },
  {
    path: '/PledgeUsdaOrder',
    name: 'PledgeUsdaOrder',
    component: () => import('../views/pledgeUsda/PledgeUsdaOrder.vue')
  },
  {
    path: '/Jingcai',
    name: 'Jingcai',
    component: () => import('../views/jingcai/index.vue')
  },
  {
    path: '/JingcaiRecord',
    name: 'JingcaiRecord',
    component: () => import('../views/jingcai/JingcaiRecord.vue')
  },
  {
    path: '/JingcaiSource',
    name: 'JingcaiSource',
    component: () => import('../views/jingcai/JingcaiSource.vue')
  },
  {
    path: '/SwapOrderRecord',
    name: 'SwapOrderRecord',
    component: () => import('../views/swap/SwapOrderRecord.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, form ,next) => {
  document.body.scrollTop = 0;
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  next()
})

export default router
