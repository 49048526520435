<template>
  <div>
    <div id="echarts" class="echarts"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import chartTestData from '@/assets/json/chartTestData'
import { ref } from 'vue';

export default {
  setup() {
    return {
      chatData: ref(null),
      upColor: "#25AF8E",
      downColor: "#D24963",
      myChart: null
    }
  },
  mounted() {
    this.chatData = chartTestData.list
    this.initChart()
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
    if(this.myChart) {
      this.myChart.dispose()
    }
  },
  methods: {
    // 屏幕变化触发
    handleResize() {
      this.myChart.resize()
    },
    // 格式时间
    formatData(val) {
      let date = new Date(val)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let mm = date.getMinutes()
      if (h < 10) {
        h = "0" + h
      }
      if (mm < 10) {
        mm = "0" + mm
      }
      return y + "-" + m + "-" + d + " " + h + ":" + mm
    },
    splitData(rawData) {
      let categoryData = [];
      let values = [];
      let volumes = [];
      for (let i = 0; i < rawData.length; i++) {
        categoryData.push(rawData[i].splice(0, 1)[0]);
        values.push(rawData[i]);
        volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
      }
      return {
        categoryData: categoryData,
        values: values,
        volumes: volumes
      };
    },
    calculateMA(dayCount, data) {
      var result = [];
      for (var i = 0, len = data.values.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        var sum = 0;
        for (var j = 0; j < dayCount; j++) {
          sum += parseFloat(data.values[i - j][1]);
        }
        result.push(+(sum / dayCount).toFixed(3));
      }
      return result;
    },
    // 初始化 echart
    initChart() {
      let _this = this
      let data = JSON.stringify(this.chatData)
      data = JSON.parse(data)
      data.forEach(item => {
        item[0] = this.formatData(item[0])
      })
      data = this.splitData(data)
      this.myChart = echarts.init(document.getElementById('echarts'))
      let option = {
        animation: false,
        legend: {
          bottom: 60,
          left: 'center',
          data: ['Dow-Jones index ', 'MA5', 'MA10', 'MA20', 'MA30'],
          textStyle: {
            color: "#999"
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          borderWidth: 1,
          borderColor: '#ccc',
          padding: 10,
          textStyle: {
            color: '#000'
          },
          position: function (pos, params, el, elRect, size) {
            const obj = {
              top: 10
            };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
          },
        },
        axisPointer: {
          link: [{
            xAxisIndex: 'all'
          }],
          label: {
            backgroundColor: '#777'
          }
        },
        toolbox: {
          show: false
        },
        brush: {
          xAxisIndex: 'all',
          brushLink: 'all',
          outOfBrush: {
            colorAlpha: 0.1
          }
        },
        visualMap: {
          show: false,
          seriesIndex: 5,
          dimension: 2,
          pieces: [{
            value: 1,
            color: _this.downColor
          },
          {
            value: -1,
            color: _this.upColor
          }
          ]
        },
        grid: [{
          top: "4%",
          left: '15%',
          right: '4%',
          height: '50%'
        },
        {
          left: '10%',
          right: '8%',
          top: '60%',
          height: '16%'
        }
        ],
        xAxis: [{
          type: 'category',
          data: data.categoryData,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#999",
            }
          },
          splitLine: {
            show: false
          },
          axisPointer: {
            z: 100
          }
        },
        {
          type: 'category',
          gridIndex: 1,
          data: data.categoryData,
          axisLine: {
            onZero: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
        }
        ],
        yAxis: [{
          scale: true,
          splitLine: {
            lineStyle: {
              color: "#202d3b"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#999", //Y轴内容文字颜色
            },
          },
        },
        {
          scale: true,
          gridIndex: 1,
          splitNumber: 2,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
        ],
        dataZoom: [{
          type: 'inside',
          xAxisIndex: [0, 1],
          start: 70,
          end: 100
        },
        {
          show: false,
          xAxisIndex: [0, 1],
          type: 'slider',
          top: '85%',
          start: 98,
          end: 100
        }
        ],
        series: [{
          name: 'BTC-USDT',
          type: 'candlestick',
          data: data.values,
          itemStyle: {
            color: _this.upColor,
            color0: _this.downColor,
            borderColor: undefined,
            borderColor0: undefined
          }
        },
        {
          name: 'MA5',
          type: 'line',
          data: _this.calculateMA(5, data),
          smooth: true,
          showAllSymbol: false,
          symbolSize: 0,
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'MA10',
          type: 'line',
          data: _this.calculateMA(10, data),
          smooth: true,
          showAllSymbol: false,
          symbolSize: 0,
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'MA20',
          type: 'line',
          data: _this.calculateMA(20, data),
          smooth: true,
          showAllSymbol: false,
          symbolSize: 0,
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'MA30',
          type: 'line',
          data: _this.calculateMA(30, data),
          smooth: true,
          showAllSymbol: false,
          symbolSize: 0,
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: data.volumes
        }
        ]
      }
      _this.myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 500px;
}
</style>