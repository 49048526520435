<template>
  <div>
    <!-- <header-bar :showNotice="false" :showBack="true" :showLand="false"></header-bar> -->
    <div class="header-bar">
      <div class="tops-heads">
        <img src="@/assets/img/logo-text.png" width="100" />
        <div class="tops-row">
          <van-popover v-model:show="showPopover" :actions="actions" placement="bottom-end" @select="onSelect">
						<template #reference>
							<q-icon name="menu" size="40px"></q-icon>
						</template>
					</van-popover>
        </div>
      </div>

      <div class="back-wrap">
        <img src="@/assets/img/base/back.png" width="48" @click="back" />
      </div>
    </div>

    <div class="box">
      <div class="content" v-html="articlecontent"></div>
    </div>
  </div>
</template>

<script>
// import HeaderBar from '@/components/HeaderBar'
import { ref } from 'vue'
import { Popover } from 'vant';

export default {
  name: '',
  components: {
    // HeaderBar
    [Popover.name]: Popover,
  },
  setup() {
    return {
      select_id: ref(""),
      articlecontent: ref(""),
      showPopover: ref(false),
      actions: [
				{text: "White Paper", lx: 2, val: "https://edison-6.gitbook.io/edison_ai"},
				{text: "Management Regulations", lx: 1,  val: 2},
				{text: "Model Charter", lx: 1,  val: 12},
				{text: "License", lx: 1,  val: 11},
				{text: "Privacy Policy", lx: 1,  val: 9},
				{text: "About Us", lx: 1,  val: 1},
				// {text: "APP Download", lx: 3,  val: ""},
			],
    }
  },
  created() {
    this.select_id = this.$route.query.id
    this.getArticle()
  },
  methods: {
    back() {
      this.$router.back()
    },
    onSelect(e) {
			if(e.lx == 1) {
        if(this.$route.name == "Help") {
          this.select_id = e.val
          this.getArticle()
          return
        }
				this.$router.push("Help?id=" + e.val)
			}
			if(e.lx == 2) {
				window.open(e.val)
			}
			if(e.lx == 3) {
				this.$q.dialog({
					message: "Coming soon",
				});
			}
		},
    getArticle() {
      let _this = this
      _this.$request.post(
        "api/Article/Get",
        {
          select_id: _this.select_id
        },
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          _this.articlecontent = data.articlecontent
        }
      )
    },
  }
}
</script>

<style scoped>
.box {
  margin-top: -80px;
  padding: 10px;
}

.content {
  padding: 15px;
  margin-bottom: 14px;
  border-radius: 10px;
  background-color: #fff;
}

.news-title {
  font-weight: bold;
}

.news-date {
  padding: 10px 0;
  color: gray;
  font-size: 13px;
}

.header-bar {
  box-sizing: border-box;
  padding: 30px 12px 16px;
  min-height: 200px;
  background: url(~@/assets/img/base/header-bar.png) no-repeat;
  background-size: 100% auto;
  background-color: #f4f4f9;
}

.transparent {
  background: transparent;
}

.tops-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tops-row {
  display: flex;
  align-items: center;
}

.back-wrap {
  margin-top: 10px;
}


@media screen and (min-width: 1000px) {
  .content {
  font-size: 16px;
}
}
</style>