<template>
  <div class="convert">
    <header-bar :showNotice="false" :showBack="true" :transparent="true"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Convert.mairu')" label="Buy" v-if="lx == 1"></title-name>
      <title-name :title="$t('Convert.maichu')" label="Flash exchange" v-if="lx == 2"></title-name>
      <title-name :title="$t('Convert.shandui')" label="Flash exchange" v-if="lx == 3"></title-name>
    </div>

    <div class="form">
      <div class="form-item">
        <div class="form-label">
          <div>{{ fromWallet.cnameZh }}</div>
          <div>{{ $t('Convert.yue') }}：{{ fromWallet.jine }}</div>
        </div>
        <div class="input-wrap">
          <q-input borderless v-model="num" :placeholder="$t('Convert.placeholder1')" @update:model-value="numChange">
            <template v-slot:prepend>
              <img src="@/assets/img/logo.png" v-if="fromWallet.cid == 6" />
              <img src="@/assets/img/base/usdt.svg" v-if="fromWallet.cid == 2" />
              <img src="@/assets/img/base/usdt.svg" v-if="fromWallet.cid == 3" />
            </template>
          </q-input>
        </div>
      </div>
      <div class="text-center q-mt-md">
        <img src="@/assets/img/wallet/convert.svg" width="20" />
      </div>
      <div class="form-item q-mt-sm">
        <div class="form-label">
          <div>{{ toWallet.cnameZh }}</div>
          <div>{{ $t('Convert.yue') }}：{{ toWallet.jine }}</div>
        </div>
        <div class="input-wrap">
          <q-input borderless v-model="num2" placeholder="" readonly>
            <template v-slot:prepend>
              <img src="@/assets/img/logo.png" v-if="toWallet.cid == 6" />
              <img src="@/assets/img/base/usdt.svg" v-else />
            </template>
          </q-input>
        </div>
      </div>

      <div class="footer-tip">
        <div v-if="lx == 1">{{ rged }}%{{ $t('Convert.edu') }}：{{ availablelimit }}</div>
        <div v-if="lx == 2">{{ $t('Convert.sxf') }}：{{ sxf }}%</div>
        <div v-if="lx == 3">{{ sxfbili }}%{{ $t('Convert.ranliao') }}</div>
        <div>1CCAI ≈ {{ edsPrice.toFixed(6) }}USDT</div>
      </div>
    </div>

    <div class="btn" v-if="lx == 1" @click="submit">{{ $t('Convert.querenmairu') }}</div>
    <div class="btn" v-if="lx == 2" @click="submit">{{ $t('Convert.querenmaichu') }}</div>
    <div class="btn" v-if="lx == 3" @click="zhuanhuan">{{ $t('Convert.shandui') }}</div>
  </div>
</template>

<script>
import { ref } from 'vue'
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'

import Web3Helper from "../../assets/js/web3helper.js";

export default {
  name: '',
  components: {
    HeaderBar,
    TitleName
  },
  setup() {
    return {
      us: ref(null),
      lx: ref(""),
      fromWallet: ref({}),
      toWallet: ref({}),
      num: ref(""),
      num2: ref(""),
      edsPrice: ref(0),
      sxf: ref(0),
      availablelimit: ref(0),
      zhSelect: ref({}),
      sxfbili: ref(0),
      rged: ref(0)
    }
  },
  created() {
    // this.us = JSON.parse(this.$utils.getloc("us"))
    this.lx = this.$route.query.lx
    // this.getWallet()
    // this.getUser()
    // this.getEdsPrice()
    // if(this.lx == 3) {
    //   this.getSelect()
    // }

    if (this.lx == 3) {
      this.$q.dialog({
        message: "CCAI審計中",
      });
      // this.getSelect()
    } else {
      this.us = JSON.parse(this.$utils.getloc("us"))
      this.getWallet()
      this.getUser()
      this.getEdsPrice()
    }
  },
  methods: {
    numChange(e) {
      if (this.lx == 1) {
        this.num2 = this.num / this.edsPrice
      }
      if (this.lx == 2) {
        this.num2 = (this.num - (this.num * this.sxf / 100)) * this.edsPrice
      }
      if (this.lx == 3) {
        this.num2 = this.num * this.zhSelect.bili / 100
      }
    },
    getEdsPrice() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/GetEdsPrice",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          _this.edsPrice = data.edsPrice
          _this.sxf = data.sxf
          _this.rged = data.bili
        }
      )
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/ZhuanhuanSelect",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data.zlist
          _this.sxfbili = res.data.data.sxfbili
          _this.zhSelect = data.find(item => item.id == 1)
        }
      )
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data.us
          _this.availablelimit = data.availablelimit
        }
      )
    },
    getWallet() {
      let _this = this
      _this.$request.post(
        "api/Wallets/GetWallet",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          console.log(data)
          if (_this.lx == 1) {
            _this.fromWallet = data.find(item => item.cid == 3)
            _this.toWallet = data.find(item => item.cid == 6)
          }
          if (_this.lx == 2) {
            _this.fromWallet = data.find(item => item.cid == 6)
            _this.toWallet = data.find(item => item.cid == 2)
          }
          if (_this.lx == 3) {
            _this.fromWallet = data.find(item => item.cid == 3)
            _this.toWallet = data.find(item => item.cid == 2)
          }
        }
      )
    },
    // 买入 / 卖出
    submit() {
      let _this = this
      if (this.num == "") {
        this.$q.dialog({
          message: this.$t('Convert.placeholder1'),
        });
        return
      }
      let message = this.lx == 1 ? this.$t('Convert.message1') : this.$t('Convert.message2')
      this.$q.dialog({
        title: this.$t('Base.tishi'),
        message: message,
        ok: {
          label: this.$t('Base.queding'),
          flat: true,
        },
        cancel: {
          label: this.$t('Base.quxiao'),
          flat: true,
        },
        persistent: true,
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$t('Convert.loading1')
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/WalletsZhuanhuan/BuyorSell",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              jine: _this.num,
              lx: _this.lx,
              signStr
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                message: res.data.msg,
              });
              if (res.data.code == 100) {
                _this.getWallet()
                _this.getUser()
                _this.num = ""
                _this.num2 = ""
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    },
    // 转换
    zhuanhuan() {
      let _this = this
      if (this.num == "") {
        this.$q.dialog({
          message: this.$t('Convert.placeholder1'),
        });
        return
      }
      this.$q.dialog({
        title: this.$t('Base.tishi'),
        message: this.$t('Convert.message3'),
        ok: {
          label: this.$t('Base.queding'),
          flat: true,
        },
        cancel: {
          label: this.$t('Base.quxiao'),
          flat: true,
        },
        persistent: true,
      }).onOk(() => {
        _this.$q.loading.show({
          message: _this.$t('Convert.loading2')
        })
        let web3Helper = new Web3Helper();
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/WalletsZhuanhuan/Zhuanhuan",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              uid: _this.us.id,
              zhid: _this.zhSelect.id,
              jine: _this.num,
              signStr
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                message: res.data.msg,
              });
              if (res.data.code == 100) {
                _this.getWallet()
                _this.num = ""
                _this.num2 = ""
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.convert {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.form {
  margin: 20px 12px 0;
  padding: 15px;
  border-radius: 12px;
  background-color: #fff;
}

.input-wrap {
  padding: 0 16px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.input-wrap img {
  width: 24px;
}

.form-label {
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

.btn {
  margin: 32px 12px 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 14px;
  color: #fff;
  background-color: #2C8B9F;
}

.footer-tip {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin-top: 15px;
  color: gray;
}

.coin-price {
  margin-top: 10px;
  text-align: right;
}
</style>