<template>
  <div class="tixian">
    <header-bar :showNotice="false" :showBack="true" :transparent="true"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Zhuanzhang.title')" label="Transfer"></title-name>
    </div>

    <div class="menu-item" @click="tolink('ZhuanzhangRecord')">
      <div class="menu-row">
        <img src="@/assets/img/user/menu4.png" class="menu-icon" />
        <div>{{$t('Record.zz')}}</div>
      </div>
      <div class="row items-center">
        <div style="margin-right: 8px;">{{ $t('Zhuanzhang.chakan') }}</div>
        <img src="@/assets/img/user/link.png" width="19" />
      </div>
      <img src="@/assets/img/base/kl.png" class="menu-left" />
      <img src="@/assets/img/base/kr.png" class="menu-right" />
    </div>

    <div class="select" @click="show = true">
      <div v-if="select">{{ select.text }}</div>
      <div v-else>{{ $t('Zhuanzhang.select') }}</div>
      <img src="@/assets/img/base/xia.png" width="14" />
    </div>

    <div class="form">
      <div class="form-title">{{ $t('Zhuanzhang.title') }}</div>
      <div class="form-label">{{ $t('Zhuanzhang.numLabel') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" :placeholder="$t('Zhuanzhang.placeholder')" />
      </div>

      <div class="form-label q-mt-lg">{{ $t('Zhuanzhang.addressLabel') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="suserid" :placeholder="$t('Zhuanzhang.placeholder2')" />
      </div>
      <div class="row justify-between q-mt-md">
        <div>{{ $t('Zhuanzhang.yue') }}：{{ select ? select.jine : 0 }}</div>
        <div style="color: gray;">{{ $t('Zhuanzhang.sxf') }}：{{ select ? select.shouxu : 0 }}%</div>
      </div>
    </div>

    <div class="btn" @click="submit">{{ $t('Zhuanzhang.queding') }}</div>

    <!-- 选择币种 -->
    <van-popup v-model:show="show" round position="bottom">
      <van-picker
        :columns="selectList"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import { ref } from 'vue'
import { Picker, Popup } from 'vant';

import Web3Helper from "../../assets/js/web3helper.js";

export default {
  name: '',
  components: {
    HeaderBar,
    TitleName,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      show: ref(false),
      select: ref(null),
      selectList: ref([]),
      suserid: ref(""),
      cid: ref("")
    }
  },
  created() {
    this.cid = this.$route.query.id
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getSelect()
  },
  methods: {
    back() {
      this.$router.back()
    },
    tolink(path) {
      this.$router.push(path)
    },
    onConfirm(e) {
      this.select = e
      this.show = false
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanzhang/ZhuanzhangSelect",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid
        },
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          data.forEach(item => {
            item.text = item.coinname
            item.value = item.cid
          })
          _this.selectList = data
          let _select = _this.selectList.find(item => item.cid == _this.cid)
          _this.select = _select
        }
      )
    },
    submit() {
      let _this = this
      if(!this.select) {
        this.$q.dialog({
          message: this.$t('Zhuanzhang.message1'),
        });
        return
      }
      if(this.num == "") {
        this.$q.dialog({
          message: this.$t('Zhuanzhang.message2'),
        });
        return
      }
      if(this.suserid == "") {
        this.$q.dialog({
          message: this.$t('Zhuanzhang.addressLabel'),
        });
        return
      }
      this.$q.dialog({
        title: this.$t('Base.tishi'),
        message: this.$t('Zhuanzhang.message3'),
        ok: {
          label: this.$t('Base.queding'),
          flat: true,
        },
        cancel: {
          label: this.$t('Base.quxiao'),
          flat: true,
        },
        persistent: true,
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$t('Zhuanzhang.loading')
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/WalletsZhuanzhang/Zhuanzhang",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              uid: _this.us.id,
              jine: _this.num,
              cid: _this.select.cid,
              signStr: signStr,
              suserid: _this.suserid,
              beizhu: ""
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                message: res.data.msg,
              });
              if(res.data.code == 100) {
                _this.select = null
                _this.getSelect()
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    },
  }
}
</script>

<style scoped>
.tixian {
  padding: 0 0 20px 0;
  min-height: 100vh;
  background: url('~@/assets/img/base/banner-background.jpg') no-repeat;
  background-size: 100% 100%;
}

.back-wrap {
  padding: 0 12px;
}

.page-content {
  margin: 24px 12px 0;
  padding: 0 1px;
  box-sizing: border-box;
  height: 57px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  border-radius: 16px;
  background-color: #000;
}

.page-item {
  height: 55px;
  line-height: 55px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
}

.page-item-active {
  color: #000;
  background-color: #91eb0d;
}

.menu-item {
  position: relative;
  margin: 24px 12px 0;
  padding: 0 20px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid #000;;
  background-color: #fff;
}

.menu-row {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.menu-left {
  position: absolute;
  height: 85px;
  width: 60px;
  top: -13px;
  left: -12px;
}

.menu-right {
  position: absolute;
  height: 85px;
  width: 60px;
  top: -13px;
  right: -12px;
}

.select {
  padding: 0 20px;
  margin: 29px 12px 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
}

.form {
  margin: 32px 12px 0;
  padding: 20px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
}

.form-title {
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: bold;
}

.form-label {
  margin-bottom: 6px;
}

.input-wrap {
  padding: 0 16px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.btn {
  margin: 32px 12px 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 14px;
  color: #fff;
  background-color: #6EADFD;
}
</style>