<template>
    <div class="record">
        <header-bar :showNotice="false" :showBack="true" :transparent="true"
            @setLanguage="setLanguageAfter"></header-bar>

        <div style="margin-top: -90px;">
            <title-name title="闪兑订单" label=""></title-name>
        </div>

        <div class="list" style="text-align: center;">
            <div class="list-name">
                <div>时间</div>
                <div>用户</div>
                <div>闪兑量</div>
                <div v-if="type == 'self'">手续费</div>
                <div v-if="type == 'self'">状态</div>
            </div>
            <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" finished-text="没有更多了"
                :immediate-check="false" offset="10" @load="onLoad">
                <div v-for="item in list" :key="item.id">
                    <div class="list-val">
                        <div>{{ item.createTime }}</div>
                        <div>{{ abbr(item.userid, 4, 4) }}</div>
                        <div>{{ item.num }}</div>
                        <div v-if="type == 'self'">{{ item.feeAmount }}</div>
                        <div v-if="type == 'self'">{{ item.stateName }}</div>
                    </div>
                </div>

            </van-list>
            <empty :text="$t('RobotUserRecord.empty')" v-if="list.length == 0" />
        </div>
    </div>
</template>

<script>
// import Web3Helper from "@/assets/js/web3helper.js";
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List } from "vant";

export default {
    name: '',
    components: {
        HeaderBar,
        TitleName,
        Empty,
        [List.name]: List,
    },
    setup() {
        return {
            us: ref(null),
            pageIndex: ref(1),
            list: ref([]),
            loading: ref(false),
            finished: ref(false),
            type:ref('all')
        }
    },
    created() {
        this.type = this.$route.query.type;
        this.us = JSON.parse(this.$utils.getloc("us"))
        this.getdata()
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageIndex++
            this.getdata()
        },
        setLanguageAfter() {
            this.pageIndex = 1
            this.loading = false
            this.finished = false
            this.list = []
            this.getdata()
        },
        getdata() {
            let _this = this
            _this.$request.post(
                "api/SwapOrder/List",
                {
                    token: _this.$utils.getloc("token"),
                    userid: _this.us.userid,
                    uid: _this.us.id,
                    pageIndex: _this.pageIndex,
                    pageSize: 10,
                    type:_this.type
                },
                (res) => {
                    _this.loading = false
                    if (res.data.code == 0) {
                        _this.$q.dialog({
                            message: res.data.msg,
                        });
                    }
                    let data = res.data.data
                    console.log(data)
                    if (data.length == 0) {
                        _this.finished = true
                    } else {
                        _this.finished = false
                    }
                    _this.list.push(...data)
                }
            )
        },
        abbr(val, start, end ) {
			if(val && val.length > 20) {
				let newVal = val.replace(
					val.substring(start, val.length - end),
					"****"
				)
				return newVal
			} else {
				return val
			}
		},
    }
}
</script>

<style scoped>
.record {
    min-height: 100vh;
    background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.list {
    padding: 15px 15px 10px;
}

.list-name {
    display: flex;
    color: gray;
}

.list-name>div {
    flex: 1;
}

.list-val {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.list-val>div {
    flex: 1;
    font-size: 13px;
}

.divestment-btn {
    padding: 3px 12px;
    color: #fff;
    border-radius: 4px;
    background-color: #e02020;
}

.total-row {
    margin-top: 6px;
    display: flex;
}

.total-item {
    padding-bottom: 10px;
    flex: 1;
    text-align: center;
    border-right: 1px solid rgba(231, 228, 228, 0.7);
    border-bottom: 1px solid rgba(231, 228, 228, 0.7);
}

.total-item:last-child {
    border-right: 0;
}

.item-border {
    border-right: 1px solid rgba(255, 255, 255, .5);
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.total-title {
    font-weight: bold;
    font-size: 15px;
}

.operation-btn{
    color: #1287F4;
}
</style>