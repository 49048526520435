<template>
  <div class="header">
    <HeaderBar :showNotice="false" :transparent="true" menulx="2" showLogo="true" />
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'

export default {
  name: "HomeHeadBar",
  components: {
    HeaderBar
  },
  setup() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
.header {
  padding: 0 12px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(12,21,47,.2); */
}

.header :deep(.header-bar) {
  min-height: auto;
  width: 100%;
  padding: 0;
}
</style>