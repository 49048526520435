<template>
  <div class="pool">
    <div class="title">{{ $t('AiData.celue') }}</div>
    <div class="msg">{{ $t('AiData.totalMsg') }}</div>
  
    <div class="block">
      <div class="block-title">{{ $t('AiData.poolStats') }}</div>
      <div class="block-row">
        <div class="block-text">Deposit</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.deposit??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row">
        <div class="block-text">WD USDT</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.wdusdt??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row">
        <div class="block-text">WD ONEX</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.wdonex??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row" style="margin-top: 25px;">
        <div class="block-text">Total WD</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.totalwd??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row">
        <div class="block-text">NPV</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.npv??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <img src="@/assets/img/base/pool.svg" class="pool-icon" />
      <img src="@/assets/img/base/robot.gif" class="robot-gif" />
    </div>

    <div class="block">
      <div class="block-title">{{ $t('AiData.arbitrageStats') }}</div>
      <div class="block-row">
        <div class="block-text">Deposit</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.deposit2??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row">
        <div class="block-text">WD USDT</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.wdusdt2??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row">
        <div class="block-text">WD ONEX</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.wdonex2??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row" style="margin-top: 25px;">
        <div class="block-text">Total WD</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.totalwd2??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <div class="block-row">
        <div class="block-text">NPV</div>
        <img src="@/assets/img/base/usdt.png" class="usdt-icon" />
        <div class="block-val">
          <count-up :end-val="pageData.npv2??0" :decimalPlaces="2"></count-up>
        </div>
      </div>
      <img src="@/assets/img/base/pool.svg" class="pool-icon" />
      <img src="@/assets/img/base/robot.gif" class="robot-gif" />
    </div>
  </div>
</template>

<script>
import CountUp from 'vue-countup-v3'

export default {
  name: 'AiDataPool',
  props: {
    pageData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    CountUp
  }
}
</script>

<style scoped>
  .pool {
    margin-top: 15px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
  }

  .msg {
    font-size: 13px;
  }

  .block {
    position: relative;
    margin-top: 15px;
    padding: 10px;
    border-radius: 12px;
    background-color: #fff;
  }

  .block-row {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .block-text {
    width: 70px;
    font-size: 12px;
    color: gray;
  }

  .usdt-icon {
    width: 22px;
    height: 22px;
  }

  .block-val {
    padding: 0 4px;
    margin-left: 10px;
    width: 127px;
    border: 1px solid rgba(129, 123, 123, 0.4);
    border-radius: 4px;
  }

  .robot-gif {
    position: absolute;
    width: 68px;
    bottom: 10px;
    right: 10px;
  }

  .pool-icon {
    position: absolute;
    width: 70px;
    top: 30px;
    right: 10px;
  }
</style>