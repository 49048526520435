import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'

// To be used on app.use(Quasar, { ... })
export default {
	config: {},
	plugins: {
		
	}
}
