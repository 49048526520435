module.exports =
{
  Base: {
    copy: '복제 성공',
    back: '반환',
    empty: '데이터가 없습니다.',
    empty2: '기록 없음',
    tishi: '힌트',
    queding: '확인',
    quxiao: '취소',
    tijiao: '제출 중...'
  },
  CheckRegister: {
    tuijianren: '추천인',
    placeholder: '추천인 주소를 입력하세요',
    register: '등록',
    addressErr: '등록 주소 가져오기 오류:',
    qingshuruyonghuming: "사용자 이름을 입력하십시오.",
    qingshuruyongmima: "암호를 입력하십시오.",
    dengluzhong: "로그인 중"
  },
  Home: {
    gonggao: "공지",
    rules: '규칙 설명',
    purchaseRecord: '구매 기록',
    select: '수준 선택',
    amount: '구매 금액',
    submit: '구매 노드',
    chongzhi: '즉시 충전',
    referrers: '나의 추천인',
    link: '초대 연결',
    list: '청약 명세서',
    address: '주소',
    listAmount: '인수 금액',
    state: '상태',
    completed: '완료됨',
    message1: '복제 성공',
    message2: '구매하시겠습니까?',
    message3: '구매 중...',
    down: '거리 노드 사재기',
    day: '일',
    hours: '시',
    seconds: "초",
    jieshu: '노드 구매 완료',
    downTitle1: '에디슨 AI 노드 파트너',
    downTitle2: '글로벌 모집 중',
    zhitui: '직추 목록',
    qianggouzhong: '2기 노드 사재기 중',
    minutes: '분할',
    jiedian: '노드 계산력',
    jifaqianneng: '무한한 잠재력을 일으키고 무한한 가능성을 창출합니다',
    jinridapp: 'DAPP로 이동',
    guanyu: '우리에 대해',
    lianjiequkuai: '블록체인 기술과 비즈니스 세계의 실제 요구 사이의 간극을 좁힙니다',
    zhanxianshequ: '커뮤니티 구축과 합의의 힘을 실천에서 보여주는 것',
    sectionmsg1: 'CITADEL AI는 블록체인 선도기업, 혁신가, 개발자 및 국제 기업으로 구성된 글로벌 커뮤니티 연합입니다. 이 연합은 CITADEL AI 블록체인의 성장과 번영을 촉진하기 위한 역할로, 새로운 비즈니스 기회를 개발하고 산업 응용을 촉진하며 학습과 협력을 추진할 것입니다.',
    sectionmsg2: '우리는 CITADEL AI에 대한 깊은 신뢰를 가진 글로벌 커뮤니티를 모았으며, 기업들에게 블록체인 기술과 신흥 기술에 대한 지도, 지원 및 관리를 제공하는 것을 고대하고 있습니다.',
    zhize: '연합의 임무',
    zhizemsg: 'CITADEL AI는 CITADEL AI 생태계의 핵심 역할을 맡고 있으며, 탈중앙화 정신을 따르며 다양한 방법으로 CITADEL AI 블록체인 기술과 생태계의 번영을 촉진할 것입니다.',
    shequ: '커뮤니티 계획',
    shequmsg1: '함께 CITADEL AI의 미래를 만들어 가요',
    shequmsg2: 'CITADEL AI는 CITADEL AI 커뮤니티의 건설과 운영에 지속적으로 중요한 역할을 하고 있습니다. CITADEL AI의 구성원으로서 사용자는 활기찬 커뮤니티에 참여하며 다른 구성원들과 함께 의사 결정에 참여하고 행사를 조직하며 CITADEL AI의 미래 도면을 공동으로 만들어 갈 것입니다.',
    shequmsg3: '탈중앙화 된 Web3 시대로 나아가기',
    shequmsg4: '우리가 추구하는 탈중앙화된 커뮤니티 참여 모델은 Web3의 비전과 완벽히 일치합니다. 이는 CITADEL AI 생태계의 투명성을 높이고 모든 결정의 공정성과 보편성을 보장하는데 일조합니다.',
    qiye: '기업 계획',
    qiyemsg1: '혁신 주도적인 블록체인 솔루션을 기업에 제공합니다',
    qiyemsg2: 'CITADEL AI는 세계 기업과 긴밀히 협력하여 CITADEL AI 블록체인 기술의 무한한 가능성을 탐구하고 있습니다. 우리는 기업에 맞춤형 블록체인 솔루션을 제공하여 그들이 디지털 경제 시대에서 경쟁력을 유지할 수 있도록 지원하고 있습니다.',
    qiyemsg3: 'CITADEL AI 메인넷의 잠재력을 최대한 활용',
    qiyemsg4: '우리는 또한 기업에 대해 CITADEL AI 메인넷의 기술적 우위와 데이터 보안 및 신뢰성 측면에서의 독특한 이점을 이해하고 활용하는 데 전반적인 지원을 제공하여 기업이 CITADEL AI 생태계에서 최상의 경험을 할 수 있도록 합니다.',
    qukuaigonglian: '블록체인 공공 체인',
    xiayidai: 'Web3의 탈중앙화된 다음 세대 공공 체인',
    xinjingji: '새로운 경제 시대의 블록체인 솔루션',
    xinjingjimsg: 'CITADEL AI는 새로운 경제에 맞춰 개발된 블록체인 네트워크로, 독특한 메인 체인 + 다중 사이드 체인 구조를 통해 개발자에게 분산 애플리케이션을 독립적으로 배포하고 운영할 수 있는 공간을 제공하여 리소스 최적화와 효율적인 활용을 실현합니다. 효율적인 병렬 처리 및 합의 메커니즘을 통해 CITADEL AI는 고주파 거래를 강력하게 지원합니다. 동시에 크로스 체인 기술은 체인 간의 원활한 통신과 가치 이전을 보장합니다.',
    kaifgongju: '강력한 거버넌스 및 개발 도구',
    kaifgongjumsg: '다른 비즈니스 시나리오에서는 다양한 거버넌스 모델이 필요합니다. CITADEL AI는 이를 깊이 이해하고 있으며, 의회 거버넌스, 전민 거버넌스 및 협회 거버넌스와 같은 다양한 거버넌스 모델을 제공합니다. 동시에 시스템의 지속적이고 건강한 발전을 보장하기 위해 정교한 인센티브 모델도 설계되었습니다. 또한 CITADEL AI는 개발자들이 애플리케이션을 보다 쉽게 개발, 디버깅 및 배포할 수 있도록 고급 IDE 도구를 제공합니다.',
    zhongxinhua: '탈중앙화된 거버넌스에 기여하기',
    zhongxinhuazeren: 'CITADEL AI 메인넷의 블록 프로듀서로서, CITADEL AI는 중요한 책임을 맡고 있습니다.',
    zhongxinhuali1: '네트워크의 안정성 확보',
    zhongxinhuali2: '합의 결정 최적화',
    zhongxinhuali3: '사이드 체인 노드의 최적 운영 유지',
    zhongxinhuali4: '네트워크의 안정성 유지',
    pianzhng: '새로운 장을 향해',
    pianzhnglabel1: '단순히 블록체인 네트워크 이상의 것',
    pianzhngmsg1: 'CITADEL AI는 탈중앙화된 자율 조직(DAO)의 개념을 받아들이기 위해 노력합니다. 이를 위해 CITADEL AI는 자체 DAO를 설립하고 글로벌 노드 모집 프로그램을 시작했습니다.',
    pianzhnglabel2: 'CITADEL AI는 전 세계에서 20명의 고급 노드를 모집하기 위해 노력합니다',
    pianzhngmsg2: "각 대표는 다른 분야의 엘리트로서 국제 기관이나 특정 분야에서 명성 있는 인물입니다. 이 20명의 대표는 CITADEL AI 생태계의 중요한 문제에 대해 공동으로 참여하고 결정하여 CITADEL AI가 더 높은 발전 수준으로 나아가도록 추진할 것입니다.",
    pianzhnglabel3: "탈중앙화 자치 조직은 블록체인 기술의 중요한 응용 분야입니다.",
    pianzhngmsg3: "그것은 전통적인 중앙 집중식 관리 방식을 버리고 의사 결정 프로세스를 더 민주적이고 투명하게 만듭니다. DAO의 아키텍처에서 각 구성원은 시스템 내에서 기여 또는 보유한 지분에 따라 투표할 수 있으므로 조직의 미래 방향을 공동으로 결정할 수 있습니다.",
    pianzhnglabel4: "CITADEL AI 기금 설립은 더 많은 사람들이 참여할 수 있도록 하기 위해",
    pianzhngmsg4: "CITADEL AI의 발전에 함께 참여하여 CITADEL AI의 영광스러운 역사를 같이 쓰기 위한 것입니다.",
    mubiao: "주요 목표",
    mubiaolabel1: "생태계 큐레이션",
    mubiaomsg1: "CITADEL AI 생태계에서 잠재력이 높은 프로젝트를 선발, 지원 및 홍보합니다.",
    mubiaolabel2: "기술 검증",
    mubiaomsg2: "블록 생성과 거래 검증을 통해 네트워크 보안을 보장합니다.",
    mubiaolabel3: "크로스 체인 조정",
    mubiaomsg3: "다른 블록체인 엔티티와 협력 관계를 구축하여 CITADEL AI의 상호 운용성을 보장하고 최신 산업 표준에 따라 업데이트합니다.",
    mubiaolabel4: "커뮤니티 구축",
    mubiaomsg4: "커뮤니티 개발을 주도하여 공감대를 형성하고 적극적인 상호 작용 공간을 구축하여 CITADEL AI 생태계의 번영을 공동으로 추진합니다.",
    mubiaolabel5: "DAO 운영 및 관리",
    mubiaomsg5: "커뮤니티 제안서의 제출, 토론 및 투표를 감독하여 이러한 제안서가 CITADEL AI의 미션과 가치에 부합하는지 확인합니다.",
    mubiaolabel6: "자금 조달",
    mubiaomsg6: "자금 조달 작업을 감독하여 CITADEL AI 생태계의 성장과 재무 안정성을 보장합니다.",
    time1: "2023년 4분기",
    time2: "2024년 1분기",
    time3: "2024년 2분기",
    time4: "2024년 3분기",
    time5: "2024년 4분기",
    time6: "2025",
    time7: "2026",
    timemsg1: '적극적으로 모집하고 전 세계에서 10만 명의 사용자를 유치하고, 20 개국을 포함합니다.',
    timemsg2: 'Web3의 연구와 구현을 촉진하여 CITADEL AI 생태계를 최신 Web3 개발과 연결합니다.',
    timemsg3: 'Aclevate 펀딩 프로그램을 지원하여 CITADEL AI에 선발된 게임 개발 팀의 인기와 시장 열기를 높여 CITADEL AI 생태계가 게임 금융 분야에서 더 큰 영향력을 가지도록 합니다.',
    timemsg4: '온라인 및 오프라인 회의, 거래 박람회, 커뮤니티 행사 등을 주최하여 더 많은 기업과 사용자가 CITADEL AI에 참여하도록 유도합니다.',
    timemsg5: '글로벌 파트너와의 관계를 강화하여 CITADEL AI 생태계의 발전을 공동으로 추진합니다.',
    timemsg6: 'GameFi 연합을 구축하여 CITADEL AI가 Play-to-Earn 게임 및 기타 GameFi 혁신의 선도주자가 되도록 합니다.',
    timemsg7: '전 세계에서 50만 명의 사용자를 성공적으로 유치하고, 적어도 50 개국에 걸쳐 CITADEL AI 생태계에 30 개 이상의 다양한 분야의 새로운 프로젝트를 추가하고 지속적으로 CITADEL AI 생태계의 기능과 다양성을 확장하고 강화하는 데 도움이 됩니다.',
    timemsg8: 'CITADEL AI 인큐베이터 구축을 지원하여 혁신 프로젝트가 CITADEL AI 공공 체인에서 발전할 수 있도록 합니다.',
    timemsg9: 'SocialFi 생태계를 진전시켜 CITADEL AI가 사회 금융 및 관련 혁신의 선두주자가 되도록 합니다.',
    timemsg10: 'CITADEL AI 아카데미 설립을 통해 교육 자원과 교육을 제공하여 커뮤니티 구성원의 블록체인 지식과 기술을 강화합니다.',
    timemsg11: '다양한 커뮤니티 바운티 프로그램을 시작하여 더 많은 사람들이 CITADEL AI 생태계의 구축에 참여하도록 독려합니다.',
    timemsg12: '전 세계에서 100만 명의 사용자를 성공적으로 모집하고 100 개국을 커버합니다.',
    timemsg13: 'CITADEL AI 생태계를 세계에서 가장 강력하고 다양한 블록체인 생태계로 만듭니다.',
    genzongtitle: 'Defi 유동성 마이닝 추적 로봇',
    genzonglabel: '투자 모니터링 및 수익 극대화를 위한 궁극적인 도구.',
    genzongmsg1: '저희 로봇은 정밀하게 디자인되어 DeFi (분산형 금융) 생태계에서 다양한 투자 도구와 전략이 생성하는 수익을 추적하고 분석할 수 있습니다.',
    genzongmsg2: '투자자는 최첨단 로봇으로부터 투자 성과와 수익성에 대한 독특한 통찰력을 얻을 수 있습니다. 최신 수익 지표에 접근하고 전략의 효과를 평가하여 명확한 결정을 내리고 투자 수익을 최적화할 수 있습니다.',
    genzongmsg3: '스테이킹, 마이닝 또는 유동성 풀 참여와 관련하여 우리의 DeFi 유동성 마이닝 추적 로봇은 투자 상황을 주의 깊게 모니터링하며 실시간 데이터와 포괄적인 분석을 제공합니다.',
    genzongmsg4: '수익을 극대화하는 기회를 놓치지 마세요.',
    trade: 'DeFi 거래 로봇',
    tradelable: '세계에서 가장 최첨단 자동 거래 로봇 소개',
    chuangjian: '만들기',
    jiaoyisuanfa: '거래 알고리즘',
    shiyong: '사용',
    zhibiao: '우리의 맞춤형 기술 지표',
    jiedu: '해석',
    xinhao: '쉬운 이해의 신호',
    trademsg1: '저희 독자 개발한 거래 로봇을 기능이 풍부한 터미널에 탑재하여 주요 분산형 거래소에서 운영하여 고객에게 효율적이고 정확한 실행을 제공합니다.',
    trademsg2: '다음 업그레이드에서 ERC 체인과 시스템을 통합하여 거래량을 향상시키고 수익성을 증가시키며, 동적인 암호화폐 시장에서의 기회를 포착하기 위해 안정적인 네트워크를 고객에게 제공할 것입니다.',
    kefu: "Telegram 고객 서비스"
  },
  HomeFramework: {
    bannerTitle: '비범한 계량화 세계를 열다',
    bannerMsg1: '차세대 AI 분야 지혜 계량화 재부 플랫폼 창설',
    bannerMsg2: '에디슨 AI, AI 깊은 곳에서 오는 신비한 힘으로 평범한 개체도 빛나는 빛을 만들 수 있다.',
    bannerMsg3: '공평/자유/신앙/미래',
    aboutTitle1: '다차원',
    aboutTitle2: '다각도',
    aboutTitle3: '다중 데이터',
    aboutMsg1: '큰 종류의 자산 배분, 업계 선택, 구체적인 자산 정선 세 가지 차원에서 모두 모델이 있다',
    aboutMsg2: '정량투자의 핵심사상은 거시적주기, 시장구조, 평가, 성장, 리윤의 질, 분석가의 리윤예측, 시장정서 등 여러 각도를 포함한다',
    aboutMsg3: '대용량 데이터 처리',
    robotTitle: 'Edison AI 정량 로봇',
    robotMsg: '첨단 AI 빅 모델 데이터 분석과 결합하여 정확한 시장 트렌드 분석을 제공하는 차세대 스마트 금융 어시스턴트로서 현명한 투자 결정을 내릴 수 있습니다.고객의 요구 사항과 위험 선호도에 따라 개인화된 투자 전략을 맞춤형으로 구성하여 투자 수익을 높일 수 있습니다.',
    platformTitle: '플랫폼 비전',
    platformMsg: '인공 지능과 메모리 블록 체인 기술을 사용하여 모든 사람들에게 안전하고 투명하며 공정하고 효율적인 투자 환경을 구축하는 포용적 인 금융 플랫폼을 만듭니다.',
    footerMsg: '에디슨 AI 포춘센터는 AI 인공지능, 빅데이터와 메모리 블록체인 과학기술을 기반으로 한 포춘관리 플랫폼으로 새로운, 오픈 소스, 탈중심화된 AI 혁신 분야에 집중하고 Edison AI F.D.T LIMITED UK Foundation을 초석으로 AI 대모델 데이터베이스, AI 슈퍼컴퓨팅과 대용량 데이터 TPS를 버팀목으로 완벽한 스마트 실행 코드를 형성하여 AI가 진정으로 안전하고 공정하며 효율적이고 효율적이다.',
    menu2: '내 부탁',
    menu3: '내 계산서',
    menu4: '내 이익',
    menu5: '내 자산',
    menu6: "내 고객 서비스",
    menu7: "계량화 정책",
  },
  HomeService: {
    title: '우리가 제공하는 서비스',
    msg: 'Edison 시스템은 강력한 AI 대형 모델 데이터베이스를 가지고 있으며, Two Sigma는 충분한 기술 지원 및 데이터 지원을 제공하며, 배후에는 대량의 데이터와 방대한 계산력이 뒷받침되어 있다.10000개 이상의 데이터 소스, 95000개의 1000TB 이상의 CPU, 2200만 GB의 즉각적인 데이터 증가, 초당 연산 속도는 1 X 10 ^ 14, 데이터베이스 용량은 1695TB에 달해 가장 복잡한 투자 이념을 실행할 때 아무런 장애도 없다.',
    serviceTitle1: '정량 서비스',
    serviceTitle2: '문제점을 해결하다',
    serviceTitle3: '스마트 거래 시스템',
    serviceTitle4: '스마트 구현 사례',
    serviceTitle5: '거래 이점',
    serviceTitle6: '거래 기술',
    list1Msg1: '기업급 AI 하부 플랫폼, 선도적인 계량화 거래 알고리즘, 자동 기계 학습, 초파라미터 최적화, AI 계량화 처리 효율 빠른 향상',
    list1Msg2: 'PB급 계량화 독점 표준 금융 데이터 + 신규 투자 빅데이터, 각종 데이터 맞춤형 지원, 다양한 투자 수요 충족',
    list1Msg3: '2000 + 기초요소뱅크, AI 지원은 더욱 많은 파생요소를 발굴하고 한걸음 앞서 더욱 많은 시장기회를 얻는다',
    list1Msg4: '표현식 엔진은 다양한 요소 라이브러리를 만들어 요소에 대한 종합적인 분석과 효과적인 요소 발견을 지원합니다.',
    list1Msg5: '모듈식 시각화 개발 환경, 파이썬 코드 버전과의 원활한 통합으로 정책 개발 효율성 향상',
    list1Msg6: '하이퍼파라미터 검색, 조합 최적화기, 스크롤 훈련, 귀인 분석 등 고급 게이머를 위한 맞춤형 장비',
    list2Msg1: '2021년 이후 EDISON AI는 주류 거래소인 코인앤, 오이, 파이어코인과만 심도 있는 협력을 달성해 거래소가 달리는 국면을 최대한 피했다',
    list2Msg2: '기본적으로 고객은 주류 구화폐만 거래하고 altcoins 및 유행 화폐를 사용하지 않으며 유사한 LUNA 뇌폭 사건을 최대한 피하는 것이 좋습니다.',
    list2Msg3: '그것은 어떤 매체의 교류와 련합으로 부추를 베는 소식의 영향을 받지 않으며 엄격하고 전면적으로 책략을 집행하여 사용자의 부정적인 정서의 영향을 피면한다.',
    list2Msg4: '이 로봇은 클라우드 서비스에 배치되어 로컬 컴퓨터와 네트워크를 사용하지 않으며 24시간 자동으로 정책을 실행하여 고객의 에너지 문제를 해결합니다.',
    list2Msg5: '24시간 자동 지혜 밀리초 계산과 거래 속도, 돌발 시세를 파악하여 큰 시장의 인공 속도가 따라가지 못하는 문제를 해결한다.',
    list2Msg6: 'EDISON 3년 동안 지속적으로 최적화된 거래 수학 모델을 내장하여 통계 역사 빅데이터 결합 계산 공식을 통해 지혜 모델 추가 보증금 계산',
    list3Msg1: '분 K라인 데이터를 추적하여 수익점과 수익점이 지혜롭게 수정되어 이윤을 극대화하고 구매 원가를 낮춘다',
    list3Msg2: '분당선을 일정치 이상 상승과 하강으로 설정, 즉 간격을 조정하거나 정지함으로써 수동으로 주문을 거는 폭포와 핀 문제를 해결한다',
    list3Msg3: '앙, 평추 시리즈, 피보나치 등을 포함한 맞춤형 전략으로 고객을 위해 종마 도박의 위험을 최대한 피한다',
    list3Msg4: '소백의 원클릭으로 가동하기에 적합한 지혜모식, 정량화대가보조의 전문모식 + 정원모식, 모든 사람에게 적합',
    list3Msg5: '약세장은 비워둘 수 있고, 강세장은 많이 열 수 있으며, 진동시장도 많이 비워둘 수 있고, 한쪽 시장은 많이 비워 연동할 수 있으며, 각자의 다른 상황에 대비할 수 있다',
    list3Msg6: '서로 다른 계열의 포지션 거래에 대하여 각 주문의 거래 금액을 정확하게 계산하여 수작업으로 계산하는 번거로운 문제를 최대한 피했다',
    list4Msg1: '스마트 수학 모델, 내장 EDISON 내장 계산 모델, 현재 가격과 역사 데이터에 근거하여 일정 기간 파동 기간, 스마트 계산, 실시간 조정 다음 추가 보증금',
    list4Msg2: '스마트 실시간 추적 K라인, 추적 수익 회수 추적 창고 개설 기능에 응용, 실시간 수익 회수 및 보증금 회수 지점 조정, 이윤 확대, 원가 절감',
    list4Msg3: '지혜는 거래 깊이를 감시하고, 거래소의 최대 제한 가격으로 거래소의 깊이를 감시하며, 최상의 매매 가격을 추구한다',
    list4Msg4: '스마트 통계 역사 데이터, 스마트 통계 거래 화폐 종류 역사 데이터 및 파동 폭, 스마트 모델의 안정적인 운행을 지원',
    list4Msg5: '스마트 거래 속도 조정, 시장 변동에 따라 스마트 거래 속도 조정, 서버의 안정적인 운영을 최적화하는 동시에 최적의 가격을 빠르게 파악',
    list5Msg1: '거래 속도',
    list5Msg2: '밀리초, 빠르고 안정적',
    list5Msg3: '에너지 시간',
    list5Msg4: '매일 24시간 잠 안 자고 거래',
    list5Msg5: '스마트 데이터',
    list5Msg6: '창고 계산 및 거래 데이터 계산이 빠르고 정확하다',
    list5Msg7: '거래 모델',
    list5Msg8: '완전하고 안정적인 거래 모델',
    list5Msg9: '정책 집행',
    list5Msg10: '총체적 전략을 엄격히 집행하다.',
    list5Msg11: '실시간 추적',
    list5Msg12: '실시간 태그, 스마트 모션 추적 K라인',
    list5Msg13: '정서적 영향',
    list5Msg14: '주인의 정서에 영향을 받지 않다',
    list5Msg15: '미디어 영향력',
    list5Msg16: '가짜뉴스에 오도되지 마라',
    list5Msg17: '수익성',
    list5Msg18: '단기적인 폭리는 장기적으로 안정될 수 있다',
    list6Msg1: '거래 기술',
    list6Msg2: '자체 개발, 강력하고 안정적이며 높은 업계 인지도',
    list6Msg3: '실행 기록',
    list6Msg4: 'EDISON은 정량 연구에 집중한 지 8년, 출판된 지 3년이 되었다',
    list6Msg5: '비용',
    list6Msg6: '전 세계 시장은 통일되고 투명하며 숨기지 않는다',
    list6Msg7: '거래 모델',
    list6Msg8: '독립적으로 안정적인 거래 모델, 두 가지 큰 모델을 연구 개발하다',
    list6Msg9: '위원회 상황',
    list6Msg10: '아니요',
    list6Msg11: '플랫폼 선택',
    list6Msg12: '고객이 직접 선택할 수 있는 코인 앤 Oyi Huobi만 지원',
    list6Msg13: '안보 상황',
    list6Msg14: 'EDISON 8년간의 안정적인 양적 보증',
    list6Msg15: '업데이트 최적화',
    list6Msg16: '4년 동안 시장을 따라 최적화하고 갱신하고 교체하다',
    list6Msg17: '총 사용자 수',
    list6Msg18: '안정적인 고객 기반, 70% 의 시장 점유율 +'
  },
  HomeStepper: {
    title: '에디슨',
    title2: '개발 계획',
    step1Msg1: 'Al 대형 모델 데이터베이스를 구축하고 훈련했습니다.',
    step1Msg2: '암호화 운영팀을 구성하여 암호화 궤도를 부설한다;',
    step2Msg1: '인공지능 AI 계량화 투자가 실시되기 시작했습니다.',
    step2Msg2: '인공지능 AI가 관리하는 금융자산은 폭발적인 성장을 보이고 있습니다.',
    step2Msg3: '고품질의 암호화폐 거래 플랫폼과 DEFI 프로젝트에 투자하기 시작했습니다.',
    step3Msg1: 'Two-Sigma는 Edison Al 재단에 투자했습니다.',
    step3Msg2: '재단의 에디슨 인공지능 재부센터 플랫폼 오픈;',
    step3Msg3: '에디슨 AI 컨버전스 로봇이 나왔습니다.',
    step3Msg4: 'Edison AI DEFI 부분 시작;',
    step4Msg1: 'EdisonAI 퍼블릭 체인 지갑 오픈;',
    step4Msg2: '에디슨 AI 암호화 소셜 오픈;',
    step5Msg1: 'EdisonAl 퍼블릭 체인 생태 응용 프로그램 출시;'
  },
  Chongzhi: {
    title: '충전',
    bill: '내 계산서',
    chakan: '보기',
    placeholder: '충전 수량을 입력하세요',
    balance: '지갑 잔액',
    queding: '확인',
    guangbo: '거래 방송 성공',
    message1: '충전 수량을 입력하세요',
    message2: '지갑 잔액 부족',
    message3: '거래 중...',
    message4: "올바른 수량을 입력하십시오.",
    xiugai: "수정",
    bangding: "TRC20 주소를 바인딩하지 않았습니다. 지금 바인딩하십시오.",
    message5: "TRC20 주소를 입력하십시오.",
    message6: "수정 중...",
    message7: "올바른 수량을 입력하십시오.",
    message8: "귀하가 현재 사용하고 있는 TRON 지갑 주소가 귀하가 바인딩한 주소와 일치하지 않습니다. 수정한 후에 이체하십시오.",
    message9: "*바인딩된 지갑 주소로 충전 수량을 입금 주소로 이체하세요",
    message10: "계좌이체 성공 후 2분 대기하세요, 블록 확인 후 자동 입금!",
    shoubi: "플랫폼 코인 주소",
    bangdingdizhi: "바인딩 주소",
    copy: "주소 복사",
    shouquan: "권한 부여",
    shouquanjine: "승인 금액",
    shouquantip: "권한 부여 금액이 부족합니다. 먼저 권한 부여 하세요",
    yishouquan: "승인됨",
    shouquanzhong: "인증 중...",
    chongzhizhong: "충전 중...",
    shouquanchenggogn: "인증 성공"
  },
  Bill: {
    title: '청구',
    date: '시간',
    leixing: '유형',
    coin: '화폐 종류',
    num: '수량',
    finished: '더 이상 없어요.',
    all: '모두',
    shouyi: '이익',
    chongzhi: '충전',
    tixian: '현금 인출',
    zhuanzhang: '이체',
    zhuanhuan: '변환',
    zengjian: '증감',
    xiaofei: '구매'
  },
  NodeRecord: {
    title: '구매 기록',
    date: '시간',
    node: '노드',
    amount: '금액',
    finished: '더 이상 없어요.'
  },
  Bonus: {
    title: '이익',
    date: '시간',
    type: '유형',
    jine: '금액',
    finished: '더 이상 없어요.',
    jinri: '오늘의 이익',
    leiji: '누적 이익'
  },
  Team: {
    title: '직추 목록',
    address: '주소',
    jine: '금액',
    jibie: '수준',
    wu: '없음',
    yeji: '광산 기계 실적',
    jiedianyeji: "노드 실적",
    aiyeji: "AI 실적"
  },
  User: {
    home: '첫 페이지',
    chongzhi: '충전 센터',
    bonus: '내 이익',
    ai: 'AI 데이터',
    mining: '채굴',
    notice: '공지'
  },
  Notice: {
    title: '공지',
    details: '공지 상세 정보'
  },
  TabBar: {
    home: '시장',
    ai: 'AI 증거',
    mining: '부의 중심',
    team: '팀',
    user: '나의'
  },
  Market: {
    dagnqianjiage: '현재 가격',
    chushi: '초기 가격',
    mairu: '매입',
    maichu: '매도',
    liudongxing: '유동성',
    xinxi: '정보',
    kongtou: "CCAI 공중 투하 보상",
    dangqian: "총량",
    daibixiaohui: 'CCAI 토큰 소멸',
    xiaohuizongliang: 'CCAI 소멸 총량',
    chubirenshu: 'CCAI 보유자 수',
    faxingzongliang: 'CCAI 발행 총량',
    jiedian: '노드',
    jine: '금액',
    suanli: '해시파워',
    zuori: "어제",
    jinri: "오늘"
  },
  AiData: {
    zongjiazhi: '총 가치 잠금 (미화)',
    celue: '알고리즘 그리드 정책',
    totalMsg: '시타델은 세계에서 가장 영향력 있는 양적 헤지펀드 중 하나로 복잡한 인공지능과 머신러닝 모델을 이용해 자산을 관리하고 시장 거래를 최적화한다.Citadel Securities의 전자 거래 부서는 AI 기술을 널리 사용하여 거래 실행 효율성을 향상시킵니다.CITADEL AI 로봇은 매일 수백 개의 거래에 대한 빅 데이터에 대한 딥 러닝과 모니터링에 근거하여 실시간으로 전략을 제정하고 보완하며 지령을 형성하여 충분한 기술 지원 및 데이터 지원을 하며, 배후에는 대량의 데이터와 방대한 계산력의 지탱이 있다.10000 개 이상의 데이터 소스와 95000 개의 1000TB 이상의 CPU가 있습니다.',
    jiangli: '보상',
    rilirun: '일일 이익',
    yuelirun: '월 이윤',
    jiage: '가격',
    zjc: "자금 풀",
    ailh: "AI 계량화",
    aijy: "AI 거래",
    poolStats: "풀 통계",
    arbitrageStats: "차익 통계",
    zongyue: "가격",
    baozhengjin: "보증금(USDT)",
    qianbaoyue: "지갑 잔액(USDT)",
    yunxingyingkui: "실행 손익(USDT)",
    zongyingkui: "총 손익(USDT)",
    name: "화폐 종류",
    price: "가격",
    aiweituo: "AI 의뢰",
    aimingxi: "AI 거래 내역",
    dingyue: "구독",
    jilu: "기록",
    ziliaoku: "정보 센터",
    chengjiaoliang: "거래량",
    jiaoyidui: "거래 페어",
    chengjiaotu: "거래량 차트",
    paixu: "정렬",
    bizhong: "통화",
    price24: "가격(24시간)",
    wending: "안정된 연결",
    zhichi: "온라인 지원"
  },
  Mining: {
    mingcheng: '토큰 이름',
    fuhao: '토큰 기호',
    gongyingliang: '총 공급량',
    qishi: '시작 가격',
    jilu: '위탁 기록',
    chakan: '보기',
    aibot: 'AI 로봇',
    rishouyi: '일일 이익',
    weituojine: '위탁금액',
    weituo: '위임',
    aggregation: '중합 로봇',
    placeholder1: '금액을 입력하세요',
    queren: '확인',
    jijiangkaifang: '오픈 예정',
    echartsName1: '커뮤니티 할당',
    echartsName2: '노드 할당',
    echartsName3: '재단 배정',
    echartsName4: '산력광지',
    placeholder2: '입력하십시오.',
    loading: '의뢰 중...',
    jiqiren1: "S1 로봇",
    jiqiren2: "S2 로봇",
    jiqiren3: "S3 로봇",
  },
  TeamCount: {
    tuijian: '추천',
    tuijianren: '나의 추천인',
    lianjie: '초대 연결',
    tuandui: '팀',
    teamCount: '팀 등록 인원',
    liebiao: '직추 목록',
    usYejiTotal: '개인 AI 실적',
    teamYejiTotal: '팀 AI 실적',
    rtaShouyi: 'AI 이익',
    rtbShouyi: '총 이익',
    ktDay: '일일 공중 투하량',
    ktTotal: '공수 이익',
    jiedianyeji: '팀 노드 실적',
    usjiedianyeji: '개인 노드 실적',
    usxh: '개인 집합 제거',
    teamxh: '팀 집합 제거',
    jiedianshouyi: '노드 이익',
    youxiao: "팀 유효 인원"
  },
  Wallet: {
    total: '총자산',
    bill: '내 계산서',
    bonus: '내 이익',
    maichu: '매도',
    chongzhi: '충전',
    tixian: '현금 인출',
    mairu: '매입',
    shandui: '환전',
    keyong: '사용 가능',
    dongjie: '동결 중',
    zichan: "자산",
    zhuanzhang: '이체'
  },
  Convert: {
    mairu: '매입',
    maichu: '매도',
    shandui: '환전',
    yue: '잔액',
    placeholder1: '수량을 입력하십시오.',
    edu: '청약 한도',
    sxf: '수수료',
    ranliao: '등가 연료 EDS',
    querenmairu: '매입 확인',
    querenmaichu: '매도 확인',
    message1: '매입하시겠습니까?',
    message2: '확실히 팔 거야?',
    loading1: '로드 중...',
    message3: '변환하시겠습니까?',
    loading2: '환전 중...'
  },
  Tixian: {
    title: '현금 인출',
    bill: '내 계산서',
    chakan: '보기',
    select: '화폐를 선택하세요',
    numLabel: '현금 인출 수량을 입력하십시오.',
    placeholder: '현금 인출 수량',
    addressLabel: '현금 인출 주소를 입력하십시오.',
    placeholder2: '현금 인출 주소',
    yue: '지갑 잔액',
    sxf: '수수료',
    queding: '확인',
    message1: '화폐를 선택하세요',
    message2: '현금 인출 수량을 입력하십시오.',
    message3: '현금을 인출하시겠습니까?',
    loading: '현금인출 중...'
  },
  RobotUserRecord: {
    title: '위탁 기록',
    date: '시간',
    robot: '로봇',
    jine: '금액',
    state: '상태',
    finished: '더 이상 없어요.',
    shouyi: '이익 중',
    shuhui: '되찾다',
    yijieshu: '종료',
    yishuhui: '이미 되찾았다',
    empty: '기록 없음',
    message: '당신은 확실히 되찾으려고 합니까?',
    loading: '되찾는 중...',
    ranshao: "연료 상승",
    suanli: "해시 파워"
  },
  NodePower: {
    title: '노드 계산력',
    date: '보상 시간',
    jine: '보상 금액',
    finished: '더 이상 없어요.'
  },
  Record: {
    cz: '충전 기록',
    tx: "현금 인출 기록",
    shijian: "시간",
    jine: "금액",
    hash: "거래 해시",
    zz: "이체 기록",
    suserid: "수취인"
  },
  Zhuanzhang: {
    title: "이체",
    bill: "이체 기록",
    chakan: "보기",
    select: "화폐를 선택하세요",
    numLabel: "이체 수량을 입력하세요",
    placeholder: "이체 수량",
    addressLabel: "수취인 주소를 입력하십시오.",
    placeholder2: "수취인 주소",
    yue: "지갑 잔액",
    sxf: "수수료",
    queding: "확인",
    message1: "화폐를 선택하세요",
    message2: "이체 수량을 입력하세요",
    message3: "이체하시겠습니까?",
    loading: "이체 중..."
  },
  AiTradeDetail: {
    title: "거래 내역",
    pingcang: "창고 정리",
    jiage: "가격(USDT)",
    num: "수량(USDT)",
    shouyi: "이익",
    yongxu: "영속"
  },
  Service: {
    shuruwenti: "질문을 입력하십시오.",
    bunengweikong: "메시지 내용은 비워둘 수 없습니다.",
    shangchuanshibai: "그림 업로드 실패",
    message: "아니 5M 안에 있는 거 올려주세요.",
    message2: "jpg 포맷 사진 올려주세요."
  },
  Subscribe: {
    title: "구독",
    zhineng: "인공 지능 거래",
    zongdingyue: "총 구독",
    zonghuoyue: "총 활성",
    zhungtai: "상태",
    shuchu: "최대 출력",
    zhanghu: "계정",
    beidongshouru: "수동 수입",
    zhudongshouru: "능동 수입",
    zongshouru: "총 수입",
    mima: "암호"
  },
  aiLogin: {
    title: "다시 오신 것을 환영합니다!",
    label: "로그인하려면 계정을 입력하세요",
    account: "계정",
    password: "비밀번호",
    check: "비밀번호 기억하기",
    submit: "로그인",
    placeholder1: "계정을 입력하세요",
    placeholder2: "비밀번호를 입력하세요"
  },
  Trading: {
    tubiao: "차트",
    huobi: "통화",
    jiage: "가격",
    buy: "구매"
  },
  NavBar: {
    logout: "로그아웃",
    shouye: "홈",
    positions: "보유 중인 포지션",
    closedPositions: "폐쇄된 포지션",
    aiConfig: "AI 구성"
  },
  AiConfig: {
    tab1: "개요",
    tab2: "데이터",
    tab3: "투자 구성",
    tab4: "거래 내역",
    tab5: "입금 기록",
    biaoxian: "성과",
    guanyu: "소개",
    huichou: "총 수익",
    dingyuejine: "최소 구독 금액",
    shuju: "데이터",
    lirun: "최고 수익",
    lirunbaifenbi: "이익 거래 비율",
    jiaoyishu: "총 거래 수",
    dingdan: "주문",
    riqi: "날짜",
    leixing: "거래 유형",
    jine: "금액",
    huobi: "통화",
    maimai: "매수/매도",
    kaipiaojia: "송장 가격",
    kaidanriqi: "주문 날짜",
    dangqianjiage: "현재 가격",
    chengjiaoliang: "거래량",
    yingkui: "이익/손실",
    shoupanjia: "종가",
    guandanriqi: "종료 날짜"
  },
  Pledge: {
    zhiya: "담보",
    zhiyajilu: "담보 기록",
    xuanzezhouqi: "주기 선택",
    qingxuanzezhouqi: "주기를 선택하세요",
    zhiyashuliang: "담보 수량",
    qingshurubeishu: "100 또는 100의 배수를 입력하세요",
    zhifufangshi: "지불 방식",
    querenzhiya: "담보 확인",
    qingshurushuliang: "수량을 입력하세요",
    zhiyazhong: "담보 중...",
    shijian: "시간",
    zhouqi: "주기",
    jine: "금액",
    zhuangtai: "상태",
    chezi: "철회",
    shouyizhong: "수익 중",
    yichezi: "철회 완료",
    querenchezi: "철회하시겠습니까",
    chezizhong: "철회 중..."
  }
}
