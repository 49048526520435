import config from './config.js';
import utils from "./utils.js";
import Axios from "axios";
import rsa from './rsa/index'
import Dialog from 'quasar/src/plugins/Dialog.js';
import router from '../../router'

const post = (api, params, func, showloading = true) => {
	let language = utils.getloc("language") ? utils.getloc("language") : 'en'
	params.lang = language;
	let _url = config.send_url + api; //拼接url
	if (Object.keys(params).length > 0) {
		params.sign = utils.sort_ascii(params); //签名
		params = JSON.stringify(params); //转json
		params = encodeURIComponent(params) //转义
		params = {
			code : rsa.getRsaCode(params) //加密
		};
	} else {
		params = "";
	}
	Axios({
		url: _url,
		method: 'post',
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		},
		data: JSON.stringify(params)
	}).then((res) => {
		if (res.status === 200) {
			func(res);
		} else {
			/* Dialog.alert({
				title: '未知错误',
				message: 'status:' + res.status + ' statusText:' + res.statusText,
			}); */
		}
	}).catch((err) => {
		console.log(err);
	});
}

function get() {

}

export default {
	post,
	get
};
