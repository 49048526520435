module.exports = {
  Base: {
    copy: "Disalin dengan sukses",
    back: "Kembali",
    empty: "Belum ada data",
    empty2: "Tidak ada catatan yang tersedia",
    tishi: "Prompt",
    queding: "Konfirmasi",
    quxiao: "Batalkan",
    tijiao: "Mengirim"
  },
  CheckRegister: {
    tuijianren: "Direkomendasi oleh",
    placeholder: "Silakan masukkan alamat referensi",
    register: "Register",
    addressErr: "Galat mendaftar untuk mendapatkan alamat:",
    qingshuruyonghuming: "masukkan satu nama pengguna",
    qingshuruyongmima: "Silakan masukkan sandi",
    dengluzhong: "Daftar masuk"
  },
  Home: {
    gonggao: "pengumuman",
    rules: "Huraian Peraturan",
    purchaseRecord: "Catatan Pembelian",
    select: "Pilih Tingkat",
    amount: "Jumlah pembelian",
    submit: "Nod pembelian",
    chongzhi: "Isi ulang Sekarang",
    referrers: "Rekomendasiku",
    link: "Panggil Sambungan",
    list: "Daftar Tanggalan",
    address: "alamat",
    listAmount: "Jumlah subskripsi",
    state: "keadaan",
    completed: "Selesai",
    message1: "Disalin dengan sukses",
    message2: "Kau yakin ingin membeli?",
    message3: "Membeli",
    down: "Pembelian panik node jarak",
    day: "hari",
    hours: "Jam",
    seconds: "detik",
    jieshu: "Pembelian panik node telah berakhir",
    downTitle1: "Edison AI Node Partner",
    downTitle2: "Rekrutasi global dalam proses",
    zhitui: "Daftar dorongan langsung",
    qianggouzhong: "Nod fase kedua dalam pembelian terburu-buru",
    minutes: "cabang",
    jiedian: "Kuasa komputer nodal",
    jifaqianneng: 'Memunculkan potensi tak terbatas, menciptakan kemungkinan tanpa batas',
    jinridapp: 'Masuk ke DAPP',
    guanyu: 'Tentang Kami',
    lianjiequkuai: 'Menghubungkan jurang antara teknologi blockchain dan kebutuhan dunia bisnis yang nyata',
    zhanxianshequ: 'Menunjukkan kekuatan komunitas dan konsensus dalam praktiknya',
    sectionmsg1: 'CITADEL AI adalah sebuah aliansi global yang terdiri dari pemimpin, inovator, pengembang, dan perusahaan internasional dalam dunia blockchain. Aliansi ini akan menjadi katalis pertumbuhan dan kemakmuran bagi blockchain CITADEL AI, menciptakan peluang bisnis baru, mendorong aplikasi industri, serta belajar dan berkolaborasi bersama.',
    sectionmsg2: 'Kami mengumpulkan komunitas global yang sangat percaya pada CITADEL AI, dan berharap dapat memberikan panduan, dukungan, dan manajemen dalam teknologi blockchain CITADEL AI dan teknologi baru yang sedang berkembang kepada perusahaan-perusahaan.',
    zhize: 'Tugas Aliansi',
    zhizemsg: 'CITADEL AI adalah kekuatan inti dari ekosistem CITADEL AI, dengan semangat desentralisasi, akan mendorong perkembangan teknologi blockchain dan ekosistem CITADEL AI melalui berbagai cara yang berbeda.',
    shequ: 'Rencana Komunitas',
    shequmsg1: 'Berkolaborasi untuk membangun masa depan CITADEL AI',
    shequmsg2: 'CITADEL AI terus berperan penting dalam pembangunan dan operasional komunitas CITADEL AI. Sebagai anggota CITADEL AI, pengguna akan terlibat dalam komunitas yang energik, berpartisipasi dalam pengambilan keputusan, mengorganisir acara, dan bersama-sama membentuk rencana masa depan CITADEL AI.',
    shequmsg3: 'Menuju era Web3 yang desentralisasi',
    shequmsg4: 'Model partisipasi komunitas desentralisasi yang kami anut sangat sesuai dengan visi Web3, yang tidak hanya meningkatkan transparansi ekosistem CITADEL AI, tetapi juga menjamin keadilan dan universalitas setiap keputusan.',
    qiye: 'Rencana Perusahaan',
    qiyemsg1: 'Menyediakan solusi blockchain inovatif untuk perusahaan',
    qiyemsg2: 'CITADEL AI berkolaborasi secara erat dengan perusahaan-perusahaan global, terus mengeksplorasi potensi tak terbatas teknologi blockchain CITADEL AI. Kami bertujuan menyediakan solusi blockchain yang sesuai dengan kebutuhan perusahaan, membantu mereka tetap kompetitif di era ekonomi digital.',
    qiyemsg3: 'Memanfaatkan potensi jaringan utama CITADEL AI',
    qiyemsg4: 'Kami juga akan memberikan dukungan menyeluruh kepada perusahaan, membantu mereka memahami dan memanfaatkan keunggulan teknis jaringan utama CITADEL AI serta keunggulan unik dalam keamanan dan keandalan data, untuk memastikan pengalaman terbaik perusahaan dalam ekosistem CITADEL AI.',
    qukuaigonglian: 'Blockchain Public Chain',
    xiayidai: 'Generasi berikutnya dari Web3 dengan jaringan dasar desentralisasi',
    xinjingji: 'Solusi blockchain untuk era ekonomi baru',
    xinjingjimsg: 'CITADEL AI, sebuah jaringan blockchain yang dirancang khusus untuk ekonomi baru, memiliki arsitektur unik main chain + side chain yang memberikan ruang bagi pengembang untuk mendeploy dan menjalankan aplikasi terdistribusi secara independen, sehingga mencapai optimalisasi sumber daya dan penggunaan yang efektif. Dengan komputasi paralel yang efisien dan mekanisme konsensus yang kuat, CITADEL AI menyediakan dukungan yang kuat untuk transaksi berkecepatan tinggi. Selain itu, teknologi cross-chain dijamin kelancaran komunikasi dan transfer nilai antar rantai.',
    kaifgongju: 'Alat governansi dan pengembangan yang kuat',
    kaifgongjumsg: 'Berbagai model governansi diperlukan untuk situasi bisnis yang berbeda, CITADEL AI memahami hal ini dengan baik dan oleh karena itu menyediakan berbagai model governansi seperti governansi parlemen, governansi publik, dan governansi asosiasi. Untuk memastikan perkembangan sistem yang berkelanjutan dan sehat, CITADEL AI juga merancang model insentif yang cermat. Selain itu, CITADEL AI juga menyediakan alat pengembangan IDE yang canggih untuk membantu pengembang dalam mengembangkan, melakukan debug, dan mendeploy aplikasi dengan lebih mudah.',
    zhongxinhua: 'Berperan dalam pemerintahan desentralisasi',
    zhongxinhuazeren: 'Sebagai produsen blok utama CITADEL AI, CITADEL AI memiliki tanggung jawab penting.',
    zhongxinhuali1: 'Memastikan keamanan jaringan',
    zhongxinhuali2: 'Mengoptimalkan keputusan konsensus',
    zhongxinhuali3: 'Memelihara operasi optimal pada node side chain',
    zhongxinhuali4: 'Memastikan stabilitas jaringan',
    pianzhng: 'Menuju babak baru',
    pianzhnglabel1: 'Bukan hanya jaringan blockchain biasa',
    pianzhngmsg1: 'Kami juga berusaha untuk merangkul konsep organisasi otonom desentralisasi (DAO). Oleh karena itu, CITADEL AI telah mendirikan DAO sendiri dan memulai program perekrutan node global.',
    pianzhnglabel2: 'CITADEL AI bertujuan merekrut 20 node senior di seluruh dunia',
    pianzhngmsg2: 'Setiap perwakilan adalah tokoh terkenal dari berbagai bidang, baik lembaga internasional maupun individu yang terkenal di bidang tertentu. 20 perwakilan ini akan bekerja sama dan membuat keputusan penting tentang ekosistem CITADEL AI, mendorong kemajuan CITADEL AI ke level yang lebih tinggi.',
    pianzhnglabel3: 'Organisasi otonom desentralisasi adalah salah satu aplikasi utama teknologi blockchain',
    pianzhngmsg3: 'Konsep ini meninggalkan pendekatan pengelolaan yang terpusat, membuat proses pengambilan keputusan lebih demokratis dan transparan. Dalam arsitektur DAO, setiap anggota dapat memberikan suara berdasarkan kontribusi atau kepemilikan mereka dalam sistem, sehingga bersama-sama membuat keputusan tentang masa depan organisasi.',
    pianzhnglabel4: 'Pendirian Yayasan CITADEL AI bertujuan untuk melibatkan lebih banyak orang dalam',
    pianzhngmsg4: 'pengembangan CITADEL AI, dan bersama-sama menulis sejarah gemilang CITADEL AI.',
    mubiao: 'Tujuan Utama',
    mubiaolabel1: 'Kurator Ekosistem',
    mubiaomsg1: 'Memilih, mendukung, dan mempromosikan proyek-proyek berpotensi tinggi di ekosistem CITADEL AI.',
    mubiaolabel2: 'Verifikasi Teknis',
    mubiaomsg2: 'Bertanggung jawab untuk memastikan keamanan jaringan melalui produksi dan verifikasi transaksi blockchain.',
    mubiaolabel3: 'Koordinasi Lintas Rantai',
    mubiaomsg3: 'Membangun hubungan kerjasama dengan entitas blockchain lainnya untuk memastikan interoperabilitas CITADEL AI dan mengikuti standar industri terkini.',
    mubiaolabel4: 'Pembangunan Komunitas',
    mubiaomsg4: 'Memimpin pengembangan komunitas, menciptakan konsensus, dan menciptakan ruang interaksi yang positif untuk mendorong pertumbuhan dan kemakmuran ekosistem CITADEL AI.',
    mubiaolabel5: 'Operasi dan Manajemen DAO',
    mubiaomsg5: 'Mengawasi pengajuan, diskusi, dan pemungutan suara proposal komunitas untuk memastikan kesesuaian dengan misi dan nilai-nilai CITADEL AI.',
    mubiaolabel6: 'Penggalangan Dana',
    mubiaomsg6: 'Mengawasi kegiatan penggalangan dana untuk memastikan pertumbuhan dan stabilitas keuangan ekosistem CITADEL AI.',
    time1: 'Kuartal Keempat 2023',
    time2: 'Kuartal Pertama 2024',
    time3: 'Kuartal Kedua 2024',
    time4: 'Kuartal Ketiga 2024',
    time5: 'Kuartal Keempat 2024',
    time6: "2025",
    time7: "2026",
    timemsg1: 'Rekrutmen aktif, tujuan untuk menarik 100.000 pengguna di seluruh dunia dari 20 negara.',
    timemsg2: 'Mendorong penelitian dan implementasi Web3, mengintegrasikan ekosistem CITADEL AI dengan perkembangan terkini Web3.',
    timemsg3: 'Mendukung program pendanaan Aclevate, mempromosikan tim pengembang permainan yang telah diterima di CITADEL AI untuk meningkatkan jumlah penggemar dan kepopuleran pasar, sehingga ekosistem CITADEL AI memiliki pengaruh yang lebih besar dalam industri keuangan permainan.',
    timemsg4: 'Mengadakan konferensi online dan offline, pameran perdagangan, dan kegiatan komunitas lainnya untuk menarik lebih banyak perusahaan dan pengguna untuk bergabung dengan CITADEL AI.',
    timemsg5: 'Memperkuat hubungan dengan mitra global untuk mendorong perkembangan ekosistem CITADEL AI.',
    timemsg6: 'Membentuk aliansi GameFi, menjadikan CITADEL AI sebagai pemimpin dalam permainan berbasis Play-to-Earn dan inovasi GameFi lainnya.',
    timemsg7: 'Berhasil menarik 500.000 pengguna di seluruh dunia dari setidaknya 50 negara, membantu CITADEL AI menambah setidaknya 30 proyek baru dari berbagai bidang di dalam ekosistemnya, serta terus mengembangkan dan memperkuat fungsionalitas dan keberagaman ekosistem CITADEL AI.',
    timemsg8: 'Membantu pendirian inkubator CITADEL AI, mendukung dan mendorong pengembangan proyek inovatif di jaringan publik CITADEL AI.',
    timemsg9: 'Mendorong ekosistem SocialFi, menjadikan CITADEL AI sebagai pelopor dalam keuangan sosial dan inovasi terkait.',
    timemsg10: 'Mendirikan Akademi CITADEL AI, menyediakan sumber daya pendidikan dan pelatihan untuk meningkatkan pengetahuan dan keterampilan anggota komunitas dalam teknologi blockchain.',
    timemsg11: 'Meluncurkan berbagai program insentif komunitas untuk mendorong partisipasi lebih banyak orang dalam pembangunan ekosistem CITADEL AI.',
    timemsg12: 'Berhasil merekrut 1 juta pengguna di seluruh dunia dari 100 negara.',
    timemsg13: 'Mengembangkan ekosistem CITADEL AI menjadi ekosistem blockchain terbesar dan paling beragam di dunia.',
    genzongtitle: 'Robot Pelacakan Likuiditas DeFi',
    genzonglabel: 'Alat utama untuk pemantauan investasi dan memaksimalkan pengembalian.',
    genzongmsg1: 'Robot kami dirancang dengan cermat untuk melacak dan menganalisis pengembalian yang dihasilkan oleh berbagai alat investasi dan strategi dalam ekosistem keuangan terdesentralisasi (DeFi).',
    genzongmsg2: 'Investor dapat memperoleh wawasan unik tentang kinerja investasi dan profitabilitas dari robot canggih kami. Akses metrik yield terkini, menilai efektivitas strategi, membuat keputusan yang terinformasi, dan mengoptimalkan pengembalian investasi dengan mudah.',
    genzongmsg3: 'Baik itu staking, penambangan, atau partisipasi dalam kolam likuiditas, robot pelacakan likuiditas pertambangan DeFi kami memantau situasi investasi Anda dengan cermat, menyediakan data real-time dan analisis komprehensif.',
    genzongmsg4: 'Jangan lewatkan kesempatan untuk memaksimalkan pengembalian.',
    trade: 'Robot Perdagangan DeFi',
    tradelabel: 'Memperkenalkan robot perdagangan otomatis paling canggih di dunia.',
    chuangjian: 'Membuat',
    jiaoyisuanfa: 'Algoritma Perdagangan',
    shiyong: 'Penggunaan',
    zhibiao: 'Indikator teknis kustom kami',
    jiedu: 'Interpretasi',
    xinhao: 'Sinyal Yang Mudah Dipahami',
    trademsg1: 'Kami telah menyematkan robot perdagangan kami yang dikembangkan sendiri ke dalam terminal yang kaya fitur, beroperasi di beberapa bursa terdesentralisasi utama, memberikan eksekusi yang efisien dan tepat kepada klien.',
    trademsg2: 'Kami akan mengintegrasikan sistem dengan rantai ERC dalam upgrade berikutnya untuk meningkatkan volume perdagangan, sambil mendorong pertumbuhan profit, dan menyediakan jaringan yang stabil bagi klien untuk membantu mereka memanfaatkan peluang di pasar kripto yang dinamis.',
    kefu: "Layanan pelanggan telegram"
  },
  HomeFramework: {
    bannerTitle: "Membuka dunia kuantifikasi yang luar biasa",
    bannerMsg1: "Mencipta generasi baru dari platform kekayaan kuantitatif intelijen di bidang AI",
    bannerMsg2: "Edison AI, kekuatan misterius dari kedalaman AI, memungkinkan individu biasa untuk menciptakan cahaya bersinar.",
    bannerMsg3: "Keadilan/Kebebasan/Kepercayaan/Masa depan",
    aboutTitle1: "Multilevel",
    aboutTitle2: "Sudut berbilang",
    aboutTitle3: "Banyak data",
    aboutMsg1: "Ada model di tiga tingkat, termasuk alokasi aset dalam kategori utama, seleksi industri, dan seleksi aset spesifik",
    aboutMsg2: "Ide utama investasi kuantitatif termasuk berbagai perspektif seperti siklus makro, struktur pasar, penilaian, pertumbuhan, kualitas keuntungan, prediksi keuntungan analis, dan sentimen pasar",
    aboutMsg3: "Memproses jumlah besar data",
    robotTitle: "Edison AI Quantitative Robot",
    robotMsg: "Ini adalah generasi baru asisten keuangan pintar yang menggabungkan analisis model data AI maju untuk menyediakan analisis trends pasar yang akurat dan membantu Anda membuat keputusan investasi bijaksana. Ini akan menyesuaikan strategi investasi pribadi berdasarkan kebutuhan dan keutamaan risiko Anda, membawa keuntungan investasi yang lebih tinggi.",
    platformTitle: "Visi Platform",
    platformMsg: "Gunakan teknologi kecerdasan buatan dan jaringan memori untuk menciptakan platform keuangan inklusif yang menciptakan lingkungan investasi yang aman, transparan, adil dan efisien untuk semua.",
    footerMsg: "Edison AI Wealth Center adalah platform manajemen kekayaan berdasarkan intelijen buatan AI, data besar, dan teknologi blockchain memori. Fokus pada bidang inovasi AI baru, sumber terbuka, dan dekentralisasi, dengan Edison AI F.D.T LIMITED UK Foundation sebagai batu sudut dan AI model database besar, AI supercomputing, dan data TPS masif sebagai dukungan, membentuk set lengkap kode berjalan intelijen untuk benar-benar mencapai keadilan, keamanan, efisiensi, dan kecepatan dalam kuantifikasi AI.",
    menu2: "Delegasi saya",
    menu3: "tagihanku",
    menu4: "Keuntunganku",
    menu5: "asetku",
    menu6: "Layanan pelangganku",
    menu7: "Strategi kuantitatif",
  },
  HomeService: {
    title: "Layanan yang kita berikan",
    msg: "Sistem Edison memiliki database model besar AI yang kuat, dan Two Sigma menyediakan dukungan teknis dan data yang cukup, didukung oleh data besar dan kekuatan komputer. Ini memiliki lebih dari 10000 sumber data, 95000 CPU dari lebih dari 1000TB, 22 juta GB dari segera meningkat data, kecepatan komputer 1 X 10^ 14 per detik, dan kapasitas pangkalan data sampai 1695TB, membuatnya tidak berusaha ketika menjalankan filosofi investasi yang paling rumit.",
    serviceTitle1: "Layanan kuantitatif",
    serviceTitle2: "Mengatasi titik sakit",
    serviceTitle3: "Sistem perdagangan cerdas",
    serviceTitle4: "Contoh implementasi cerdas",
    serviceTitle5: "Keuntungan perdagangan",
    serviceTitle6: "Teknologi Penjualan",
    list1Msg1: "Perusahaan tingkat AI platform dasar, memimpin algoritma perdagangan kuantitatif, belajar mesin otomatis, optimisasi hyperparameter, dan peningkatan cepat dalam efisiensi proses kuantifikasi AI",
    list1Msg2: "Nilai PB kuantitatif standar keuangan standar kuantitatif + investasi baru data besar, mendukung berbagai pengasuaian data untuk memenuhi kebutuhan investasi berbeda",
    list1Msg3: "2000+perpustakaan elemen dasar, AI mendukung pengembangan elemen lebih derivat, memimpin jalan dan mendapatkan lebih banyak kesempatan pasar",
    list1Msg4: "Mesin ekspresi menciptakan perpustakaan faktor berbeda yang mendukung analisis komprensif faktor dan penemuan lebih cepat faktor efektif",
    list1Msg5: "Lingkungan pembangunan visual modular, terintegrasi secara terus-menerus dengan versi kode Python, meningkatkan efisiensi pembangunan strategi",
    list1Msg6: "Atur peralatan untuk pemain seni tinggi melalui pencarian hyperparameter, kombinasi optimisator, latihan berguling, analisis atribut, dll",
    list2Msg1: "2Sejak 021, EDISON AI hanya mencapai kerjasama dalam dengan pertukaran utama seperti Keamanan Coin, Ouyi dan Huobi, yang telah menghindari situasi pertukaran melarikan diri ke tingkat terbesar",
    list2Msg2: "Secara standar, rekomendasi bahwa pelanggan hanya perdagangan mainstream valuta lama, menghindari menggunakan altcoin dan valuta populer, dan minimalisasi peristiwa badai LUNA yang sama",
    list2Msg3: "Hal ini tidak terpengaruh oleh berita apapun tentang komunikasi media dan pemotongan kongsi penyakit, dan secara ketat dan meliputi menerapkan strategi untuk menghindari dampak emosional negatif pada pengguna.",
    list2Msg4: "Robot ini dipasang dalam layanan awan, tidak menguasai komputer dan jaringan lokal Anda, dan secara otomatis menjalankan strategi ini 24 jam sehari untuk memecahkan masalah energi pelanggan.",
    list2Msg5: "24 jam otomatis kalkulasi milidetik intelijen dan kecepatan perdagangan, menangkap trends pasar tiba-tiba, dan memecahkan masalah kecepatan manual tidak menjaga di pasar besar.",
    list2Msg6: "Dibangun dalam tiga tahun EDISON secara terus-menerus optimisasi perdagangan model matematika, peningkatan margin model cerdas dihitung dengan menggabungkan data besar sejarah dengan formula kalkulasi",
    list3Msg1: "Dengan melacak data garis-K menit, titik keuntungan dan titik keuntungan secara cerdas diubah untuk maksimalkan keuntungan dan mengurangi biaya pembelian",
    list3Msg2: "Dengan menetapkan garis menit untuk meningkat dan jatuh melebihi nilai tertentu, yaitu, menyesuaikan titik interval atau berhenti, kita dapat memecahkan masalah penyisipan air terjun dan pin pengaturan aturan manual",
    list3Msg3: "Strategi yang dapat disesuaikan, termasuk Martingale, seri Pingtui, Fibonacci, dll., mengurangi resiko judi para pelanggan",
    list3Msg4: "Mode pintar yang cocok untuk satu klik memulai Xiaobai, mode profesional yang diberi bantuan oleh master kuantitatif+quota mode, cocok untuk semua orang",
    list3Msg5: "Pasar beruang dapat pendek, pasar sapi dapat membuka lebih banyak, pasar volatil dapat membuka kedua panjang dan pendek, dan pasar unilateral dapat menghubungkan panjang dan pendek, menargetkan situasi yang berbeda",
    list3Msg6: "Menghitung dengan tepat jumlah transaksi untuk setiap perintah berdasarkan seri transaksi posisi yang berbeda, menghindari isu membosankan dari penghitungan manual ke tingkat terbesar mungkin",
    list4Msg1: "Model matematika cerdas dengan model kalkulasi EDISON yang dibuat. Berdasarkan harga saat ini dan data sejarah, ada periode fluktuasi, dan kalkulasi cerdas dapat menyesuaikan panggilan margin berikutnya dalam waktu nyata",
    list4Msg2: "Pelacakan intelijen dalam waktu nyata dari garis K, diterapkan untuk melacak fungsi mengambil keuntungan dan pembukaan, penyesuaian dalam waktu nyata dari mengambil keuntungan dan titik kembalian margin, amplifikasi keuntungan, dan reduksi biaya",
    list4Msg3: "Monitor intelijen dari kedalaman perdagangan, mengawasi kedalaman perdagangan dengan batas maksimum harga, dan mengejar harga pembeli dan menjual terbaik",
    list4Msg4: "Data sejarah statistik cerdas, data sejarah perdagangan statistik cerdas dan volatilitas, mendukung operasi stabil mode cerdas",
    list4Msg5: "Penyesuaian cerdas kecepatan perdagangan, penyesuaian cerdas kecepatan perdagangan berdasarkan fluktuasi pasar, sementara optimisasi operasi server stabil dan cepat menangkap harga terbaik",
    list5Msg1: "Kecepatan transaksi",
    list5Msg2: "Milisekundi, cepat, stabil",
    list5Msg3: "Waktu energi",
    list5Msg4: "Menjual 24 jam sehari tanpa tidur",
    list5Msg5: "Data cerdas",
    list5Msg6: "Perhitungan cepat dan akurat dari data posisi dan transaksi",
    list5Msg7: "Mod perdagangan",
    list5Msg8: "Model perdagangan lengkap dan stabil",
    list5Msg9: "Implementasi kebijakan",
    list5Msg10: "Menyempurnakan strategi keseluruhan",
    list5Msg11: "Pelacakan waktu nyata",
    list5Msg12: "Tanda waktu nyata, pelacakan gerakan intelijen garis K",
    list5Msg13: "Impak emosional",
    list5Msg14: "Tidak terpengaruh oleh emosi pemilik",
    list5Msg15: "pengaruh media",
    list5Msg16: "Jangan disesatkan oleh berita palsu",
    list5Msg17: "Profitabilitas",
    list5Msg18: "Gaji kesuntuan jangka pendek dapat mempertahankan stabilitas jangka panjang",
    list6Msg1: "Teknologi Penjualan",
    list6Msg2: "Terbangun secara independen, kuat dan stabil, dengan pengakuan industri tinggi",
    list6Msg3: "Jalankan Sejarah",
    list6Msg4: "EDISON telah fokus pada penelitian kuantitatif selama 8 tahun dan telah diterbitkan selama 3 tahun",
    list6Msg5: "biaya",
    list6Msg6: "Pasar global bersatu, transparan, dan tidak tersembunyi",
    list6Msg7: "Mod perdagangan",
    list6Msg8: "Penelitian dan pengembangan independen dari model perdagangan stabil, dua model utama",
    list6Msg9: "Situasi komite",
    list6Msg10: "Tidak",
    list6Msg11: "Pemilihan Platform",
    list6Msg12: "Hanya mendukung Coin An Oyi Huobi, pelanggan bisa memilih dengan keputusan mereka sendiri",
    list6Msg13: "Situasi keamanan",
    list6Msg14: "Assurance Quantitative of Eight Year Stability for EDISON",
    list6Msg15: "Kemaskini optimisasi",
    list6Msg16: "Empat tahun untuk mengikuti optimisasi pasar dan memperbarui iterasi",
    list6Msg17: "Jumlah pengguna",
    list6Msg18: "Pangkalan pelanggan stabil dengan 70% saham pasar+",
  },
  HomeStepper: {
    title: "Edison",
    title2: "Rencana Pembangunan",
    step1Msg1: "Membina dan dilatih database model besar Al;",
    step1Msg2: "Membuat tim operasi enkripsi yang bertanggung jawab untuk menempatkan jejak enkripsi;",
    step2Msg1: "investasi kuantitatif AI dalam kecerdasan buatan telah dimulai untuk dieksploitasi;",
    step2Msg2: "aset keuangan yang dikelola oleh AI kecerdasan buatan menunjukkan pertumbuhan ledakan;",
    step2Msg3: "Hal ini mulai menginvestasikan dalam platform perdagangan mata uang kripto kualitas tinggi dan proyek DEFI;",
    step3Msg1: "TDua Sigma investasi di Edison Al Foundation;",
    step3Msg2: "Platform Edison Artificial Intelligence Wealth Center dari fondasi dilancarkan;",
    step3Msg3: "Robot agregasi Edison AI keluar;",
    step3Msg4: "Edison AI DEFI parsial diaktifkan;",
    step4Msg1: "Dompet rantai umum EdisonAI dilancarkan;",
    step4Msg2: "Edison AI mengkripsi jaringan sosial online;",
    step5Msg1: "Aplikasi ekologi rantai publik EdisonAl dilancarkan;",
  },
  Chongzhi: {
    title: "Isi ulang",
    bill: "tagihanku",
    chakan: "cek",
    placeholder: "Silakan masukkan jumlah muat ulang",
    balance: "Balansi dompet",
    queding: "konfirmasi",
    guangbo: "Transaksi siaran berhasil",
    message1: "Silakan masukkan jumlah muat ulang",
    message2: "Tidak cukup keseimbangan dompet",
    message3: "Penjualan",
    message4: "Silakan masukkan jumlah yang tepat",
    xiugai: "ubah",
    bangding: "Anda tidak mengikat alamat TRC20, mengikat sekarang",
    message5: "Silakan masukkan alamat TRC20",
    message6: "Mengubah",
    message7: "Silakan masukkan jumlah yang tepat",
    message8: "Alamat dompet TRON yang Anda gunakan saat ini tidak cocok dengan alamat yang Anda terikat. Silakan mengubahnya sebelum membuat transfer",
    message9: "*Silakan gunakan alamat dompet terikat untuk memindahkan jumlah muatan ulang ke alamat penerima",
    message10: "Setelah transfernya berhasil, silakan tunggu selama 2 menit untuk blok memori dikonfirmasi dan secara otomatis dikreditasi!",
    shoubi: "Platform menerima alamat",
    bangdingdizhi: "Alamat Bind",
    copy: "Salin Alamat",
    shouquan: "otorisasi",
    shouquanjine: "Jumlah otorisasi",
    shouquantip: "Jumlah otorisasi tidak cukup, silakan otorisasi pertama",
    yishouquan: "Diotorisasi",
    shouquanzhong: "Memotorisasi",
    chongzhizhong: "Memuatkan ulang",
    shouquanchenggogn: "Otorisasi berhasil"
  },
  Bill: {
    title: "tagihan",
    date: "waktu",
    leixing: "tipe",
    coin: "valuta",
    num: "jumlah",
    finished: "Tidak ada lagi",
    all: "seluruh",
    shouyi: "pendapatan",
    chongzhi: "Isi ulang",
    tixian: "Pengunduran",
    zhuanzhang: "Transfer",
    zhuanhuan: "konversi",
    zengjian: "meningkat/menurun",
    xiaofei: "membeli"
  },
  NodeRecord: {
    title: "Catatan Pembelian",
    date: "waktu",
    node: "node",
    amount: "uang",
    finished: "Tidak ada lagi"
  },
  Bonus: {
    title: "pendapatan",
    date: "waktu",
    type: "tipe",
    jine: "uang",
    finished: "Tidak ada lagi",
    jinri: "Gaji hari ini",
    leiji: "pendapatan terkumpul"
  },
  Team: {
    title: "Daftar dorongan langsung",
    address: "alamat",
    jine: "uang",
    jibie: "level",
    wu: "tidak ada",
    yeji: "Kinerja Mesin Tambang",
    jiedianyeji: "Performasi node",
    aiyeji: "Performasi AI"
  },
  User: {
    home: "halaman rumah",
    chongzhi: "Pusat Voucher",
    bonus: "Keuntunganku",
    ai: "Data AI",
    mining: "penambangan",
    notice: "pengumuman"
  },
  Notice: {
    title: "pengumuman",
    details: "Perincian Pengumuman"
  },
  TabBar: {
    home: "pasar",
    ai: "Saksi AI",
    mining: "Pusat Kekayaan",
    team: "tim",
    user: "Aku"
  },
  Market: {
    dagnqianjiage: "Harga saat ini",
    chushi: "Harga awal",
    mairu: "Beli",
    maichu: "Jual",
    liudongxing: "Liquidity",
    xinxi: 'Informasi',
    kongtou: "CCAI Airdrop Rewards",
    dangqian: "Total",
    daibixiaohui: 'Pembakaran Token CCAI',
    xiaohuizongliang: 'Total Jumlah CCAI yang Dibakar',
    chubirenshu: 'Jumlah Pemegang CCAI',
    faxingzongliang: 'Total Jumlah CCAI yang Diterbitkan',
    jiedian: 'Node',
    jine: 'Jumlah',
    suanli: 'Hashrate',
    zuori: "kemarin",
    jinri: "hari ini"
  },
  AiData: {
    zongjiazhi: "Kunci dalam Nilai Total (USD)",
    celue: "Strategi Jaringan Algoritma",
    totalMsg: "Citadel adalah salah satu dana penagihan kuantitatif yang paling mempengaruhi di dunia, menggunakan intelijen buatan kompleks dan model belajar mesin untuk mengelola aset dan optimisasi transaksi pasar. Departemen perdagangan elektronik Citadel Securities memanfaatkan teknologi AI secara ekstensif untuk meningkatkan efisiensi eksekusi perdagangan. Robot CITADEL AI akan mengembangkan dan meningkatkan strategi dalam waktu nyata berdasarkan belajar dalam dan mengawasi ratusan transaksi pada data besar setiap hari, dan bentuk instruksi, menyediakan dukungan teknis dan data yang cukup, didukung oleh data besar dan kekuatan komputer. Ini memiliki lebih dari 10000 sumber data dan 95000 CPU dengan lebih dari 1000TB.",
    botTitle: "Jumlah perintah transaksi",
    jiangli: "hadiah",
    rilirun: "Peruntungan harian rata-rata",
    yuelirun: "Gaji bulanan",
    jiage: "harga",
    zjc: "Pool dana",
    ailh: "Ai Weiwei",
    aijy: "Ini sangat mudah.",
    poolStats: "Statistik Pool",
    arbitrageStats: "Statistik Arbitrage",
    zongyue: "Total keseimbangan",
    baozhengjin: "Deposit (USDT)",
    qianbaoyue: "Imbangan dompet (USDT)",
    yunxingyingkui: "Untung (USDT)",
    zongyingkui: "Total (USDT)",
    name: "valuta",
    price: "harga",
    aiweituo: "Delegasi AI",
    aimingxi: "Rincian transaksi AI",
    dingyue: "Berlangganan",
    jilu: "Catatan",
    ziliaoku: "Database",
    chengjiaoliang: "Volume Perdagangan",
    jiaoyidui: "Pasangan Perdagangan",
    chengjiaotu: "Grafik Volume",
    paixu: "Urut",
    bizhong: "Mata Uang",
    price24: "Harga (24 Jam)",
    wending: "Koneksi Stabil",
    zhichi: "Dukungan Online"
  },
  Mining: {
    mingcheng: "Nama Token",
    fuhao: "Simbol tanda",
    gongyingliang: "Total pasokan",
    qishi: "Harga awal",
    jilu: "Catatan tugas",
    chakan: "cek",
    aibot: "robot AI",
    rishouyi: "Penghasilan sehari",
    weituojine: "Jumlah yang dipercaya",
    weituo: "entrust",
    aggregation: "Robot Aggregasi",
    placeholder1: "Silakan masukkan jumlah",
    queren: "konfirmasi",
    jijiangkaifang: "Segera datang",
    echartsName1: "Allokasi komunitas",
    echartsName2: "Allokasi node",
    echartsName3: "Allokasi fondasi",
    echartsName4: "kolam tambang komputasi",
    placeholder2: "Silakan masukkan",
    loading: "Menakjubkan",
    jiqiren1: "S1 Robot",
    jiqiren2: "S2 Robot",
    jiqiren3: "S3 Robot",
  },
  TeamCount: {
    tuijian: "rekomendasi",
    tuijianren: "Rekomendasiku",
    lianjie: "Panggil Sambungan",
    tuandui: "tim",
    teamCount: "Jumlah rekaman tim",
    liebiao: "Daftar dorongan langsung",
    usYejiTotal: "Performasi AI pribadi",
    teamYejiTotal: "Performasi tim AI",
    rtaShouyi: "Penghasilan AI",
    rtbShouyi: "Daftar terkumpul",
    ktDay: "Volum jatuh udara sehari",
    ktTotal: "Gaji IDO",
    jiedianyeji: "Performasi Node Tim",
    usjiedianyeji: "Performasi node individu",
    usxh: "Kehancuran agregasi pribadi",
    teamxh: "Pemusnahan Aggregasi Tim",
    jiedianshouyi: "Penghasilan node",
    youxiao: "Jumlah anggota tim yang efektif"
  },
  Wallet: {
    total: "total aset",
    bill: "tagihanku",
    bonus: "Keuntunganku",
    maichu: "Jual",
    chongzhi: "Isi ulang",
    tixian: "Pengunduran",
    mairu: "Beli",
    shandui: "Penukaran Flash",
    keyong: "tersedia",
    dongjie: "USDA",
    zichan: "aset",
    zhuanzhang: 'Transfer'
  },
  Convert: {
    mairu: "Beli",
    maichu: "Jual",
    shandui: "Penukaran Flash",
    yue: "balance",
    placeholder1: "Silakan masukkan jumlah",
    edu: "Batas subskripsi",
    sxf: "Gaji pengurusan",
    ranliao: "CCAI bahan bakar yang sama",
    querenmairu: "Konfirmasi Pembelian",
    querenmaichu: "Konfirmasi penjualan",
    message1: "Apakah Anda yakin Anda ingin membeli?",
    message2: "Kau yakin ingin menjual?",
    loading1: "Memuatkan",
    message3: "Kau yakin ingin mengubah?",
    loading2: "Penukaran Flash dalam proses"
  },
  Tixian: {
    title: "Pengunduran",
    bill: "tagihanku",
    chakan: "cek",
    select: "Silakan pilih uang",
    numLabel: "Silakan masukkan kuantitas penarikan",
    placeholder: "Kuantitas tarik",
    addressLabel: "Silakan masukkan alamat penarikan",
    placeholder2: "Alamat penarikan",
    yue: "Balansi dompet",
    sxf: "Gaji pengurusan",
    queding: "konfirmasi",
    message1: "Silakan pilih uang",
    message2: "Silakan masukkan kuantitas penarikan",
    message3: "Kau yakin ingin mundur?",
    loading: "Menarik"
  },
  RobotUserRecord: {
    title: "Catatan tugas",
    date: "waktu",
    robot: "robot",
    jine: "uang",
    state: "keadaan",
    finished: "Tidak ada lagi",
    shouyi: "Dalam pendapatan",
    shuhui: "penebusan",
    yijieshu: "Akhir",
    yishuhui: "Ditebak",
    empty: "Tidak ada catatan yang tersedia",
    message: "Kau yakin ingin menebusnya?",
    loading: "Redemption dalam proses",
    ranshao: "Penghargaan bahan bakar",
    suanli: "Kuasa komputasi"
  },
  NodePower: {
    title: "Kuasa komputer nodal",
    date: "Waktu balasan",
    jine: "Jumlah bayaran",
    finished: "Tidak ada lagi",
  },
  Record: {
    cz: 'Isi ulang Catatan',
    tx: "Catatan penarikan",
    shijian: "waktu",
    jine: "uang",
    hash: "Hash Transaksi",
    zz: "Catatan transfer",
    suserid: "Pembayar"
  },
  Zhuanzhang: {
    title: "Transfer",
    bill: "Catatan transfer",
    chakan: "cek",
    select: "Silakan pilih uang",
    numLabel: "Silakan masukkan jumlah transfer",
    placeholder: "Beralih jumlah",
    addressLabel: "Silakan masukkan alamat penerima",
    placeholder2: "Alamat Penerima",
    yue: "Balansi dompet",
    sxf: "Gaji pengurusan",
    queding: "konfirmasi",
    message1: "Silakan pilih uang",
    message2: "Silakan masukkan jumlah transfer",
    message3: "Kau yakin ingin memindahkan uang?",
    loading: "Memindahkan"
  },
  AiTradeDetail: {
    title: "Rincian transaksi",
    pingcang: "Posisi menutup",
    jiage: "Harga (USDT)",
    num: "Kuantitas (USDT)",
    shouyi: "pendapatan",
    yongxu: "sustainable"
  },
  Service: {
    shuruwenti: "Silakan masukkan pertanyaan Anda",
    bunengweikong: "isi pesan tidak dapat kosong",
    shangchuanshibai: "Gagal mengunggah gambar",
    message: "Silakan mengunggah gambar dalam 5M",
    message2: "Silakan mengunggah gambar dalam format JPG"
  },
  Subscribe: {
    title: "Langganan",
    zhineng: "Perdagangan Kecerdasan Buatan",
    zongdingyue: "Total Langganan",
    zonghuoyue: "Total Aktif",
    zhungtai: "Status",
    shuchu: "Output Maksimum",
    zhanghu: "Rekening",
    beidongshouru: "Pendapatan Pasif",
    zhudongshouru: "Pendapatan Aktif",
    zongshouru: "Total Pendapatan",
    mima: "Kata Sandi"
  },
  aiLogin: {
    title: "Selamat datang kembali!",
    label: "Silakan masukkan akun Anda untuk masuk",
    account: "Akun",
    password: "Kata sandi",
    check: "Ingat saya",
    submit: "Masuk",
    placeholder1: "Masukkan akun Anda",
    placeholder2: "Masukkan kata sandi Anda"
  },
  Trading: {
    tubiao: "Grafik",
    huobi: "Mata Uang",
    jiage: "Harga",
    buy: "Beli"
  },
  NavBar: {
    logout: "Keluar",
    shouye: "Beranda",
    positions: "Posisi Terbuka",
    closedPositions: "Posisi Ditutup",
    aiConfig: "Konfigurasi AI"
  },
  AiConfig: {
    tab1: "Ikhtisar",
    tab2: "Data",
    tab3: "Portofolio Investasi",
    tab4: "Riwayat Perdagangan",
    tab5: "Catatan Deposit",
    biaoxian: "Performa",
    guanyu: "Tentang",
    huichou: "Total Kembalian",
    dingyuejine: "Jumlah Langganan Minimum",
    shuju: "Data",
    lirun: "Keuntungan Terbaik",
    lirunbaifenbi: "Persentase Keuntungan Transaksi",
    jiaoyishu: "Total Transaksi",
    dingdan: "Pesanan",
    riqi: "Tanggal",
    leixing: "Jenis Transaksi",
    jine: "Jumlah",
    huobi: "Mata Uang",
    maimai: "Beli/Jual",
    kaipiaojia: "Harga Tagihan",
    kaidanriqi: "Tanggal Pesanan",
    dangqianjiage: "Harga Saat Ini",
    chengjiaoliang: "Volume",
    yingkui: "Keuntungan/Kerugian",
    shoupanjia: "Harga Penutupan",
    guandanriqi: "Tanggal Penutupan"
  },
  Pledge: {
    zhiya: "Jaminan",
    zhiyajilu: "Catatan Jaminan",
    xuanzezhouqi: "Pilih Siklus",
    qingxuanzezhouqi: "Silakan pilih siklus",
    zhiyashuliang: "Jumlah Jaminan",
    qingshurubeishu: "Silakan masukkan 100 atau kelipatannya",
    zhifufangshi: "Metode Pembayaran",
    querenzhiya: "Konfirmasi Jaminan",
    qingshurushuliang: "Silakan masukkan jumlah",
    zhiyazhong: "Memproses jaminan...",
    shijian: "Waktu",
    zhouqi: "Siklus",
    jine: "Jumlah",
    zhuangtai: "Status",
    chezi: "Tarik",
    shouyizhong: "Mendapatkan keuntungan",
    yichezi: "Telah ditarik",
    querenchezi: "Konfirmasi penarikan?",
    chezizhong: "Proses penarikan..."
  }
}