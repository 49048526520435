<template>
  <div class="page">
    <header-bar ref="headerBarRef" :showBack="true" :transparent="true" menulx="2" noticeColor="#fff"></header-bar>

    <div style="margin-top: -40px;">
      <title-name :title="$t('Subscribe.title')" label="Subscribe"></title-name>
    </div>

    <div class="container">
      <div class="total">
        <div class="total-title">{{ $t('Subscribe.zhineng') }}</div>
        <div class="row q-mt-md">
          <div class="col text-center">
            <div>{{ $t('Subscribe.zongdingyue') }}</div>
            <div>{{ pageData.jine1 }}</div>
          </div>
          <div class="col text-center">
            <div>{{ $t('Subscribe.zonghuoyue') }}</div>
            <div>{{ pageData.jine2 }}</div>
          </div>
        </div>
      </div>

      <div class="card justify-center q-mt-md">
        <div class="card-item">
          <div class="cell">
            <div>{{ $t('Subscribe.title') }}：</div>
            <div>{{ pageData.jine1 }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('Subscribe.zhungtai') }}：</div>
            <div>{{ pageData.state }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('Subscribe.shuchu') }}：</div>
            <div>{{ pageData.max }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('Subscribe.zhanghu') }}：</div>
            <div>{{ pageData.account }}</div>
          </div>
        </div>
        <div class="card-border"></div>
        <div class="card-item">
          <div class="cell">
            <div>{{ $t('Subscribe.beidongshouru') }}：</div>
            <div>{{ pageData.shouru1 }}</div>
          </div>
          <div class="cell">
           <div>{{ $t('Subscribe.zhudongshouru') }}：</div>
           <div>{{ pageData.shouru2 }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('Subscribe.zongshouru') }}：</div>
            <div>{{ pageData.shouru3 }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('Subscribe.mima') }}：</div>
            <div>{{ pageData.password }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import { ref } from 'vue'

export default {
  components: {
    HeaderBar,
    TitleName
  },
  setup() {
    return {
      us: ref(null),
      pageData: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getAccount()
  },
  methods: {
    getAccount() {
      let _this = this
      _this.$request.post(
        "api/Account/GetAccount",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.pageData = data
            // console.log(data)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.container {
  padding: 10px;
}

.card {
  padding: 10px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
}

.card-border {
  margin: 0 20px;
  width: 1px;
  background-color: rgba(214, 208, 208, 0.5);
}

.cell {
  display: flex;
  padding: 10px 0;
}

.cell div:first-child {
  width: 80px;
}

.cell div:last-child {
  flex: 1;
  text-align: right;
}

.total {
  padding: 15px 10px;
  border-radius: 10px;
  background-color: #fff;
}

.total-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
</style>