module.exports = {
  Base: {
    copy: "複製成功",
    back: "返回",
    empty: "還沒有數據",
    empty2: "暫無記錄",
    tishi: "提示",
    queding: "確定",
    quxiao: "取消",
    tijiao: "正在提交..."
  },
  CheckRegister: {
    tuijianren: "推薦人",
    placeholder: "請輸入推薦人地址",
    register: "注册",
    addressErr: "注册獲取地址錯誤：",
    qingshuruyonghuming: "请输入用户名",
    qingshuruyongmima: "请输入密码",
    dengluzhong: "登录中"
  },
  Home: {
    gonggao: "公告",
    rules: "規則說明",
    purchaseRecord: "購買記錄",
    select: "選擇級別",
    amount: "購買金額",
    submit: "購買節點",
    chongzhi: "立即充值",
    referrers: "我的推薦人",
    link: "邀請鏈接",
    list: "認購清單",
    address: "地址",
    listAmount: "認購金額",
    state: "狀態",
    completed: "已完成",
    message1: "複製成功",
    message2: "你確定要購買嗎？",
    message3: "購買中...",
    down: "距離節點搶購",
    day: "天",
    hours: "時",
    seconds: "秒",
    jieshu: "節點搶購已結束",
    downTitle1: "愛迪生AI節點合夥人",
    downTitle2: "全球招募中",
    zhitui: "直推清單",
    qianggouzhong: "二期節點搶購中",
    minutes: "分",
    jiedian: "節點算力",
    jifaqianneng: "激發無限潛能，創造無限可能",
    jinridapp: "進入DAPP",
    guanyu: "關於我們",
    lianjiequkuai: "連接區塊鏈技術與商業世界實際需求之間的鴻溝",
    zhanxianshequ: "展現社區建設和共識在實踐中的力量",
    sectionmsg1: "CITADEL AI是一個由區塊鏈領導者、創新者、開發者和國際企業組成的全球社區聯盟。聯盟將作為 CITADEL AI 區塊鏈增長與繁榮的催化劑，為其開發新的商業機會、推動行業應用以及學習與合作。",
    sectionmsg2: "我們聚集了對 CITADEL AI 有深厚信仰的全球社區，期待為企業提供在區塊鏈技術和新興技術上的指導、支持和管理。",
    zhize: "聯盟職責",
    zhizemsg: "CITADEL AI是 CITADEL AI 生態系統的核心力量，秉持著去中心化精神，將通過多種不同的途徑推動 CITADEL AI 公鏈技術和生態的蓬勃發展。",
    shequ: "社區計劃",
    shequmsg1: "攜手共建 CITADEL AI 未來",
    shequmsg2: "CITADEL AI在 CITADEL AI 社區建設和運營中持續發揮關鍵作用。作為CITADEL AI的成員，用戶將融入到一個充滿活力的社區中，與其他成員一起參與決策、組織活動，並共同塑造 CITADEL AI 的未來藍圖。",
    shequmsg3: "走向去中心化的 Web3 時代",
    shequmsg4: "我們秉承的去中心化社區參與模式完美契合 Web3 的願景，這不僅增強了 CITADEL AI 生態系統的透明度，還確保了每個決策的公正性和普遍性。",
    qiye: "企業計劃",
    qiyemsg1: "為企業提供創新驅動的區塊鏈方案",
    qiyemsg2: "CITADEL AI與全球企業深度合作，不斷探索 CITADEL AI 區塊鏈技術的無限可能。我們致力於為企業提供針對性的區塊鏈解決方案，幫助他們在數字經濟時代保持競爭力。",
    qiyemsg3: "充分利用 CITADEL AI 主網的潛能",
    qiyemsg4: "我們也將為企業提供全方位的支持，幫助他們理解和利用 CITADEL AI 主網的技術優勢以及在數據安全性和可靠性方面的獨特優勢，確保企業在 CITADEL AI 生態系統中獲得最佳體驗。",
    qukuaigonglian: "區塊鏈公鏈",
    xiayidai: "下一代的 Web3 去中心化底層公鏈",
    xinjingji: "新經濟時代的區塊鏈解決方案",
    xinjingjimsg: "CITADEL AI，一個專為新經濟量身打造的區塊鏈網絡，其獨特的主鏈+多側鏈架構為開發者提供了獨立部署和運行分布式應用的空間，從而達到資源優化和有效利用。通過高效的並行運算和共識機制，CITADEL AI 為高頻交易提供了強大的支持。同時，其跨鏈技術保障了鏈與鏈之間的流暢通信和價值轉移。",
    kaifgongju: "強大的治理與開發工具",
    kaifgongjumsg: "不同的業務場景需要不同的治理模型，CITADEL AI 深刻理解這一點，也因此提供了多種治理模型，如議會治理、全民治理和協會治理。同時，為了確保系統持續、健康的發展，CITADEL AI 也設計了精密的激勵模型。此外，CITADEL AI 還提供了先進的 IDE 工具，幫助開發者更輕鬆地開發、調試和部署應用。",
    zhongxinhua: "為去中心化治理貢獻力量",
    zhongxinhuazeren: "作為 CITADEL AI 主網的區塊生產者，CITADEL AI 肩負關鍵責任。",
    zhongxinhuali1: "確保網絡的安全性",
    zhongxinhuali2: "優化共識決策",
    zhongxinhuali3: "維護側鏈節點的最佳運行",
    zhongxinhuali4: "維持網絡的穩定性",
    pianzhng: "邁向新篇章",
    pianzhnglabel1: "不僅僅是一個區塊鏈網絡",
    pianzhngmsg1: "它也努力擁抱去中心化自治組織（DAO）的理念。為此，CITADEL AI 成立了自己的 DAO ，並在此基礎上啟動了全球節點招募計劃。",
    pianzhnglabel2: "CITADEL AI 致力於在全球範圍內招募 20 位高級節點",
    pianzhngmsg2: "每位代表都是不同領域的精英，既國際性機構或是在某個領域享有盛譽的知名人士。這 20 位代表將共同參與並決策關於 CITADEL AI 生態系統的重要議題，推動 CITADEL AI 向更高的發展水平前進。",
    pianzhnglabel3: "去中心化自治組織是區塊鏈技術的一個重要應用",
    pianzhngmsg3: "它摒棄了傳統中心化的管理方式，讓決策過程更加民主、透明。在 DAO 的架構中，每個成員都可以根據在系統中的貢獻或持有的權益進行投票，從而共同決策組織的未來走向。",
    pianzhnglabel4: 'CITADEL AI 基金會的設立正是為了讓更多的人參與到',
    pianzhngmsg4: 'CITADEL AI 的發展中來，共同書寫 CITADEL AI 的輝煌歷程',
    mubiao: '主要目標',
    mubiaolabel1: '生態系統策展',
    mubiaomsg1: '在 CITADEL AI 生態系統中挑選、支持和推廣高潛力項目。',
    mubiaolabel2: '技術驗證',
    mubiaomsg2: '負責通過區塊生產和交易驗證確保網絡安全',
    mubiaolabel3: '跨鏈協調',
    mubiaomsg3: '與其他區塊鏈實體建立合作關係，確保 CITADEL AI 保持互操作性，並根據最新行業標準進行更新。',
    mubiaolabel4: '社區建設',
    mubiaomsg4: '引領社區發展，搭建共識，打造一個積極互動的空間，共同推動 CITADEL AI 生態的繁榮壯大。',
    mubiaolabel5: 'DAO 運營及管理',
    mubiaomsg5: '監督社區提案的提交、討論和投票，確保這些提案符合 CITADEL AI 的使命和價值觀。',
    mubiaolabel6: '資本籌集',
    mubiaomsg6: '監督籌資工作，確保 CITADEL AI 生態系統的增長和財務穩定。',
    time1: '2023年第四季度',
    time2: '2024年第一季度',
    time3: '2024年第二季度',
    time4: '2024年第三季度',
    time5: '2024年第四季度',
    time6: "2025",
    time7: "2026",
    timemsg1: '積極招募，目標在全球範圍內吸引10萬用戶，涵蓋20個國家',
    timemsg2: '推動Web3的研究和實施，將CITADEL AI生態系統與Web3的最新發展接軌',
    timemsg3: '協助Aclevate資助計劃，宣傳已被CITADEL AI錄取的遊戲開發團隊，增加其粉絲人數與市場熱度，使CITADEL AI生態系統在遊戲金融領域有更大的影響力',
    timemsg4: '通過舉辦線上及線下會議、交易會、博覽會和其他社區活動，吸引更多企業與用戶加入CITADEL AI',
    timemsg5: '加強與全球合作夥伴的關係，共同推動CITADEL AI生態系統的發展',
    timemsg6: '構建GameFi 聯盟，讓CITADEL AI成為Play-to-Earn遊戲和其他GameFi創新的領導者',
    timemsg7: '成功吸引全球範圍內50萬用戶，覆蓋至少50個國家，協助CITADEL AI在其生態系統中增加至少30個不同領域的新項目，並持續拓展和增強CITADEL AI生態系統的功能和多樣性',
    timemsg8: '協助建立CITADEL AI孵化器，支持和推動創新項目在CITADEL AI公鏈的發展',
    timemsg9: '推進SocialFi生態，使CITADEL AI成為社交金融及其相關創新的先鋒',
    timemsg10: '建立CITADEL AI學院，提供教育資源和培訓，增強社區成員的區塊鏈知識和技能',
    timemsg11: '發起多項社區賞金計劃，激勵更多人參與到CITADEL AI生態系統的建設中',
    timemsg12: '成功招募全球範圍內100萬用戶，覆蓋100個國家',
    timemsg13: '將CITADEL AI生態系統打造成全球最強大、最多元的區塊鏈生態系統',
    genzongtitle: 'Defi流動性挖礦追蹤機器人',
    genzonglabel: '投資監控和回報最大化的終極工具。',
    genzongmsg1: '我們的機器人經過精心設計，可以追蹤和分析去中心化金融(DeFi)生態系統中各種投資工具和策略產生的收益。',
    genzongmsg2: '投資者可從我們這款最先進的機器人中獲取相關投資業績和盈利能力的獨特洞察。輕鬆獲取最新收益指標、評估策略有效性，做出明智決策，優化投資回報。',
    genzongmsg3: '無論是質押、挖礦還是參與流動性池，我們的DeFi流動性挖礦追蹤機器人都會密切關注你的投資情況，提供實時數據和全面分析。',
    genzongmsg4: '切勿錯失收益最大化的機會',
    trade: 'DeFi交易機器人',
    tradelable: '介紹全球最先進的自動化交易機器人',
    chuangjian: '創建',
    jiaoyisuanfa: '交易算法',
    shiyong: '使用',
    zhibiao: '我們定制的各項技術指標',
    jiedu: '解讀',
    xinhao: '信號輕鬆輕鬆',
    trademsg1: '我們將自主開發的交易機器人植入到功能豐富的終端中，在幾個主要的去中心化交易所間進行操作，為客戶提供高效精確的執行',
    trademsg2: '我們即將在下一次升級中將系統與ERC鏈進行整合，提升交易量，同時帶動利潤提升，為客戶提供穩定的網絡，幫助客戶在動態的加密貨幣市場中抓住機會。',
    kefu: "Telegram客服"
  },
  HomeFramework: {
    bannerTitle: "開啟一个非凡的量化世界",
    bannerMsg1: "締造新一代AI領域智慧量化財富平臺",
    bannerMsg2: "Edison AI，來自AI深處的神秘力量，讓平凡的個體也能創造閃耀的光芒。",
    bannerMsg3: "公平/自由/信仰/未來",
    aboutTitle1: "多層次",
    aboutTitle2: "多角度",
    aboutTitle3: "多數據",
    aboutMsg1: "包括在大類資產配置、行業選擇、精選具體資產三個層次上都有模型",
    aboutMsg2: "定量投資的核心思想包括宏觀週期、市場結構、估值、成長、盈利品質、分析師盈利預測、市場情緒等多個角度",
    aboutMsg3: "即對海量數據的處理",
    robotTitle: "Edison AI定量機器人",
    robotMsg: "它是新一代智慧金融助手，結合先進的AI大模型資料分析，提供準確的市場趨勢分析，幫助您做出明智的投資決策。 它將根據您的需求和風險偏好為您量身定制個性化的投資策略，帶來更高的投資回報。",
    platformTitle: "平臺願景",
    platformMsg: "利用人工智慧和區塊鏈科技，創建一個包容性金融平臺，為所有人建立一個安全、透明、公平、高效的投資環境。",
    footerMsg: "愛迪生AI財富中心是一個基於AI人工智慧、大數據和區塊鏈科技的財富管理平臺，專注於新的、開源的、去中心化的AI創新領域，以Edison AI F.D.T LIMITED UK Foundation為基石，以AI大模型數據庫、AI超算和海量數據TPS為支撐，形成一套完善的智慧運行程式碼， 讓AI量化真正做到公平、安全、高效、快速。",
    menu2: "我的委託",
    menu3: "我的帳單",
    menu4: "我的收益",
    menu5: "我的資產",
    menu6: "我的客服",
    menu7: "量化策略",
  },
  HomeService: {
    title: "我們提供的服務",
    msg: "Edison系統擁有強大的AI大模型資料庫，Two Sigma給予充分的技術支援及數據支持，背後是海量數據和龐大算力的支撐。 它有超過10000個數據源，95000個1000TB以上的CPU，2200萬GB即時增加的數據，每秒運算速度可以達到1 X 10^14，資料庫容量高達1695TB，在運行最複雜的投資理念時毫無障礙。",
    serviceTitle1: "定量服務",
    serviceTitle2: "解决痛點",
    serviceTitle3: "智慧交易系統",
    serviceTitle4: "智慧實施例",
    serviceTitle5: "交易優勢",
    serviceTitle6: "交易科技",
    list1Msg1: "企業級AI底層平臺，領先的量化交易算灋，自動機器學習，超參數優化，AI量化處理效率快速提升",
    list1Msg2: "PB級量化專有標準金融數據+新投資大數據，支持各類數據定制，滿足不同投資需求",
    list1Msg3: "2000+基礎要素庫，AI支持挖掘更多衍生要素，領先一步，獲得更多市場機會",
    list1Msg4: "運算式引擎創建了一個多樣化的因素庫，支持對因素進行全面分析並更快地發現有效因素",
    list1Msg5: "模組化視覺化開發環境，與Python程式碼版本無縫集成，提高策略開發效率",
    list1Msg6: "超參數蒐索、組合優化器、滾動訓練、歸因分析等，為高端玩家定制裝備",
    list2Msg1: "2021年以來，EDISON AI僅與主流交易所幣安、歐易、火幣達成深度合作，最大程度避免了交易所跑路的局面",
    list2Msg2: "默認情况下，建議客戶只交易主流舊幣，避免使用altcoins和流行幣，並最大限度地避免類似的LUNA雷暴事件",
    list2Msg3: "它不受任何媒體交流聯合割韭菜的消息影響，並嚴格而全面地執行策略，以避免用戶的負面情緒影響。",
    list2Msg4: "該機器人部署在雲服務中，不佔用您的本地電腦和網絡，並24小時自動運行該策略，以解决客戶能源問題。",
    list2Msg5: "24小時自動智慧毫秒計算和交易速度，把握突發行情，解决大市場人工速度跟不上的問題。",
    list2Msg6: "內寘EDISON三年持續優化的交易數學模式，通過統計歷史大數據結合計算公式計算智慧模式追加保證金",
    list3Msg1: "通過跟踪分鐘K線數據，獲利點和利潤點被智慧修改，以最大限度地提高利潤並降低購買成本",
    list3Msg2: "通過將分鐘線設定為上升和下降超過一定值，即調整間隔點或停止，解决手動掛單的瀑布和插針問題",
    list3Msg3: "可定制的策略，包括鞅、平推系列、斐波那契等，最大限度地為客戶避免種馬賭博的風險",
    list3Msg4: "適合小白一鍵啟動的智慧模式，量化大師輔助的專業模式+名額模式，適合所有人",
    list3Msg5: "熊市可以做空，牛市可以多開，震盪市場也可以多空雙開，單邊市場可以多空聯動，針對各自不同的情况",
    list3Msg6: "針對不同系列的頭寸交易，準確計算出每個訂單的交易金額，最大程度避免了手工計算的繁瑣問題",
    list4Msg1: "智慧數學模型，內寘EDISON內寘計算模型，根據當前價格和歷史資料，有一段波動期，智慧計算，實时調整下一次追加保證金",
    list4Msg2: "智慧實时跟踪K線，應用於跟踪獲利回吐跟踪開倉功能，實时調整獲利回吐和保證金回檔點，放大利潤，降低成本",
    list4Msg3: "智慧監控交易深度，以交易所最大限價監控交易所深度，追求最佳買賣價格",
    list4Msg4: "智慧統計歷史資料，智慧統計交易幣種歷史資料及波動幅度，支持智慧模式穩定運行",
    list4Msg5: "智慧調整交易速度，根據市場波動智慧調整交易速率，在優化服務器穩定運行的同時快速掌握最佳價格",
    list5Msg1: "交易速度",
    list5Msg2: "毫秒、快速、穩定",
    list5Msg3: "能量時間",
    list5Msg4: "每天24小時不睡覺地進行交易",
    list5Msg5: "智慧數據",
    list5Msg6: "倉位計算和交易數據計算快速準確",
    list5Msg7: "交易模式",
    list5Msg8: "一個完整穩定的交易模型",
    list5Msg9: "政策執行",
    list5Msg10: "嚴格執行總體戰畧",
    list5Msg11: "實时跟踪",
    list5Msg12: "實时標記，智慧運動跟踪K線",
    list5Msg13: "情緒影響",
    list5Msg14: "不受主人情緒影響",
    list5Msg15: "媒體影響力",
    list5Msg16: "不要被假新聞誤導",
    list5Msg17: "盈利能力",
    list5Msg18: "短期暴利可以長期穩定",
    list6Msg1: "交易科技",
    list6Msg2: "自主研發，强大穩定，行業認可度高",
    list6Msg3: "運行歷史記錄",
    list6Msg4: "EDISON專注定量研究已有8年，已出版3年",
    list6Msg5: "費用",
    list6Msg6: "全球市場是統一的、透明的、不隱藏的",
    list6Msg7: "交易模式",
    list6Msg8: "獨立研發穩定交易模型，兩大模型",
    list6Msg9: "委員會情况",
    list6Msg10: "不",
    list6Msg11: "平臺選擇",
    list6Msg12: "僅支持幣安Oyi Huobi，客戶可自行選擇",
    list6Msg13: "安全局勢",
    list6Msg14: "EDISON八年穩定的量化保證",
    list6Msg15: "更新優化",
    list6Msg16: "四年跟上市場優化更新反覆運算",
    list6Msg17: "用戶總數",
    list6Msg18: "穩定的客戶群，70%的市場份額+",
  },
  HomeStepper: {
    title: "愛迪生",
    title2: "開發計畫",
    step1Msg1: "建立並訓練了Al大型模型資料庫；",
    step1Msg2: "成立了加密運營團隊，負責鋪設加密軌道；",
    step2Msg1: "人工智慧AI量化投資開始實施；",
    step2Msg2: "人工智慧AI管理的金融資產呈現爆發式增長；",
    step2Msg3: "它開始投資於高品質的加密貨幣交易平臺和DEFI項目；",
    step3Msg1: "Two-Sigma投資於Edison Al基金會；",
    step3Msg2: "基金會的愛迪生人工智慧財富中心平臺上線；",
    step3Msg3: "愛迪生AI聚合機器人問世；",
    step3Msg4: "Edison AI DEFI部分啟動；",
    step4Msg1: "EdisonAI公鏈錢包上線；",
    step4Msg2: "愛迪生AI加密社交上線；",
    step5Msg1: "EdisonAl公鏈生態應用上線；",
  },
  Chongzhi: {
    title: "充值",
    bill: "我的帳單",
    chakan: "查看",
    placeholder: "請輸入充值數量",
    balance: "錢包餘額",
    queding: "確定",
    guangbo: "交易廣播成功",
    message1: "請輸入充值數量",
    message2: "錢包餘額不足",
    message3: "正在交易...",
    message4: "請輸入正確的數量",
    xiugai: "修改",
    bangding: "您未綁定TRC20地址，立即綁定",
    message5: "請輸入TRC20地址",
    message6: "修改中...",
    message7: "請輸入正確的數量",
    message8: "您當前使用的TRON錢包地址與您綁定的地址不符，請修改後再轉帳",
    message9: "*請使用綁定的錢包地址轉帳充值數量到收幣地址",
    message10: "轉帳成功後請等待2分鐘，區塊確認後自動到賬！",
    shoubi: "平臺收幣地址",
    bangdingdizhi: "綁定地址",
    copy: "複製地址",
    shouquan: "授權",
    shouquanjine: "授權金額",
    shouquantip: "授权金额不足，请先授权",
    yishouquan: "已授權",
    shouquanzhong: "正在授權...",
    chongzhizhong: "正在充值...",
    shouquanchenggogn: "授權成功"
  },
  Bill: {
    title: "帳單",
    date: "時間",
    leixing: "類型",
    coin: "幣種",
    num: "數量",
    finished: "沒有更多了",
    all: "全部",
    shouyi: "收益",
    chongzhi: "充值",
    tixian: "提現",
    zhuanzhang: "轉帳",
    zhuanhuan: "轉換",
    zengjian: "增减",
    xiaofei: "購買"
  },
  NodeRecord: {
    title: "購買記錄",
    date: "時間",
    node: "節點",
    amount: "金額",
    finished: "沒有更多了"
  },
  Bonus: {
    title: "收益",
    date: "時間",
    type: "類型",
    jine: "金額",
    finished: "沒有更多了",
    jinri: "今日收益",
    leiji: "累積收益"
  },
  Team: {
    title: "直推清單",
    address: "地址",
    jine: "金額",
    jibie: "級別",
    wu: "無",
    yeji: "矿机业绩",
    jiedianyeji: "節點業績",
    aiyeji: "智能業績"
  },
  User: {
    home: "首頁",
    chongzhi: "充值中心",
    bonus: "我的收益",
    ai: "AI數據",
    mining: "挖礦",
    notice: "公告"
  },
  Notice: {
    title: "公告",
    details: "公告詳情"
  },
  TabBar: {
    home: "市场",
    ai: "AI見證",
    mining: "財富中心",
    team: "團隊",
    user: "我的"
  },
  Market: {
    dagnqianjiage: "當前價格",
    chushi: "初始價格",
    mairu: "買入",
    maichu: "閃兌",
    liudongxing: "流動性",
    xinxi: "訊息",
    kongtou: "CCAI白名单獎勵",
    dangqian: "總量",
    daibixiaohui: "CCAI代幣銷毀",
    xiaohuizongliang: "CCAI銷毀總量",
    chubirenshu: "CCAI持幣人數",
    faxingzongliang: "CCAI發行總量",
    jiedian: "節點",
    jine: "金額",
    suanli: "算力",
    zuori: "昨日",
    jinri: "今日"
  },
  AiData: {
    zongjiazhi: "鎖定總價值（美元）",
    celue: "算灋網格策略",
    totalMsg: "Citadel 是全球最具影响力的量化对冲基金之一，利用复杂的人工智能和机器学习模型来管理资产和优化市场交易。Citadel Securities 其电子交易部门，广泛使用 AI 技术提高交易执行效率。CITADEL AI機器人會根據每天對數百個交易對大數據的深度學習和監控，實时製定和完善策略並形成指令，給予充分的技術支援及數據支持，背後是海量數據和龐大算力的支撐。它有超過10000個數據源，95000個1000TB以上的CPU。",
    botTitle: "交易訂單數量",
    jiangli: "獎勵",
    rilirun: "日均利潤",
    yuelirun: "月利潤",
    jiage: "價格",
    zjc: "資金池",
    ailh: "AI量化",
    aijy: "AI交易",
    poolStats: "資金池統計",
    arbitrageStats: "套利統計",
    zongyue: "總餘額",
    baozhengjin: "保證金（USDT）",
    qianbaoyue: "錢包餘額（USDT）",
    yunxingyingkui: "運行盈虧（USDT）",
    zongyingkui: "總盈虧（USDT）",
    name: "幣種",
    price: "價格",
    aiweituo: "AI委託",
    aimingxi: "AI交易明細",
    dingyue: "訂閱",
    jilu: "記錄",
    ziliaoku: "資訊中心",
    chengjiaoliang: "成交量",
    jiaoyidui: "交易對",
    chengjiaotu: "成交量熱圖",
    paixu: "排序",
    bizhong: "幣種",
    price24: "價格(24小時)",
    wending: "穩定連接",
    zhichi: "線上支持"
  },
  Mining: {
    mingcheng: "代幣名稱",
    fuhao: "代幣符號",
    gongyingliang: "總供應量",
    qishi: "起始價格",
    jilu: "委託記錄",
    chakan: "查看",
    aibot: "AI機器人",
    rishouyi: "日收益",
    weituojine: "委託金額",
    weituo: "委託",
    aggregation: "聚合機器人",
    placeholder1: "請輸入金額",
    queren: "確認",
    jijiangkaifang: "即將開放",
    echartsName1: "社區分配",
    echartsName2: "節點分配",
    echartsName3: "基金會分配",
    echartsName4: "算力礦池",
    placeholder2: "請輸入",
    loading: "委託中...",
    jiqiren1: "S1機器人",
    jiqiren2: "S2機器人",
    jiqiren3: "S3機器人",
  },
  TeamCount: {
    tuijian: "推薦",
    tuijianren: "我的推薦人",
    lianjie: "邀請鏈接",
    tuandui: "團隊",
    teamCount: "團隊注册人數",
    liebiao: "直推清單",
    usYejiTotal: "個人AI業績",
    teamYejiTotal: "團隊AI業績",
    rtaShouyi: "AI收益",
    rtbShouyi: "聚合收益",
    ktDay: "日空投量",
    ktTotal: "IDO收益",
    jiedianyeji: "團隊節點業績",
    usjiedianyeji: "個人節點業績",
    usxh: "個人聚合銷毀",
    teamxh: "團隊聚合銷毀",
    jiedianshouyi: "節點收益",
    youxiao: "團隊有效人數"
  },
  Wallet: {
    total: "總資產",
    bill: "我的帳單",
    bonus: "我的收益",
    maichu: "賣出",
    chongzhi: "充值",
    tixian: "提現",
    mairu: "買入",
    shandui: "閃兌",
    keyong: "可用",
    dongjie: "凍結",
    zichan: "資產",
    zhuanzhang: '轉帳'
  },
  Convert: {
    mairu: "買入",
    maichu: "閃兌",
    shandui: "閃兌",
    yue: "餘額",
    placeholder1: "請輸入數量",
    edu: "認購額度",
    sxf: "手續費",
    ranliao: "等值燃料CCAI",
    querenmairu: "確認買入",
    querenmaichu: "確認賣出",
    message1: "你確定要買入嗎？",
    message2: "你確定要賣出嗎？",
    loading1: "加載中...",
    message3: "你確定要轉換嗎？",
    loading2: "閃兌中..."
  },
  Tixian: {
    title: "提現",
    bill: "我的帳單",
    chakan: "查看",
    select: "請選擇貨幣",
    numLabel: "請輸入提現數量",
    placeholder: "提現數量",
    addressLabel: "請輸入提現地址",
    placeholder2: "提現地址",
    yue: "錢包餘額",
    sxf: "手續費",
    queding: "確定",
    message1: "請選擇貨幣",
    message2: "請輸入提現數量",
    message3: "你確定要提現嗎？",
    loading: "提現中..."
  },
  RobotUserRecord: {
    title: "委託記錄",
    date: "時間",
    robot: "機器人",
    jine: "金額",
    state: "狀態",
    finished: "没有更多了",
    shouyi: "收益中",
    shuhui: "贖回",
    yijieshu: "已結束",
    yishuhui: "已贖回",
    empty: "暫無記錄",
    message: "你確定要贖回嗎？",
    loading: "贖回中...",
    ranshao: "燃料增值",
    suanli: "算力"
  },
  NodePower: {
    title: "節點算力",
    date: "獎勵時間",
    jine: "獎勵金額",
    finished: "没有更多了",
  },
  Record: {
    cz: '充值记录',
    tx: "提现记录",
    shijian: "時間",
    jine: "金額",
    hash: "交易哈希",
    zz: "转账记录",
    suserid: "收款人"
  },
  Zhuanzhang: {
    title: "轉帳",
    bill: "轉帳記錄",
    chakan: "查看",
    select: "請選擇貨幣",
    numLabel: "請輸入轉帳數量",
    placeholder: "轉帳數量",
    addressLabel: "請輸入收款人地址",
    placeholder2: "收款人地址",
    yue: "錢包餘額",
    sxf: "手續費",
    queding: "確定",
    message1: "請選擇貨幣",
    message2: "請輸入轉帳數量",
    message3: "你確定要轉帳嗎？",
    loading: "轉帳中..."
  },
  AiTradeDetail: {
    title: "交易明細",
    pingcang: "平倉",
    jiage: "價格（USDT）",
    num: "數量（USDT）",
    shouyi: "收益",
    yongxu: "永續"
  },
  Service: {
    shuruwenti: "请输入您的问题",
    bunengweikong: "消息内容不能为空",
    shangchuanshibai: "上传图片失败",
    message: "请上传不5M内的图片",
    message2: "请上传 jpg 格式图片"
  },
  Subscribe: {
    title: "訂閱",
    zhineng: "人工智能交易",
    zongdingyue: "總訂閱",
    zonghuoyue: "總活躍",
    zhungtai: "狀態",
    shuchu: "最大輸出",
    zhanghu: "帳戶",
    beidongshouru: "被動收入",
    zhudongshouru: "主動收入",
    zongshouru: "總收入",
    mima: "密碼"
  },
  aiLogin: {
    title: "歡迎回來!",
    label: "請輸入您的帳戶進行登錄",
    account: "帳戶",
    password: "密碼",
    check: "記住密碼",
    submit: "登錄",
    placeholder1: "請輸入帳戶",
    placeholder2: "請輸入密碼"
  },
  Trading: {
    tubiao: "圖表",
    huobi: "貨幣",
    jiage: "價格",
    buy: "買"
  },
  NavBar: {
    logout: "退出登錄",
    shouye: "首頁",
    positions: "持有倉位",
    closedPositions: "已平倉倉位",
    aiConfig: "AI配置"
  },
  AiConfig: {
    tab1: "概述",
    tab2: "数据",
    tab3: "投资组合",
    tab4: "交易历史",
    tab5: "入金记录",
    biaoxian: "表现",
    guanyu: "关于",
    huichou: "总回酬",
    dingyuejine: "最小订阅金额",
    shuju: "数据",
    lirun: "最佳利润",
    lirunbaifenbi: "盈利交易百分比",
    jiaoyishu: "总交易数",
    dingdan: "订单",
    riqi: "日期",
    leixing: "交易类型",
    jine: "金额",
    huobi: "货币",
    maimai: "买/卖",
    kaipiaojia: "开票价",
    kaidanriqi: "开单日期",
    dangqianjiage: "当前价格",
    chengjiaoliang: "成交量",
    yingkui: "盈亏",
    shoupanjia: "收盘价",
    guandanriqi: "关单日期"
  },
  Pledge: {
    zhiya: "質押",
    zhiyajilu: "質押記錄",
    xuanzezhouqi: "選擇周期",
    qingxuanzezhouqi: "請選擇周期",
    zhiyashuliang: "質押數量",
    qingshurubeishu: "請輸入100或100的倍數",
    zhifufangshi: "支付方式",
    querenzhiya: "確認質押",
    qingshurushuliang: "請輸入數量",
    zhiyazhong: "質押中...",
    shijian: "時間",
    zhouqi: "周期",
    jine: "金額",
    zhuangtai: "狀態",
    chezi: "撤資",
    shouyizhong: "收益中",
    yichezi: "已撤資",
    querenchezi: "確認要撤資嗎",
    chezizhong: "撤資中..."
  }
}