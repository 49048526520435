<template>
  <div class="aidata">
    <header-bar :transparent="true" @setLanguage="setLanguageAfter"></header-bar>

    <div class="tab">
      <q-tabs
        v-model="tab"
        inline-label
        dense
      >
        <q-tab name="0" :label="$t('AiData.zjc')" />
        <q-tab name="1" :label="$t('AiData.ailh')" />
        <q-tab name="2" :label="$t('AiData.aijy')" />
      </q-tabs>
    </div>

    <pool v-if="tab == 0" :pageData="pageData"></pool>
    <market v-if="tab == 1" :pageData="pageData"></market>
    <trade-list v-if="tab == 2" :pageData="pageData"></trade-list>

    <!-- 开始动画，遮罩 -->
    <transition name="fade">
      <div class="mark" v-if="showMark">
        <div class="mark-content">
          <q-icon name="close" style="color: #fff;" size="30px" class="close-icon" @click="closeMark" />
          <img src="@/assets/img/base/robot.gif" class="mark-robot-icon" />
        </div>
      </div>
    </transition>

    <tab-bar active="1" ref="tabbarRef"></tab-bar>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TabBar from '@/components/TabBar'
import Pool from './components/Pool.vue'
import Market from './components/Market.vue'
import TradeList from './components/TradeList.vue'
import { ref } from 'vue'
export default {
  name: '',
  components: {
    HeaderBar,
    TabBar,
    Pool,
    Market,
    TradeList
  },
  setup() {
    return {
      us: ref(null),
      tab: ref("0"),
      showMark: ref(false),
      pageData: ref({})
    }
  },
  created() {
    if(this.$route.query.active) {
      this.tab = this.$route.query.active
    }
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getPageData()
  },
  mounted() {
    let markState = localStorage.getItem('markState')
    if(markState != 1) {
      this.showMark = true
    }
  },
  methods: {
    setLanguageAfter() {
      this.$refs.tabbarRef.reSet()
    },
    closeMark() {
      this.showMark = false
      localStorage.setItem('markState', 1)
    },
    getPageData() {
      let _this = this
      _this.$request.post(
        "api/FakeData/GetData",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
					}
          let data = res.data.data
          _this.pageData = data
          console.log(data)
        }
      )
    }
  }
}
</script>

<style scoped>
.aidata {
  min-height: 100vh;
  padding: 0 15px 15px;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}
.tab {
  margin-top: -65px;
}
.mark {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.mark-content {
  position: relative;
}
.close-icon {
  position: absolute;
  top: -80px;
  right: -50px;
}
.mark-robot-icon {
  width: 190;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(1.5);
}
</style>./components/Market.vue./components/DataTradeList.vue./components/DataPool.vue