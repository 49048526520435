<template>
  <div class="trade-echart" :style="{ backgroundColor: data.bgColor }">
    <div :id="data.symbolName" class="chart"></div>
    <div class="trade-content">
      <div>{{ data.symbolName }}</div>
      <!-- <div class="q-mt-md">{{ data.upDown }}%</div> -->
      <div class="q-mt-sm" style="font-size: 16px">price</div>
      <div class="q-mt-sm">${{ data.price }}</div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from 'echarts';
import { onMounted, onUnmounted, ref, markRaw, defineProps } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    }
  }
})

let myChart = ref(null)
let list = ref([])
let time = ref(null)

onMounted(() => {
  list.value = generateRandomNumbersInRange(1000, 5000, 7);
  initChart()
})

onUnmounted(() => {
  if(myChart.value) {
    myChart.value.dispose()
  }
  clearInterval(time.value)
})

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomNumbersInRange(min, max, count) {
  let randomNumbers = [];
  for (let i = 0; i < count; i++) {
    // 生成1000到5000之间的随机数并加入数组
    let randomNumber = generateRandomNumber(min, max);
    randomNumbers.push(randomNumber);
  }
  return randomNumbers;
}

function initChart() {
  myChart.value = markRaw(echarts.init(document.getElementById(props.data.symbolName)));
  let options = {
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: {
          show: false
        },
        axisTick: { // 隐藏刻度线
          show: false
        },
        axisLabel: { // 隐藏标签
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: { // 隐藏刻度线
          show: false
        },
        axisLabel: { // 隐藏标签
          show: false
        },
        splitLine: {
          show: false // 不显示网格线
        }
      }
    ],
    series: [
      {
        name: '平台日活',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 1.2,
          color: '#fff'
        },
        showSymbol: false,
        areaStyle: {
          color: props.data.areaColor
        },
        emphasis: {
          disabled: true
        },
        data: list.value
      }
    ]
  }
  myChart.value.setOption(options);
  getRandomData()
}

function getRandomData() {
  time.value = setInterval(() => {
    let number = Math.floor(Math.random() * 4000) + 1000
    list.value.push(number)
    list.value.shift()
    let options = {
      series: [
        {
          data: list.value
        }
      ]
    }
    myChart.value.setOption(options)
  }, 1000)
}
</script>

<style scoped>
.trade-echart {
  position: relative;
  width: 250px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  /* background-image: linear-gradient(to right bottom, #9979dd, #ae80d6, #be88d1, #c991cc, #d29cc9); */
}

.chart {
  width: 100%;
  height: 150px;
}

.trade-content {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #fff;
  font-size: 1rem;
}
</style>