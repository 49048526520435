import { createStore } from 'vuex'

export default createStore({
  state: {
    LoginState: false, //登录状态
    mode: "md"
  },
  mutations: {
    setLoginState: function(state, val) {
      state.LoginState = val;
    },
    setMode: function(state, val) {
      state.mode = val;
    }
  },
  actions: {},
  modules: {}
});
