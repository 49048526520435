<template>
    <div class="record">
        <header-bar :showNotice="false" :showBack="true" :transparent="true"
            @setLanguage="setLanguageAfter"></header-bar>

        <div style="margin-top: -90px;">
            <title-name title="智能理财" label=""></title-name>
        </div>

        <div class="list" style="text-align: center;">
            <div class="list-name">
                <div>时间</div>
                <div>名称</div>
                <div>金额</div>
                <div>出局倍数</div>
                <div>出局金额</div>
            </div>
            <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" finished-text="没有更多了"
                :immediate-check="false" offset="10" @load="onLoad">
                <div v-for="item in list" :key="item.id">
                    <div class="list-val">
                        <div>{{ item.createTime }}</div>
                        <div>{{ item.name }}</div>
                        <div>{{ item.amount }}</div>
                        <div>{{ item.bei }}</div>
                        <div>{{ item.amountOut }}</div>

                    </div>
                    <div class="total-row">
                        <div class="total-item item-border" style="display: flex;">
                            <div style="flex: 1;">
                                <div style="color: gray;">日收益</div>
                                <div>{{ item.shouyiDayBili }}%</div>
                            </div>
                            <div style="flex: 1;">
                                <div style="color: gray;">累计收益</div>
                                <div>{{ item.shouyiTotal }}</div>
                            </div>
                            <div style="flex: 1;">
                                <div style="color: gray;">状态</div>
                                <div>{{ item.stateName }}</div>
                            </div>
                            <!-- <div style="flex: 1;" v-if="(item.state == 0 || item.state == 1) && item.isSelfEnd == 1">
                                <div style="color: gray;">操作</div>
                                <div class="operation-btn" @click="submit(item.id)">
                                    赎回
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="total-row" v-if="item.state == 2 && item.stateShouyiBeyond <= 1">
                        <div class="total-item item-border" style="display: flex;">
                            <div style="flex: 1;">
                                <div style="color: gray;">超额收益</div>
                                <div>{{ item.shouyiBeyond }}({{ item.shouyiDayBili }}%)</div>
                            </div>
                            <div style="flex: 1;">
                                <div style="color: gray;">状态</div>
                                <div>{{ item.stateShouyiBeyondName }}</div>
                            </div>
                            <div style="flex: 1;">
                                <div style="color: gray;">操作</div>
                                <div class="operation-btn" @click="futou(item.id, item.amount)">
                                    复投
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </van-list>
            <empty :text="$t('RobotUserRecord.empty')" v-if="list.length == 0" />
        </div>
    </div>
</template>

<script>
import Web3Helper from "@/assets/js/web3helper.js";
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List } from "vant";

export default {
    name: '',
    components: {
        HeaderBar,
        TitleName,
        Empty,
        [List.name]: List,
    },
    setup() {
        return {
            us: ref(null),
            pageIndex: ref(1),
            list: ref([]),
            loading: ref(false),
            finished: ref(false),

            newRobotGoodsList: ref([])
        }
    },
    created() {
        this.us = JSON.parse(this.$utils.getloc("us"))
        this.getdata()
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageIndex++
            this.getdata()
        },
        setLanguageAfter() {
            this.pageIndex = 1
            this.loading = false
            this.finished = false
            this.list = []
            this.getdata()
        },
        getdata() {
            let _this = this
            _this.$request.post(
                "api/NewRobotOrder/List",
                {
                    token: _this.$utils.getloc("token"),
                    userid: _this.us.userid,
                    uid: _this.us.id,
                    pageIndex: _this.pageIndex,
                    pageSize: 10
                },
                (res) => {
                    _this.loading = false
                    if (res.data.code == 0) {
                        _this.$q.dialog({
                            message: res.data.msg,
                        });
                    }
                    let data = res.data.data
                    console.log(data)
                    if (data.length == 0) {
                        _this.finished = true
                    } else {
                        _this.finished = false
                    }
                    _this.list.push(...data)
                }
            )
        },
        submit(newRobotOrderId) {
            let _this = this
            _this.$q.dialog({
                title: `提示`,
                message: "确定要取回吗？",
                ok: {
                    label: '确定'
                },
                cancel: {
                    label: '取消',
                    color: 'white',
                    textColor: "black"
                },
            }).onOk(() => {
                _this.$q.loading.show({
                    message: "取回中..."
                })
                let web3Helper = new Web3Helper();
                web3Helper.getSign().then((signStr) => {
                    _this.$request.post(
                        "api/NewRobotOrder/EndOrder",
                        {
                            token: _this.$utils.getloc("token"),
                            userid: _this.us.userid,
                            address: _this.us.address,
                            signStr,
                            newRobotOrderId
                        },
                        (res) => {
                            _this.$q.loading.hide();
                            _this.list = []
                            _this.getdata();
                            _this.$q.dialog({
                                message: res.data.msg,
                            });
                        }
                    )
                })
            })
        },
        getNewRobotGoods() {
            return new Promise((resolve) => {
                let _this = this
                if (_this.newRobotGoodsList.length > 0) {
                    resolve(true)
                    return
                }
                _this.$request.post(
                    "api/NewRobotGoods/List",
                    {
                        token: _this.$utils.getloc("token"),
                        userid: _this.us.userid,
                        pageIndex: 1,
                        pageSize: 10
                    },
                    (res) => {
                        if (res.data.code == 100) {
                            let data = res.data.data
                            _this.newRobotGoodsList = data
                            resolve(true)
                        }
                    }
                )
            })

        },
        futou(newRobotOrderId) {
            let _this = this

            Promise.all([_this.getNewRobotGoods()]).then(() => {
                let arr = []
                _this.newRobotGoodsList.forEach(item => {
                    arr.push({
                        label: `${item.name} ${item.priceMin}~${item.priceMax}`,
                        value: item.id
                    })
                });

                _this.$q.dialog({
                    title: `确定要复投吗？`,
                    message: "请选择智能",
                    options: {
                        type: 'radio',
                        model: '',
                        isValid: val => val != '',
                        items: arr
                    },
                    ok: {
                        label: '确定'
                    },
                    cancel: {
                        label: '取消',
                        color: 'white',
                        textColor: "black"
                    },
                }).onOk((newRobotGoodsId) => {
                    let nrg = _this.newRobotGoodsList.filter(f => f.id == newRobotGoodsId)[0]
                    _this.$q.dialog({
                        title: `确定要复投吗？`,
                        message: `请输入金额(${nrg.priceMin}~${nrg.priceMax})`,
                        prompt: {
                            model: nrg.priceMin,
                            isValid: val => (val >= nrg.priceMin && val <= nrg.priceMax),
                            type: 'text',
                        },
                        ok: {
                            label: '确定'
                        },
                        cancel: {
                            label: '取消',
                            color: 'white',
                            textColor: "black"
                        },
                    }).onOk((price) => {
                        _this.$q.dialog({
                            title: `确定要复投吗？`,
                            message: "请选择支付方式",
                            options: {
                                type: 'radio',
                                model: '2',
                                items: [
                                    { label: `USDT`, value: '2', },
                                    { label: `USDA`, value: '3' },
                                ]
                            },
                            ok: {
                                label: '确定'
                            },
                            cancel: {
                                label: '取消',
                                color: 'white',
                                textColor: "black"
                            },
                        }).onOk((cid) => {
                            _this.$q.loading.show({
                                message: "复投中..."
                            })
                            let web3Helper = new Web3Helper();
                            web3Helper.getSign().then((signStr) => {
                                _this.$request.post(
                                    "api/NewRobotOrder/BuyAgain",
                                    {
                                        token: _this.$utils.getloc("token"),
                                        userid: _this.us.userid,
                                        address: _this.us.address,
                                        signStr,
                                        newRobotOrderId,
                                        cid,
                                        newRobotGoodsId,
                                        price
                                    },
                                    (res) => {
                                        _this.$q.loading.hide();
                                        _this.pageIndex = 1
                                        _this.list = []
                                        _this.getdata();
                                        _this.$q.dialog({
                                            message: res.data.msg,
                                        });
                                    }
                                )
                            })
                        })
                    })
                })
            })
        }
    }
}
</script>

<style scoped>
.record {
    min-height: 100vh;
    background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.list {
    padding: 15px 15px 10px;
}

.list-name {
    display: flex;
    color: gray;
}

.list-name>div {
    flex: 1;
}

.list-val {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.list-val>div {
    flex: 1;
    font-size: 13px;
}

.divestment-btn {
    padding: 3px 12px;
    color: #fff;
    border-radius: 4px;
    background-color: #e02020;
}

.total-row {
    margin-top: 6px;
    display: flex;
}

.total-item {
    padding-bottom: 10px;
    flex: 1;
    text-align: center;
    border-right: 1px solid rgba(231, 228, 228, 0.7);
    border-bottom: 1px solid rgba(231, 228, 228, 0.7);
}

.total-item:last-child {
    border-right: 0;
}

.item-border {
    border-right: 1px solid rgba(255, 255, 255, .5);
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.total-title {
    font-weight: bold;
    font-size: 15px;
}

.operation-btn {
    color: #1287F4;
}
</style>