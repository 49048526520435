<template>
  <div class="empty">
    <!-- <img src="@/assets/img/base/empty.svg" /> -->
    <div class="empty-text" v-if="text">{{ text }}</div>
    <div class="empty-text" v-else>No data yet</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    text: {
      type: String,
      default: null
    }
  },
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
.empty {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty img {
  width: 200px;
}

.empty-text {
  color: gray;
}
</style>