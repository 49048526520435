<template>
  <div>
    <Pledge />
  </div>
</template>

<script>
import Pledge from '../market/components/Pledge'

export default {
  components: {
    Pledge
  },
  setup() {

  }
}
</script>

<style></style>