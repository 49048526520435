module.exports = {
  Base: {
    copy: "コピー成功",
    back: "戻る",
    empty: "データはまだありません",
    empty2: "記録はありません",
    tishi: "注意",
    queding: "確定",
    quxiao: "キャンセル",
    tijiao: "提出中..."
  },
  CheckRegister: {
    推薦人: "紹介者",
    placeholder: "紹介者のアドレスを入力してください",
    register: "登録",
    addressErr: "登録時にアドレスが取得できませんでした：",
    qingshuruyonghuming: "ユーザー名を入力してください",
    qingshuruyongmima: "パスワードを入力してください",
    dengluzhong: "ログイン中"
  },
  Home: {
    公告: "お知らせ",
    rules: "ルール説明",
    purchaseRecord: "購入履歴",
    select: "レベルを選択",
    amount: "購入金額",
    submit: "ノードを購入する",
    chongzhi: "今すぐチャージ",
    referrers: "私の紹介者",
    link: "招待リンク",
    list: "購読リスト",
    address: "アドレス",
    listAmount: "購入金額",
    state: "ステータス",
    completed: "完了",
    message1: "コピーが成功しました",
    message2: "購入しますか？",
    message3: "購入中...",
    down: "ノード購入まで残り",
    day: "日",
    hours: "時間",
    seconds: "秒",
    jieshu: "ノードの購入期間が終了しました",
    downTitle1: "Edison AI ノードパートナー",
    downTitle2: "グローバルリクルートメント中",
    zhitui: "直接紹介リスト",
    qianggouzhong: "二期ノード買占め中",
    minutes: "分",
    jiedian: "ノードの計算力",
    jifaqianneng: "無限の可能性を引き出し、無限の可能性を創造する",
    jinridapp: "DAPPに入る",
    guanyu: "私たちについて",
    lianjiequkuai: "メモリブロックチェーン技術とビジネス世界の実際の需要との間のギャップを接続する",
    zhanxianshequ: "コミュニティ建設と共通認識の実践における力を示す",
    sectionmsg1: "CITADEL AIは、メモリブロックチェーンリーダー、イノベーター、開発者、国際企業からなるグローバルコミュニティアライアンスです。アライアンスはCITADEL AIメモリブロックチェーンの成長と繁栄の触媒として、新たなビジネス機会の開発、業界応用の推進、学習と協力を行う。",
    sectionmsg2: "私たちはCITADEL AIに深い信頼を寄せるグローバルコミュニティを集め、企業にブロックチェーン技術や新興技術に関する指導、サポート、および管理を提供することを期待しています。",
    zhize: "連盟の責務",
    zhizemsg: "CITADEL AIはCITADEL AIエコシステムの中核力として、分散化精神を掲げ、さまざまな手段を通じてCITADEL AI公鎖技術とエコシステムの活性化を推進します。",
    shequ: "コミュニティ計画",
    shequmsg1: "一緒にCITADEL AIの未来を築く",
    shequmsg2: "CITADEL AIはCITADEL AIコミュニティの構築と運営において重要な役割を果たし続けています。CITADEL AIのメンバーとして、ユーザーは活気あるコミュニティに溶け込み、他のメンバーと協力して意思決定やイベントの組織に参加し、CITADEL AIの将来を共に形作ります。",
    shequmsg3: "分散化Web3時代への一歩",
    shequmsg4: "私たちが採用している分散型コミュニティ参加モデルは、Web3のビジョンと完全に一致しており、これによりCITADEL AIエコシステムの透明性が向上し、各決定の公正性と普遍性が確保されています。",
    qiye: "企業計画",
    qiyemsg1: "革新的なブロックチェーンソリューションを企業に提供する",
    qiyemsg2: "CITADEL AIは世界の企業と深い協力関係を築き、CITADEL AIブロックチェーン技術の可能性を探求し続けています。私たちは企業に対してターゲット指向のブロックチェーンソリューションを提供し、デジタル経済時代に競争力を維持するお手伝いをしています。",
    qiyemsg3: "CITADEL AIメインネットのポテンシャルを最大限に活用する",
    qiyemsg4: "また、私たちは企業に対して包括的なサポートを提供し、CITADEL AIメインネットの技術的利点やデータのセキュリティと信頼性の独自の利点を理解し活用するお手伝いをしています。これにより、企業がCITADEL AIエコシステムで最高の体験を得られるようにしています。",
    qukuaigonglian: "ブロックチェーン公鎖",
    xiayidai: "次世代のWeb3分散化基盤公鎖",
    xinjingji: "新経済時代のブロックチェーンソリューション",
    xinjingjimsg: "CITADEL AIは、新経済に特化したブロックチェーンネットワークであり、独自のメインチェーン+マルチサイドチェーン構造が開発者に分散型アプリケーションを独立して展開・運用する空間を提供し、リソースの最適化と効果的な利用を実現しています。効率的な並列処理とコンセンサスメカニズムにより、CITADEL AIは高頻度取引を強力にサポートしています。また、クロスチェーン技術により、チェーン間のスムーズな通信と価値転送が保証されています。",
    kaifgongju: "強力なガバナンスと開発ツール",
    kaifgongjumsg: "異なるビジネスシナリオには異なるガバナンスモデルが必要ですが、CITADEL AIはこの点を深く理解し、議会ガバナンス、民主的ガバナンス、協会ガバナンスなど、さまざまなガバナンスモデルを提供しています。また、システムの持続的かつ健全な発展を確保するために、CITADEL AIは精巧なインセンティブモデルを設計しています。さらに、CITADEL AIは先進的なIDEツールも提供し、開発者がアプリケーションをより簡単に開発、デバッグ、展開できるようサポートしています。",
    zhongxinhua: "分散型ガバナンスに貢献する",
    zhongxinhuazeren: 'CITADEL AIのメインネットのブロック生産者として、CITADEL AIは重要な責任を担っています。',
    zhongxinhuali1: 'ネットワークのセキュリティを確保する',
    zhongxinhuali2: 'コンセンサス決定を最適化する',
    zhongxinhuali3: 'サイドチェーンノードの最適な運用を維持する',
    zhongxinhuali4: 'ネットワークの安定性を維持する',
    pianzhng: '新しい章へ',
    pianzhnglabel1: 'ただのブロックチェーンネットワークではありません',
    pianzhngmsg1: 'それは分散自治組織（DAO）の理念も積極的に取り入れています。このため、CITADEL AIは独自のDAOを設立し、これを基盤に世界中のノード募集プログラムを開始しました。',
    pianzhnglabel2: 'CITADEL AIは世界中から20人の上級ノードを募集しています',
    pianzhngmsg2: '各代表は異なる領域のエリートであり、国際機関または特定の分野で名声を博した人物です。これらの20人の代表は、CITADEL AIエコシステムに関する重要な問題に共同で取り組み、CITADEL AIをさらなる発展に導いています。',
    pianzhnglabel3: '分散自治組織はブロックチェーン技術の重要な応用です',
    pianzhngmsg3: 'これは従来の中央集権的な管理方法を排除し、意思決定プロセスをより民主的で透明にします。DAOの枠組みでは、各メンバーがシステム内での貢献や権益に基づいて投票を行い、組織の将来を共同で決定します。',
    pianzhnglabel4: 'CITADEL AI財団の設立は、より多くの人々が参加できるようにするためです',
    pianzhngmsg4: 'CITADEL AIの発展に共に貢献し、CITADEL AIの栄光ある歴史を共に築いていくためです',
    mubiao: '主な目標',
    mubiaolabel1: 'エコシステムのキュレーション',
    mubiaomsg1: 'CITADEL AIエコシステム内での高潜在性プロジェクトの選定、支援、普及を担当します。',
    mubiaolabel2: '技術検証',
    mubiaomsg2: 'ブロック生産および取引検証を通じてネットワークの安全性を確保します',
    mubiaolabel3: 'クロスチェーン調整',
    mubiaomsg3: '他のブロックチェーンエンティティと提携し、CITADEL AIの相互運用性を確保し、最新の業界基準に基づいて更新します。',
    mubiaolabel4: 'コミュニティ構築',
    mubiaomsg4: 'コミュニティの発展をリードし、コンセンサスを築き、積極的な対話の空間を構築し、CITADEL AIエコシステムの繁栄を共に推進します。',
    mubiaolabel5: 'DAOの運営および管理',
    mubiaomsg5: 'コミュニティ提案の提出、議論、投票を監督し、これらの提案がCITADEL AIの使命や価値観に合致していることを確認します。',
    mubiaolabel6: '資金調達',
    mubiaomsg6: '資金調達活動を監督し、CITADEL AIエコシステムの成長と財務安定性を確保します。',
    time1: '2023年第4四半期',
    time2: '2024年第1四半期',
    time3: '2024年第2四半期',
    time4: '2024年第3四半期',
    time5: '2024年第4四半期',
    time6: '2025年',
    time7: '2026年',
    timemsg1: '積極的な募集を行い、世界中から10万人のユーザーを惹きつけ、20か国をカバーする目標を設定します。',
    timemsg2: 'Web3の研究と実装を推進し、CITADEL AIエコシステムをWeb3の最新の進展と連携させます。',
    timemsg3: 'Aclevate支援プログラムを支援し、CITADEL AIに採用されたゲーム開発チームの宣伝を行い、ファン数と市場の熱を高め、CITADEL AIエコシステムがゲーム金融分野でより大きな影響力を持つようにします。',
    timemsg4: 'オンラインおよびオフラインの会議、取引会、展示会、およびその他のコミュニティイベントを開催して、より多くの企業とユーザーをCITADEL AIに参加させます。',
    timemsg5: 'グローバルパートナーとの関係を強化し、CITADEL AIエコシステムの発展を共同で推進します。',
    timemsg6: 'GameFi同盟を構築し、CITADEL AIをPlay-to-Earnゲームやその他のGameFiのイノベーションのリーダーにします。',
    timemsg7: '世界中から50万人のユーザーを成功裏に惹きつけ、少なくとも50か国をカバーし、CITADEL AIエコシステムに少なくとも30の異なる領域の新プロジェクトを追加し、CITADEL AIエコシステムの機能と多様性を継続的に拡張し強化します。',
    timemsg8: 'CITADEL AIインキュベーターの設立を支援し、革新的なプロジェクトがCITADEL AIパブリックチェーンでの発展を促進します。',
    timemsg9: 'SocialFiエコシステムを推進し、CITADEL AIをソーシャルファイナンスおよびそれに関連するイノベーションの先駆者にします。',
    timemsg10: 'CITADEL AIアカデミーを設立し、教育リソースとトレーニングを提供し、コミュニティメンバーのブロックチェーン知識とスキルを向上させます。',
    timemsg11: '複数のコミュニティバウンティプログラムを立ち上げ、より多くの人々がCITADEL AIエコシステムの構築に参加するよう奨励します。',
    timemsg12: '世界中から100万人のユーザーを成功裏に獲得し、100か国をカバーします。',
    timemsg13: 'CITADEL AIエコシステムを世界最大かつ最も多様なブロックチェーンエコシステムに育てます。',
    genzongtitle: 'DeFi流動性マイニングトラッキングボット',
    genzonglabel: '投資監視と収益最大化の究極のツール。',
    genzongmsg1: '当社のボットは、DeFiエコシステムで生成されるさまざまな投資ツールと戦略の収益を追跡し分析するように精心に設計されています。',
    genzongmsg2: '投資家は、当社の最先端のボットから関連する投資パフォーマンスと利益率のユニークな洞察を得ることができます。最新の収益指標を簡単に取得し、戦略の効果を評価し、賢明な決定を下し、投資収益を最適化します。',
    genzongmsg3: 'ステーキング、マイニング、または流動性プールへの参加に関係なく、当社のDeFi流動性マイニングトラッキングボットは、投資状況を注視し、リアルタイムデータと包括的な分析を提供します。',
    genzongmsg4: '利益の最大化の機会を逃さないでください',
    trade: 'DeFi取引ボット',
    tradelable: '世界最先端の自動取引ボットの紹介',
    chuangjian: '作成',
    jiaoyisuanfa: '取引アルゴリズム',
    shiyong: '使用',
    zhibiao: 'カスタマイズされた技術指標',
    jiedu: '解読',
    xinhao: 'シグナルを簡単に',
    trademsg1: '私たちは自主開発した取引ロボットを豊富な機能を持つ端末に埋め込み、いくつかの主要な取引所間で操作を行い、顧客に効率的で正確な実行を提供します',
    trademsg2: '私たちは次のアップグレードでシステムとERCチェーンを統合し、取引量を向上させるとともに、利益の向上を促進し、顧客に安定したネットワークを提供し、顧客が動的な暗号通貨市場でチャンスをつかむのを支援します。',
    kefu: "テレグラムカスタマーサービス"
  },
  HomeFramework: {
    bannerTitle: '非凡な量的世界を開拓する',
    bannerMsg1: '次世代のAI領域の知的な量的財富プラットフォームの構築',
    bannerMsg2: 'Edison AI、常人にも輝かしい光をもたらすAIの深遠なる力',
    bannerMsg3: '公正/自由/信頼/未来',
    aboutTitle1: '多層的',
    aboutTitle2: '多角度',
    aboutTitle3: '多データ',
    aboutMsg1: '大分類資産の配置、業界選択、選りすぐりの具体的な資産の3つのレベルにモデルが含まれています',
    aboutMsg2: '数量投資の核心思想は、マクロサイクル、市場構造、評価、成長、利益の品質、アナリストの利益予測、市場の感情など、複数の視点を含んでいます',
    aboutMsg3: '大量のデータ処理',
    robotTitle: 'Edison AI量的ロボット',
    robotMsg: 'これは次世代の知的金融アシスタントであり、先進のAI大規模モデルデータ分析を組み合わせ、正確な市場トレンド分析を提供し、賢明な投資決定を支援します。 あなたのニーズとリスク許容度に応じて、あなたに合わせた個別の投資戦略を提供し、より高い投資収益をもたらします。',
    platformTitle: 'プラットフォームのビジョン',
    platformMsg: '人工知能とブロックチェーン技術を活用し、包括的な金融プラットフォームを構築し、安全で透明性が高く、公正で効率的な投資環境をすべての人々に提供します。',
    footerMsg: 'Edison AI Wealth Centerは、AI人工知能、ビッグデータ、およびブロックチェーン技術に基づく財富管理プラットフォームであり、新しい、オープンソース、分散型のAIイノベーション領域に焦点を当てています。 Edison AI F.D.T LIMITED UK Foundationを基盤とし、AI大規模モデルデータベース、AIスーパーコンピュータ、および大量のデータTPSを支えとして、公正で安全、効率的で迅速なAI量的を実現するための完璧なスマートコードを形成しています。',
    menu2: '私のオーダー',
    menu3: '私の請求書',
    menu4: '私の収益',
    menu5: '私の資産',
    menu6: '私のサポート',
    menu7: '量的戦略'
  },
  HomeService: {
    title: "デルが提供するサービス",
    msg: "Edisonシステムは強力なAI大モデルデータベースを持ち、Two Sigmaは十分な技術サポートとデータサポートを与え、その背後にはマスデータと膨大な計算力のサポートがある。10000を超えるデータソース、95000個の1000 TB以上のCPU、2200万GBの即時増加データがあり、毎秒の演算速度は1 X 10 ^ 14に達することができ、データベース容量は1695 TBに達し、最も複雑な投資理念を実行する際に支障がない。",
    serviceTitle1: "定量サービス",
    serviceTitle2: "痛みを和らげる",
    serviceTitle3: "インテリジェント取引システム",
    serviceTitle4: "インテリジェントな実施形態",
    serviceTitle5: "取引上の優位性",
    serviceTitle6: "取引科学技術",
    list1Msg1: "企業レベルのAI基盤プラットフォーム、リードする量子化取引アルゴリズム、自動機械学習、超パラメータ最適化、AI量子化処理効率の急速な向上",
    list1Msg2: "PBレベル量子化固有標準金融データ",
    list1Msg3: "2000+基礎要素ライブラリ、AIはより多くの派生要素の発掘をサポートし、一歩リードし、より多くの市場機会を獲得する",
    list1Msg4: "エクスプレッションエンジンは、要素の包括的な分析と有効な要素の迅速な発見をサポートする多様な要素ライブラリを作成します",
    list1Msg5: "モジュラー化された可視化開発環境、Pythonコードバージョンとのシームレスな統合、戦略開発効率の向上",
    list1Msg6: "超パラメータ検索、コンビネーション最適化器、ローリング訓練、帰因分析など、ハイエンドプレイヤーのために装備をカスタマイズする",
    list2Msg1: "2021年以来、EDISON AIは主流取引所の貨安、欧易、火貨と深い協力を達成しただけで、取引所の走りの局面を最大限に回避した",
    list2Msg2: "デフォルトでは、お客様は主要な旧通貨のみを取引し、altcoinsや流行通貨の使用を回避し、類似のLUNA雷雨事件を最大限に回避することをお勧めします默認情况下，建議客戶只交易主流舊幣，避免使用altcoins和流行幣，並最大限度地避免類似的LUNA雷暴事件",
    list2Msg3: "メディアコミュニケーション連合によるニラ刈りメッセージの影響を受けず、ユーザーのネガティブな感情の影響を回避するために、厳格かつ包括的に戦略を実行します。",
    list2Msg4: "このロボットはクラウドサービスに配備されており、ローカルコンピュータやネットワークを占有せず、24時間自動でこのポリシーを実行して、お客様のエネルギー問題を解決します。",
    list2Msg5: "24時間自動知能ミリ秒計算と取引速度、突発相場を把握し、大市場の人工速度が追いつかない問題を解決する。",
    list2Msg6: "EDISONを3年間最適化し続ける取引数学モデルを内蔵し、統計履歴ビッグデータと計算式を結合して知恵モデルを計算して保証金を追加する",
    list3Msg1: "分Kラインデータを追跡することで、利益点と利益点がスマートに修正され、利益を最大限に高め、購入コストを削減",
    list3Msg2: "分線を上昇と下降が一定値を超えるように設定することで、間隔を調整したり停止したりすることで、手動注文の滝やピンの問題を解決します",
    list3Msg3: "タタール、平推シリーズ、フィボナッチなどのカスタマイズ可能な戦略により、顧客のために種馬賭博のリスクを最大限に回避",
    list3Msg4: "シロのワンクリック起動に適したスマートモード、マスター補助の専門モード+定員モードを定量化し、すべての人に適している",
    list3Msg5: "熊市は空にすることができて、牛市は多く開くことができて、震動市場も多くの空を開くことができて、片側市場は多くの空を連動することができて、それぞれの異なる状況に対して",
    list3Msg6: "異なるシリーズの頭金取引に対して、注文ごとの取引金額を正確に計算し、手作業で計算する煩雑な問題を最大限に回避する",
    list4Msg1: "インテリジェント数学モデル、EDISON内蔵計算モデルを内蔵し、現在の価格と歴史データに基づいて、変動期があり、インテリジェント計算、リアルタイム調整次回追加保証金",
    list4Msg2: "知恵リアルタイムでKラインを追跡し、利益巻き戻し追跡開倉機能を追跡し、利益巻き戻しと保証金巻き戻しポイントをリアルタイムで調整し、利益を拡大し、コストを削減する",
    list4Msg3: "スマートに取引の深さを監視し、取引所の最大制限価格で取引所の深さを監視し、最適な売買価格を追求する",
    list4Msg4: "スマート統計履歴データ、スマート統計取引通貨の履歴データ及び変動幅、スマートモードの安定運行をサポートする",
    list4Msg5: "スマートに取引速度を調整し、市場変動に基づいてスマートに取引速度を調整し、サーバの安定した運用を最適化しながら最適な価格を迅速に把握する",
    list5Msg1: "取引速度",
    list5Msg2: "ミリ秒、高速、安定",
    list5Msg3: "エネルギーじかん",
    list5Msg4: "毎日24時間寝ずに取引をする",
    list5Msg5: "インテリジェントデータ",
    list5Msg6: "倉庫計算と取引データ計算が迅速かつ正確である",
    list5Msg7: "取引モード",
    list5Msg8: "完全で安定した取引モデル",
    list5Msg9: "ポリシーの実行",
    list5Msg10: "全体戦略を厳格に実行する",
    list5Msg11: "リアルタイム追跡",
    list5Msg12: "リアルタイムタグ、スマートモーション追跡Kライン",
    list5Msg13: "メディアの影響力",
    list5Msg14: "主人の気持ちに左右されない",
    list5Msg15: "メディアの影響力",
    list5Msg16: "偽ニュースに惑わされてはならない",
    list5Msg17: "収益性",
    list5Msg18: "短期暴利は長期的に安定することができる",
    list6Msg1: "取引科学技術",
    list6Msg2: "自主研究開発、強大で安定、業界の認可度が高い",
    list6Msg3: "実行履歴",
    list6Msg4: "EDISONが定量研究に専念して8年、出版から3年",
    list6Msg5: "コスト",
    list6Msg6: "グローバル市場は統一的で透明で非表示ではない",
    list6Msg7: "取引モード",
    list6Msg8: "安定取引モデルを独自に開発、2つのモデル",
    list6Msg9: "委員会の状況",
    list6Msg10: "いいえ",
    list6Msg11: "プラットフォームの選択",
    list6Msg12: "貨幣安Oyi Huobiのみをサポートし、お客様は自分で選択することができます",
    list6Msg13: "安全保障情勢",
    list6Msg14: "EDISONの8年間の安定した定量化保証",
    list6Msg15: "最適化の更新",
    list6Msg16: "4年間で市場最適化と更新の繰り返し",
    list6Msg17: "ユーザーの合計数",
    list6Msg18: "安定した顧客層、70%の市場シェア+",
  },
  HomeStepper: {
    title: '愛迪生',
    title2: '開発プロジェクト',
    step1Msg1: '大規模なモデルデータベースを構築し、トレーニングを行いました。',
    step1Msg2: '暗号化オペレーションチームを設立し、暗号化トラックを整備しました。',
    step2Msg1: 'AI量子化投資を開始しました。',
    step2Msg2: 'AIによる金融資産の管理が急速に成長しています。',
    step2Msg3: '高品質な暗号通貨取引プラットフォームやDeFiプロジェクトに投資を始めました。',
    step3Msg1: 'Two-SigmaがEdison Al Foundationに投資しました。',
    step3Msg2: 'Foundationの愛迪生AIウェルスセンタープラットフォームがローンチされました。',
    step3Msg3: '愛迪生AIアグリゲーションロボットが登場しました。',
    step3Msg4: 'Edison AI DeFiセクションが開始されました。',
    step4Msg1: 'EdisonAIパブリックチェーンウォレットがローンチされました。',
    step4Msg2: '愛迪生AI暗号化ソーシャルがローンチされました。',
    step5Msg1: 'EdisonAlパブリックチェーンエコアプリケーションがローンチされました。'
  },
  Chongzhi: {
    title: 'チャージ',
    bill: 'マイビル',
    chakan: '表示',
    placeholder: 'チャージ量を入力してください',
    balance: 'ウォレット残高',
    queding: '確定',
    guangbo: 'トランザクションのブロードキャストに成功しました',
    message1: 'チャージ量を入力してください',
    message2: 'ウォレット残高が不足しています',
    message3: 'トランザクション中...',
    message4: '正しい量を入力してください',
    xiugai: '修正',
    bangding: 'TRC20アドレスがバインドされていません、今すぐバインドしてください',
    message5: 'TRC20アドレスを入力してください',
    message6: '修正中...',
    message7: '正しい量を入力してください',
    message8: '現在使用しているTRONウォレットアドレスがバインドされているアドレスと一致しません。送金前に修正してください',
    message9: '*バインドされたウォレットアドレスを使用してチャージ量を受け取るアドレスに送金してください',
    message10: '送金が成功した後、2分間お待ちください。ブロックの確認後、自動的に入金されます！',
    shoubi: 'プラットフォーム受け取りアドレス',
    bangdingdizhi: 'バインドアドレス',
    copy: 'アドレスをコピー',
    shouquan: '許可',
    shouquanjine: '許可金額',
    shouquantip: '許可金額が不足しています。まず許可を行ってください',
    yishouquan: '許可済み',
    shouquanzhong: '許可中...',
    chongzhizhong: 'チャージ中...',
    shouquanchenggogn: '許可成功'
  },
  Bill: {
    title: '請求書',
    date: '日付',
    leixing: 'タイプ',
    coin: '通貨',
    num: '数量',
    finished: 'これ以上はありません',
    all: 'すべて',
    shouyi: '収益',
    chongzhi: 'チャージ',
    tixian: '引き出し',
    zhuanzhang: '送金',
    zhuanhuan: '変換',
    zengjian: '増減',
    xiaofei: '購入'
  },
  NodeRecord: {
    title: "購買履歴",
    date: "時間",
    node: "ノード",
    amount: "金額",
    finished: "これ以上ない"
  },
  Bonus: {
    title: "に利益を与える",
    date: "時間",
    type: "を選択してオプションを設定します。",
    jine: "金額",
    finished: "これ以上ない",
    jinri: "今日の収益",
    leiji: "累積収益"
  },
  Team: {
    title: "直進リスト",
    address: "アドレス",
    jine: "金額",
    jibie: "レベル＃レベル＃",
    wu: "なし",
    yeji: "鉱機実績",
    jiedianyeji: "ノード業績",
    aiyeji: "AI業績"
  },
  User: {
    home: "トップページ",
    chongzhi: "チャージセンタ",
    bonus: "私の収益",
    ai: "AIデータ",
    mining: "こうを掘る",
    notice: "公告"
  },
  Notice: {
    title: "公告",
    details: "公告の詳細"
  },
  TabBar: {
    home: "市場",
    ai: "AI立証",
    mining: "フォーチュンセンター",
    team: "チーム",
    user: "マイ"
  },
  Market: {
    dagnqianjiage: '現在の価格',
    chushi: '初期価格',
    mairu: '購入',
    maichu: '売却',
    liudongxing: '流動性',
    xinxi: '情報',
    kongtou: 'CCAIエアドロップ報酬',
    dangqian: '総量',
    daibixiaohui: 'CCAIトークンの焼却',
    xiaohuizongliang: 'CCAI焼却総量',
    chubirenshu: 'CCAI保有者数',
    faxingzongliang: 'CCAI発行総量',
    jiedian: 'ノード',
    jine: '金額',
    suanli: 'ハッシュレート',
    zuori: '昨日',
    jinri: '今日'
  },
  AiData: {
    zongjiazhi: '総評価額（米ドル）',
    celue: 'グリッド戦略',
    totalMsg: 'Citadelは世界で最も影響力のある量子化ヘッジファンドの1つであり、複雑な人工知能と機械学習モデルを利用して資産管理と市場取引の最適化を行っている。Citadel Securitiesの電子取引部門は、AI技術を広く使用して取引実行効率を向上させている。CITADEL AIロボットは毎日数百の取引に対するビッグデータの深い学習と監視、リアルタイムで戦略を制定し、改善し、命令を形成し、十分な技術支援とデータ支援を与え、背後にはマスデータと膨大な計算力の支えがある。10000を超えるデータソースがあり、95000個の1000 TB以上のCPUがあります。',
    botTitle: '取引注文数量',
    jiangli: '報酬',
    rilirun: '日平均利益',
    yuelirun: '月利益',
    jiage: '価格',
    zjc: '資金プール',
    ailh: 'AI量化',
    aijy: 'AI取引',
    poolStats: '資金プール統計',
    arbitrageStats: 'アービトラージ統計',
    zongyue: '総残高',
    baozhengjin: '保証金（USDT）',
    qianbaoyue: 'ウォレット残高（USDT）',
    yunxingyingkui: '運行利益（USDT）',
    zongyingkui: '総利益（USDT）',
    name: '通貨',
    price: '価格',
    aiweituo: 'AIオーダー',
    aimingxi: 'AI取引詳細',
    dingyue: '購読',
    jilu: '記録',
    ziliaoku: '情報センター',
    chengjiaoliang: '取引量',
    jiaoyidui: '取引ペア',
    chengjiaotu: '取引量ヒートマップ',
    paixu: '並べ替え',
    bizhong: '通貨',
    price24: '価格（24時間）',
    wending: '安定接続',
    zhichi: 'オンラインサポート'
  },
  Mining: {
    mingcheng: 'トークン名',
    fuhao: 'トークンのシンボル',
    gongyingliang: '総供給量',
    qishi: '初期価格',
    jilu: '委託記録',
    chakan: '閲覧',
    aibot: 'AIロボット',
    rishouyi: '日収益',
    weituojine: '委託金額',
    weituo: '委託',
    aggregation: '集約ロボット',
    placeholder1: '金額を入力してください',
    queren: '確認',
    jijiangkaifang: '近日公開予定',
    echartsName1: 'コミュニティ配分',
    echartsName2: 'ノード配分',
    echartsName3: '財団配分',
    echartsName4: 'ハッシュパワープール',
    placeholder2: '入力してください',
    loading: '委託中...',
    jiqiren1: "S 1ロボット",
    jiqiren2: "S 2ロボット",
    jiqiren3: "S 3ロボット",
  },
  TeamCount: {
    tuijian: '推薦',
    tuijianren: '私の推薦者',
    lianjie: '招待リンク',
    tuandui: 'チーム',
    teamCount: 'チーム登録数',
    liebiao: '直属リスト',
    usYejiTotal: '個人AI業績',
    teamYejiTotal: 'チームAI業績',
    rtaShouyi: 'AI収益',
    rtbShouyi: '集約収益',
    ktDay: '日々のエアドロップ量',
    ktTotal: 'エアドロップ収益',
    jiedianyeji: 'チームノード業績',
    usjiedianyeji: '個人ノード業績',
    usxh: '個人の集約消滅',
    teamxh: 'チームの集約消滅',
    jiedianshouyi: 'ノード収益',
    youxiao: 'チーム有効人数'
  },
  Wallet: {
    total: '総資産',
    bill: '私の請求書',
    bonus: '私の収益',
    maichu: '売出し',
    chongzhi: 'チャージ',
    tixian: '引き出し',
    mairu: '購入',
    shandui: 'フラッシュ取引',
    keyong: '利用可能',
    dongjie: '凍結',
    zichan: '資産',
    zhuanzhang: '振込'
  },
  Convert: {
    mairu: '購入',
    maichu: '売却',
    shandui: 'フラッシュ交換',
    yue: '残高',
    placeholder1: '数量を入力してください',
    edu: '認購額度',
    sxf: '手数料',
    ranliao: '等価の燃料CCAI',
    querenmairu: '購入を確認する',
    querenmaichu: '売却を確認する',
    message1: '購入しますか？',
    message2: '売却しますか？',
    loading1: '読み込み中...',
    message3: '変換しますか？',
    loading2: 'フラッシュ交換中...'
  },
  Tixian: {
    title: '引き出し',
    bill: '私の請求書',
    chakan: '表示する',
    select: '通貨を選択してください',
    numLabel: '引き出し数量を入力してください',
    placeholder: '引き出し数量',
    addressLabel: '引き出しアドレスを入力してください',
    placeholder2: '引き出しアドレス',
    yue: 'ウォレット残高',
    sxf: '手数料',
    queding: '確定',
    message1: '通貨を選択してください',
    message2: '引き出し数量を入力してください',
    message3: '引き出しますか？',
    loading: '引き出し中...'
  },
  RobotUserRecord: {
    title: "依頼記録",
    date: "日時",
    robot: "ロボット",
    jine: "金額",
    state: "状態",
    finished: "これ以上ありません",
    shouyi: "収益中",
    shuhui: "償還",
    yijieshu: "終了済み",
    yishuhui: "償還済み",
    empty: "記録がありません",
    message: "償還してもよろしいですか？",
    loading: "償還中...",
    ranshao: "燃料増値",
    suanli: "算力"
  },
  NodePower: {
    title: "せってんけいさんりょく",
    date: "奨励時間",
    jine: "奨励金金額",
    finished: "これ以上ない",
  },
  Record: {
    cz: 'チャージレコード',
    tx: "キャッシュレコード",
    shijian: "時間",
    jine: "金額",
    hash: "取引ハッシュ",
    zz: "振替記録",
    suserid: "受取人"
  },
  Zhuanzhang: {
    title: "送金",
    bill: "送金記録",
    chakan: "確認",
    select: "通貨を選択してください",
    numLabel: "送金数量を入力してください",
    placeholder: "送金数量",
    addressLabel: "受取人のアドレスを入力してください",
    placeholder2: "受取人のアドレス",
    yue: "ウォレット残高",
    sxf: "手数料",
    queding: "確認",
    message1: "通貨を選択してください",
    message2: "送金数量を入力してください",
    message3: "送金してもよろしいですか？",
    loading: "送金中..."
  },
  AiTradeDetail: {
    title: "取引明細",
    pingcang: "決済",
    jiage: "価格（USDT）",
    num: "数量（USDT）",
    shouyi: "収益",
    yongxu: "永続"
  },
  Service: {
    shuruwenti: "質問を入力してください",
    bunengweikong: "メッセージ内容を空にすることはできません",
    shangchuanshibai: "画像のアップロードに失敗しました",
    message: "5M以内の画像をアップロードしてください",
    message2: "jpg形式の画像をアップロードしてください"
  },
  Subscribe: {
    title: "購読",
    zhineng: "AI取引",
    zongdingyue: "総購読",
    zonghuoyue: "総活躍",
    zhungtai: "ステータス",
    shuchu: "最大出力",
    zhanghu: "アカウント",
    beidongshouru: "受動収入",
    zhudongshouru: "能動的な収入",
    zongshouru: "総収入",
    mima: "パスワード"
  },
  aiLogin: {
    title: "お帰りなさい！",
    label: "アカウントでログインしてください",
    account: "アカウント",
    password: "パスワード",
    check: "パスワードを記憶する",
    submit: "ログイン",
    placeholder1: "アカウントを入力してください",
    placeholder2: "パスワードを入力してください"
  },
  Trading: {
    tubiao: "グラフ",
    huobi: "通貨＃ツウカ＃",
    jiage: "価格",
    buy: "値段で買う"
  },
  NavBar: {
    logout: "ログオンの終了",
    shouye: "トップページ",
    positions: "ポジションを持つ",
    closedPositions: "倉庫の位置を平らにした",
    aiConfig: "AI構成"
  },
  AiConfig: {
    tab1: "概要",
    tab2: "データ",
    tab3: "投資ポートフォリオ",
    tab4: "取引履歴",
    tab5: "入金記録",
    biaoxian: "パフォーマンス",
    guanyu: "について",
    huichou: "総収益",
    dingyuejine: "最小購読額",
    shuju: "データ",
    lirun: "ベストプロフィット",
    lirunbaifenbi: "利益取引率",
    jiaoyishu: "総取引数",
    dingdan: "注文",
    riqi: "日付",
    leixing: "取引タイプ",
    jine: "金額",
    huobi: "通貨",
    maimai: "買/売",
    kaipiaojia: "オープニング価格",
    kaidanriqi: "注文日付",
    dangqianjiage: "現在の価格",
    chengjiaoliang: "出来高",
    yingkui: "利益/損失",
    shoupanjia: "終値",
    guandanriqi: "クローズ日付"
  },
  Pledge: {
    zhiya: "担保",
    zhiyajilu: "担保記録",
    xuanzezhouqi: "サイクルを選択",
    qingxuanzezhouqi: "サイクルを選択してください",
    zhiyashuliang: "担保数量",
    qingshurubeishu: "100またはその倍数を入力してください",
    zhifufangshi: "支払方法",
    querenzhiya: "担保を確認",
    qingshurushuliang: "数量を入力してください",
    zhiyazhong: "担保中...",
    shijian: "時間",
    zhouqi: "周期",
    jine: "金額",
    zhuangtai: "ステータス",
    chezi: "撤回",
    shouyizhong: "収益中",
    yichezi: "撤回済み",
    querenchezi: "撤回しますか",
    chezizhong: "撤回中..."
  }
}