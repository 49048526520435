<template>
	<div class="order">
		<div class="tabs">
			<div class="tabs-row">
				<div class="tabs-item" :class="{ 'tabs-active': active == 0 }" @click="tabsChange(0)">
					交易记录
				</div>
			</div>
		</div>

		<div class="order-list">
			<div class="order-item" v-for="(item, index) in list" :key="index">
				<div class="order-row">
					<div class="order-col ">
						<div class="order-label">{{ item.typeName }}</div>
						<div class="order-label">{{ item.createTime }}</div>
					</div>
					<div class="order-col text-right" v-if="item.type == 0">
						<div class="order-label">{{ item.num-item.feeBuyAmount }} CCAI</div>
						<div class="order-label">-{{ item.amount }} USDT</div>
						<div class="order-label">{{ item.feeBuyAmount }} CCAI手续费</div>
					</div>
					<div class="order-col text-right" v-if="item.type == 1">
						<div class="order-label">-{{ item.num+item.feeSellAmount }} CCAI</div>
						<div class="order-label">{{ item.amount }} USDT</div>
						<div class="order-label">{{ item.feeSellAmount }} CCAI手续费</div>
					</div>
				</div>
			</div>
		</div>

		<empty v-if="list.length == 0" text="暂无订单"></empty>
	</div>
</template>

<script setup>
import {
	ref,
	defineProps,
	defineExpose,
	onMounted
} from "vue";
import {
	useRouter
} from 'vue-router'
import request from '@/assets/js/request.js'
import util from '@/assets/js/utils.js'

defineExpose({
	getList
})

let props = defineProps({
	type: {
		type: [String, Number],
		default: ""
	}
})

const router = useRouter()

let us = ref(null)
let active = ref(0)
let list = ref([])

onMounted(() => {
	us.value = JSON.parse(util.getloc("us"))
	if (us.value) {
		getList()
	}
})

function getList() {
	request.post(
		"api/Trade/List", {
		token: util.getloc("token"),
		userid: us.value.userid,
	},
		(res) => {
			if (res.data.code == 100) {
				let data = res.data.data
				list.value = data
				console.log(list.value)
			}
		}
	)
}
</script>

<style scoped>
.order {
	margin-top: 30px;
}

.tabs {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tabs-row {
	display: flex;
	align-items: center;
}

.tabs-item {
	margin-right: 25px;
	color: #000;
}

.right-icon {
	width: 16px;
	height: 16px;
}

.all {
	margin-right: 10rpx;
	color: #7F8798;
	font-size: 12px;
}

.del-icon {
	width: 15px;
	height: 15px;
}

.order-list {
	margin-top: 20px;
}

.order-item {
	margin-top: 15px;
	padding: 17px 15px;
	border-radius: 6px;
	background-color: #fff;
}

.order-item:first-child {
	margin: 0;
}

.order-cell {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #000;
}

.order-date {
	font-size: 12px;
	color: #7F8798;
}

.order-row {
	display: flex;
}

.order-col {
	flex: 1;
}

.order-label {
	color: #7F8798;
	font-size: 12px;
}

.order-val {
	margin-top: 5px;
	color: #000;
	font-size: 13px;
}

.tabs-active {
	color: #5B5FF8;
}

.order-footer {
	display: flex;
	justify-content: space-between;
}

.order-btn {
	flex: 1;
	margin-top: 15px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99px;
	color: #000;
	background: linear-gradient(90deg, #4344DE 0%, #5B5FF8 100%);
}

.order-btn:first-child {
	margin-right: 6px;
}

.order-btn:last-child {
	margin-left: 6px;
}

/* #ifdef APP-PLUS */
.popup-box {
	padding: 15px;
}

/* #endif */

/* #ifdef H5 */
.popup-box {
	padding: 15px 15px 65px;
}

/* #endif */

.popup-title {
	position: relative;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
}

.popup-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 20px;
	height: 20px;
}

.popup-cell {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
}

.popup-input {
	margin-top: 15px;
	font-size: 12px;
}

.popup-input-label {
	color: #999;
}

.input {
	margin-top: 10px;
	padding: 0 10px;
	height: 40px;
	display: flex;
	align-items: center;
	border-radius: 6px;
	justify-content: space-between;
	background-color: #efefef;
}

.popup-btn {
	margin-top: 30px;
	height: 44px;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.record-date {
	font-size: 12px;
	color: #c4c6d2;
}

.record-green {
	font-weight: bold;
	color: #2CB45A;
}

.record-red {
	color: rgb(255, 67, 67);
}

.record-gray {
	color: #999;
}

.big-text {
	font-size: 18px;
	font-weight: bold;
}

.tag {
	margin-top: 5px;
	display: inline-block;
	width: 42px;
	padding: 0 4px;
	border-radius: 99rem;
	font-size: 12px;
	text-align: center;
	color: #000;
}

.tab-default {
	background-color: gray;
}

.tab-success {
	background-color: #5BC98D;
}

.tab-error {
	background-color: #f56c6c;
}
</style>