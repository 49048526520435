<template>
  <div class="trade" v-if="swapInfo">
    <div class="header"></div>

    <div class="swap-head">
      <div>总量：{{swapInfo.swapTotal}} 枚</div>
      <div>剩余：{{swapInfo.swapLast}} 枚</div>
    </div>
    <div class="content">
      <div class="form">
        <div class="input-wrap">
          <div class="input-label">币价</div>
          <div class="input">
            <q-input borderless v-model="swapInfo.price" dense readonly />
          </div>
          <div class="input-label">{{ swapInfo.lpccaiName }}</div>
        </div>
        <div class="input-wrap q-mt-sm">
          <div class="input-label">数量</div>
          <div class="input">
            <q-input
              borderless
              type="number"
              v-model="num"
              dense
              placeholder="请输入"
              @update:model-value="numChange"
            />
          </div>
          <div class="input-label">{{ swapInfo.lpccaiName }}</div>
        </div>
        <div class="tabs">
          <div
            class="tabs-item"
            :class="{ 'tabs-active': active == item.value }"
            v-for="(item, index) in tabs"
            :key="item.value"
            @click="tabsChange(index)"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="input-wrap">
          <div class="input-label">支付</div>
          <div class="input">
            <q-input borderless v-model="swapResult.usdt" dense readonly />
          </div>
          <div class="input-label">{{ swapInfo.usdtName }}</div>
        </div>
        <div class="data">
          <div>可用{{ swapInfo.usdtName }}：{{ swapInfo.usdt }}</div>
          <div>可用{{ swapInfo.lpccaiName }}：{{ swapInfo.lpccai }}</div>
        </div>
        <div class="btn" @click="onSubmit()">闪兑</div>
      </div>

      <div class="market">
        <div class="market-item item-bg2">
          <div class="market-value item-value-2">
            {{ swapInfo.dayTotalLast }} 枚CCAI
          </div>
          <div class="market-label ">今日剩余总量</div>
          <div
            class="item-btn"
            @click="$router.push('SwapOrderRecord?type=all')"
          >
            查看详情
          </div>
        </div>
        <div class="market-item item-bg1">
          <div class="market-value item-value-1">{{ swapInfo.dayBuyLast }}</div>
          <div class="market-label">
            <div>每个ID限量</div>
            <div>{{ swapInfo.dayBuy }}枚抢购额度</div>
          </div>
          <div
            class="item-btn"
            @click="$router.push('SwapOrderRecord?type=self')"
          >
            查看详情
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Web3Helper from "@/assets/js/web3helper.js";
// import CustomComponent from "@/components/CustomComponent";

export default {
  name: "Trade",
  components: {},
  setup() {
    return {
      us: ref(null),
      swapInfo: ref(null),
      num: ref(""),
      swapResult: ref({
        usdt: 0,
      }),
      tabs: [
        { text: "25%", value: 25 },
        { text: "50%", value: 50 },
        { text: "75%", value: 75 },
        { text: "100%", value: 100 },
      ],
      active: ref(""),
    };
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"));
    if (this.us) {
      this.getSwapInfo();
    }
  },
  methods: {
    tabsChange(index) {
      this.active = this.tabs[index].value;
      this.num = (this.swapInfo.dayBuyLast * this.active) / 100;
      this.numChange();
    },
    numChange() {
      if (this.num < 0) {
        this.num = 0;
      }
      if (this.num > this.swapInfo.dayBuyLast) {
        this.num = this.swapInfo.dayBuyLast;
      }
      this.swapResult.usdt = (this.num * this.swapInfo.price).toFixed(4);
    },
    getSwapInfo() {
      let _this = this;
      _this.$request.post(
        "api/SwapOrder/GetSwapInfo",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
            return;
          }
          let data = res.data.data;
          console.log(data);
          _this.swapInfo = data;
        }
      );
    },
    onSubmit() {
      let _this = this;
      if (this.num == "" || this.num == 0) {
        _this.$q.dialog({
          message: "请输入数量",
        });
        return;
      }
      _this.$q
        .dialog({
          title: `提示`,
          message: "确定要闪兑吗？",
          ok: {
            label: "确定",
          },
          cancel: {
            label: "取消",
            color: "white",
            textColor: "black",
          },
        })
        .onOk(() => {
          _this.$q.loading.show({
            message: "闪兑中...",
          });
          let web3Helper = new Web3Helper();
          web3Helper.getSign().then((signStr) => {
            _this.$request.post(
              "api/SwapOrder/Order",
              {
                token: _this.$utils.getloc("token"),
                userid: _this.us.userid,
                address: _this.us.address,
                signStr,
                num: _this.num,
              },
              (res) => {
                _this.$q.loading.hide();
                if (res.data.code != 100) {
                  _this.$q.dialog({
                    message: res.data.msg,
                  });
                  return;
                }
                _this.num = "";
                _this.getSwapInfo();
                _this.$q.dialog({
                  message: res.data.msg,
                });
              }
            );
          });
        });
    },
  },
};
</script>

<style scoped>
.trade {
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-icon {
  width: 25px;
}

.title {
  margin-left: 10px;
  font-size: 18px;
  /* color: #fff; */
}

.content {
  display: flex;
}

.market {
  display: flex;
  flex-direction: column;
}

.market-item {
  position: relative;
  flex: 1;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
}

.market-item:nth-child(2) {
  margin-top: 2px;
}

.item-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  margin: 0 10px 6px;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px;
  scale: 0.9;
}

.item-bg1 {
  background: url("../../../assets/img/trade/trade-img1.png");
  background-size: 100% 103%;
}

.item-bg2 {
  background: url("../../../assets/img/trade/trade-img2.png");
  background-size: 100% 103%;
}

.market-label {
  text-align: center;
  max-width: 110px;
  overflow-x: auto;
  margin-top: 15px;
  font-size: 12px;
  scale: 0.9;
  color: #fff;
}

.market-value {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  width: 120px;
  overflow-x: auto;
  text-align: center;
}

.form {
  margin-right: 10px;
}

.input-wrap {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 12px;
}

.input-label {
  padding: 6px 0;
  font-size: 14px;
}

.input {
  padding: 0 10px;
  flex: 1;
}

.input:deep(.q-field--dense .q-field__control) {
  height: 30px;
}

.form-label {
  padding: 4px 0;
  font-size: 14px;
  color: #aaaaaa;
  text-align: right;
}

.tabs {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.tabs-item {
  width: 23%;
  height: 20px;
  border-radius: 3px;
  /* color: #fff; */
  text-align: center;
  background-color: #fafafa;
}

.tabs-active {
  color: #fff;
  background-color: #19c9d8;
}

.data {
  margin-top: 10px;
}

.btn {
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: #19c9d8;
  border-radius: 10px;
}

.swap-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
