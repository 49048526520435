<template>
  <div class="page">
    <div class="content-tip">
      <!-- <img src="@/assets/img/base2/zuodaohang.png" class="tip-icon" @click="show = true" /> -->
      <div class="tip-name" v-if="selectSymbol.symbol">
        {{ selectSymbol.symbolName }}
        <span class="text-gray" v-if="selectSymbol.upDown == 0">{{ selectSymbol.upDown }}%</span>
        <span v-if="selectSymbol.upDown > 0">{{ selectSymbol.upDown }}%</span>
        <span class="text-red" v-if="selectSymbol.upDown < 0">{{ selectSymbol.upDown }}%</span>
      </div>
      <!-- <div class="ai-trade-text" @click="tolink('aiTradeRecord')">
        <img src="@/assets/img/base2/ai-trade.png" width="100" />
      </div> -->
    </div>

    <div class="content-row">
      <div class="content-left">
        <img src="@/assets/img/base2/robot.gif" class="robot-gif" />
        <div class="available">
          <div style="margin-right: 6px;text-align: right;">
            <div>{{ usdt }} USDT</div>
            <div>{{ ccai }} CCAI</div>
          </div>
          <!-- <img src="@/assets/img/base2/zhuanhuan.png" class="right-icon" /> -->
        </div>
        <!-- Echarts -->
        <div class="q-mt-sm" v-if="Object.keys(selectSymbol).length > 0">
          <TradeEcharts :data="selectSymbol" :key="selectSymbol.symbol" />
        </div>
        <!-- time -->
        <div class="time-row">
          <div class="time-item" :class="{ 'time-active': tid == item.id }" v-for="item in timeList" :key="item.id"
            @click="tid = item.id">
            {{ item.num }}s
            <!-- <div style="color: #ffd914;font-size: 12px;">收益{{item.bili}}%</div> -->
          </div>
        </div>
        <!-- <div class="form-item">
          <img src="@/assets/img/base2/add.png" class="right-icon" />
          <div>{{ selectSymbol.price }}</div>
          <img src="@/assets/img/base2/minus.png" class="right-icon" />
        </div> -->
        <div class="form-item">
          <q-input borderless dense v-model="num" placeholder="请输入CCAI" />
        </div>
        <div class="btn" @click="submit(0, num)">买入 CCAI</div>
        <div class="btn2" @click="submit(1, num)">卖出 CCAI</div>
      </div>

      <div class="content-right">
        <div class="content-right-top">
          <div>价格</div>
          <div>时间</div>
        </div>
        <!-- <div class="price-list">
          <div class="price-item" v-for="(item, index) in sellPriceList" :key="index">
            <div class="red-text">{{ item.price.toFixed(3) }}</div>
            <div>{{ item.num }}</div>
          </div>
        </div>
        <div class="cprice" v-if="selectSymbol">
          <div>{{ selectSymbol.price }}</div>
          <div>{{ canBuyNum }}</div>
        </div>
        <div class="price-list">
          <div class="price-item" v-for="(item, index) in buyPriceList" :key="index">
            <div class="green-text">{{ item.price.toFixed(3) }}</div>
            <div>{{ item.num }}</div>
          </div>
        </div> -->
        <div class="price-list">
          <div class="price-item" v-for="(item, index) in tradePriceList" :key="index">
            <div class="green-text">{{ item.priceNew.toFixed(3) }}</div>
            <div>{{ item.createTime }}</div>
          </div>
        </div>
        <div class="trade-chi">
          <div class="trade-chi-title">交易池</div>
          <div class="trade-chi-value">{{ canBuyNum }}</div>
        </div>
      </div>
    </div>

    <TradeRecord ref="orderRef"></TradeRecord>

    <!-- 侧边 -->
    <!-- <van-popup v-model:show="show" position="left">
      <div class="popup-scroll">
				<div class="coin-cell" v-for="item in symbolList" :key="item.symbol" @click="selectCoin(item)">
					<div>{{ item.symbolName }}</div>
					<div class="text-gray" v-if="item.upDown == 0">{{ item.upDown }}</div>
					<div class="text-green" v-if="item.upDown > 0">{{ item.upDown }}</div>
					<div class="text-red" v-if="item.upDown < 0">{{ item.upDown }}</div>
				</div>
			</div>
    </van-popup> -->

    <tab-bar active="4" />

    <CheckRegister @refresh="refresh" />
  </div>
</template>

<script>
  import Web3Helper from "@/assets/js/web3helper.js";
  import TabBar from "@/components/TabBar.vue";
  import { ref } from "vue";
  import { Popup, Picker, Toast } from "vant";
  import TradeRecord from "./components/TradeRecord.vue";
  import CheckRegister from "@/components/CheckRegister";
  import TradeEcharts from "@/components/TradeEcharts.vue";

  export default {
    components: {
      TabBar,
      CheckRegister,
      TradeEcharts,
      TradeRecord,
      [Popup.name]: Popup,
      [Picker.name]: Picker,
    },
    setup() {
      return {
        us: ref({}),
        symbolList: ref([]),
        selectSymbol: ref({}),
        usdt: ref(0),
        ccai: ref(0),
        timeout: ref(null),
        sellPriceList: ref([]),
        buyPriceList: ref([]),
        num: ref(""),
        show: ref(false),
        // showLeverage: ref(false),
        // leverageList: [10, 50, 100],
        // leverage: ref(10),

        timeList: ref([]),
        tid: ref(""),

        colorList: [
          { bgColor: "#be88d1", areaColor: "#D291C2" },
          { bgColor: "#B8DEFC", areaColor: "#A7CBFD" },
          { bgColor: "#E3E4F6", areaColor: "#AFB5E7" },
          { bgColor: "#FF8B7E", areaColor: "#C64867" },
          { bgColor: "#FAE8D2", areaColor: "#F6CC9B" },
          { bgColor: "#FEBED5", areaColor: "#FF9FA5" },
        ],

        canBuyNum: ref(0),
        tradePriceList: ref([]),
      };
    },
    created() {
      this.getSymbolList();
      this.us = JSON.parse(this.$utils.getloc("us"));
      if (this.us) {
        this.getWallet();
        this.getTradeCCAI();
        this.getTradePrice();
      }
    },
    unmounted() {
      clearTimeout(this.timeout);
    },
    methods: {
      refresh() {
        this.$refs.orderRef.getList();
        this.getWallet();
      },
      tolink(path) {
        this.$router.push(path);
      },
      leverageConfirm(e) {
        this.leverage = e;
        this.showLeverage = false;
      },
      profitlossConfirm(e) {
        this.profit = e.profit;
        this.loss = e.loss;
      },
      // 选择币种
      selectCoin(e) {
        this.selectSymbol = e;
        this.show = false;
        clearTimeout(this.timeout);
        // this.getPrice()
      },
      // 对象数组去重
      repeat(arr) {
        let list = [];
        return arr.filter(
          (item) => !list.includes(item.price) && list.push(item.price)
        );
      },
      // 买入 / 卖出 list
      getPrice() {
        let sellArr = [];
        let buyArr = [];
        let price = this.selectSymbol.price;
        if (price <= 0) {
          return;
        }
        for (let i = 0; i < 10; i++) {
          // 随机数可能会又相同
          let num = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
          sellArr.push({
            price: (price * num) / 10000 + price,
            num: Math.floor(Math.random() * (1000 - 10 + 1)) + 10,
          });
          buyArr.push({
            price: price - (price * num) / 10000,
            num: Math.floor(Math.random() * (1000 - 10 + 1)) + 10,
          });
        }
        buyArr.sort((a, b) => {
          return b.price - a.price;
        });
        sellArr.sort((a, b) => {
          return b.price - a.price;
        });
        // this.buyPriceList = this.repeat(buyArr).slice(0, 6)
        // this.sellPriceList = this.repeat(sellArr).slice(0, 6)
        this.timeout = setTimeout(() => {
          this.getSymbolList();
        }, 5000);
        console.log("get price");
      },
      getWallet() {
        let _this = this;
        this.$request.post(
          "api/Wallets/GetWallet",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
          },
          (res) => {
            if (res.data.code == 100) {
              let data = res.data.data;
              data.forEach((item) => {
                if (item.cid == 2) {
                  _this.usdt = item.jine;
                } else if (item.cid == 6) {
                  _this.ccai = item.jine;
                }
              });
            }
          }
        );
      },
      getSymbolList() {
        let _this = this;
        this.$request.post(
          "api/Trade/TradeList",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
          },
          (res) => {
            if (res.data.code == 100) {
              let data = res.data.data;
              data.forEach((item, index) => {
                let colorObj = _this.colorList[index];
                Object.assign(item, colorObj);
              });
              _this.symbolList = data;
              if (
                Object.keys(_this.selectSymbol).length == 0 &&
                data.length > 0
              ) {
                _this.selectSymbol = data[0];
              } else {
                _this.selectSymbol = data.find(
                  (item) => item.symbol == _this.selectSymbol.symbol
                );
              }
              // _this.getPrice()
            }
          },
          false
        );
      },
      submit(type, num) {
        let _this = this;
        if (num == "") {
          Toast("请输入数量");
          return;
        }
        let msg = type == 0 ? "买入" : "卖出";
        _this.$q
          .dialog({
            title: "提示",
            message: `确定要${msg}吗？`,
            persistent: true,
            cancel: true,
          })
          .onOk(() => {
            _this.$q.loading.show({
              message: `${msg}中...`,
            });
            let web3Helper = new Web3Helper();
            web3Helper.getSign().then((signStr) => {
              _this.$request.post(
                "api/Trade/Order",
                {
                  token: _this.$utils.getloc("token"),
                  userid: _this.us.userid,
                  address: _this.us.address,
                  signStr,
                  type,
                  num,
                },
                (res) => {
                  _this.$q.loading.hide();
                  _this.$q.dialog({
                    message: res.data.msg,
                  });
                  if (res.data.code == 100) {
                    _this.num = "";
                    // console.log(_this.$refs.orderRef)
                    _this.getWallet();
                    _this.getTradeCCAI();
                    _this.$refs.orderRef.getList();
                  }
                }
              );
            });
          });
      },
      getTradeCCAI() {
        let _this = this;
        this.$request.post(
          "api/Trade/GetTradeCCAI",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
          },
          (res) => {
            if (res.data.code == 100) {
              let data = res.data.data;
              _this.canBuyNum = data;
            }
          }
        );
      },
      getTradePrice() {
        let _this = this;
        this.$request.post(
          "api/TradePrice/List",
          {
            token: _this.$utils.getloc("token"),
          },
          (res) => {
            if (res.data.code == 100) {
              let data = res.data.data;
              _this.tradePriceList = data;
              console.log(data);
            }
          }
        );
      },
    },
  };
</script>

<style scoped>
  .page {
    padding: 15px;
  }

  .content-tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tip-icon {
    width: 22px;
    height: 22px;
  }

  .tip-name {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .tip-name span {
    margin-left: 10px;
    color: #2cb45a;
    font-size: 14px;
  }

  .content-row {
    margin-top: 15px;
    display: flex;
  }

  .content-left {
    position: relative;
    flex: 1;
    margin-right: 15px;
  }

  .content-right {
    width: 130px;
  }

  .content-right-top {
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #7f8798;
  }

  .price-item {
    margin-top: 7px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #7f8798;
  }

  .price-item:first-child {
    margin-top: 0;
  }

  .red-text {
    color: #ff4343;
  }

  .green-text {
    color: #2cb45a;
  }

  .cprice {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }

  .tabs {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  .tabs-item {
    width: 167px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #6d73f4;
    border-radius: 22px;
    font-size: 14px;
    color: #000;
  }

  .tabs-active {
    background: linear-gradient(90deg, #4344de 0%, #5b5ff8 100%);
  }

  .form-item {
    height: 40px;
    margin-top: 12px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    color: #252525;
    background-color: #dadada;
  }

  .form-label {
    color: #7f8798;
  }

  .available {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #7f8798;
    font-size: 12px;
  }

  .volume {
    margin-top: 15px;
    font-size: 12px;
    color: #7f8798;
  }

  .btn {
    margin-top: 13px;
    color: #fff;
    height: 36px;
    border-radius: 18px;
    background-color: #2cb45a;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn2 {
    margin-top: 13px;
    color: #fff;
    height: 36px;
    border-radius: 18px;
    background-color: #ff4343;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cell {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 12px;
  }

  .cell-label {
    color: #7f8798;
  }

  .cell-value {
    color: #000;
  }

  .select {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .select-item {
    margin-top: 11px;
    width: 46%;
    height: 38px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: hsla(0, 0%, 100%, 0.2);
    font-size: 13px;
  }

  .select-time {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: hsla(0, 0%, 100%, 0.3);
    color: #000;
    border-radius: 10px;
  }

  .select-rate {
    flex: 1;
    text-align: center;
    color: #6a62fa;
  }

  .select-active {
    background-color: rgba(2, 176, 145, 0.3);
  }

  .select-time-active {
    background-color: rgba(2, 176, 145, 0.7);
  }

  .right-icon {
    width: 16px;
    height: 16px;
  }

  .popup-scroll {
    padding: 30px 15px 0;
    box-sizing: border-box;
    width: 260px;
    height: 100vh;
    background-color: rgb(7, 4, 42);
  }

  .coin-cell {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    color: #000;
  }

  .coin-cell:last-child {
    margin: 0;
  }

  .time-row {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }

  .time-item {
    padding: 6px 0;
    width: 30%;
    border-radius: 6px;
    text-align: center;
    color: #000;
    background-color: hsla(0, 0%, 100%, 0.3);
  }

  .time-active {
    background-color: #5b5ff8;
  }

  .content-left:deep(.trade-echart) {
    width: 100%;
    height: 110px;
  }

  .content-left:deep(.chart) {
    width: 100%;
    height: 110px;
  }

  .content-left:deep(.trade-content > div) {
    margin-top: 2px;
    font-size: 14px;
  }

  .ai-trade-text {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #000;
    text-decoration: underline;
    color: #ffd914;
  }

  .robot-gif {
    position: absolute;
    width: 35px;
    top: -15px;
    left: -5px;
  }

  .tip {
    margin-top: 12px;
    font-size: 13px;
    color: #ffd585;
    text-align: right;
  }

  .trade-chi {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  .trade-chi-title {
    flex-shrink: 0;
  }

  .trade-chi-value {
    max-width: 70px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
</style>