<template>
  <div class="landing-swiper">
    <div class="landing-swiper-top">
      <img src="@/assets/img/base/dark_overlay.png" style="height: 180px;" />
      <div class="landing-swiper-title">{{ $t('Home.mubiao') }}</div>
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item class="q-px-md">
        <div class="landing-swiper-item">
          <div>{{ $t('Home.mubiaolabel1') }}</div>
          <div class="q-mt-md">{{ $t('Home.mubiaomsg1') }}</div>
          <div class="text-right q-mt-lg">
            <img src="@/assets/img/base/slider_cross.png" width="80" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="q-px-md">
        <div class="landing-swiper-item">
          <div>{{ $t('Home.mubiaolabel2') }}</div>
          <div class="q-mt-md">{{ $t('Home.mubiaomsg2') }}</div>
          <div class="text-right q-mt-lg">
            <img src="@/assets/img/base/slider_cross.png" width="80" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="q-px-md">
        <div class="landing-swiper-item">
          <div>{{ $t('Home.mubiaolabel3') }}</div>
          <div class="q-mt-md">{{ $t('Home.mubiaomsg3') }}</div>
          <div class="text-right q-mt-lg">
            <img src="@/assets/img/base/slider_cross.png" width="80" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="q-px-md">
        <div class="landing-swiper-item">
          <div>{{ $t('Home.mubiaolabel4') }}</div>
          <div class="q-mt-md">{{ $t('Home.mubiaomsg4') }}</div>
          <div class="text-right q-mt-lg">
            <img src="@/assets/img/base/slider_cross.png" width="80" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="q-px-md">
        <div class="landing-swiper-item">
          <div>{{ $t('Home.mubiaolabel5') }}</div>
          <div class="q-mt-md">{{ $t('Home.mubiaomsg5') }}</div>
          <div class="text-right q-mt-lg">
            <img src="@/assets/img/base/slider_cross.png" width="80" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="q-px-md">
        <div class="landing-swiper-item">
          <div>{{ $t('Home.mubiaolabel6') }}</div>
          <div class="q-mt-md">{{ $t('Home.mubiaomsg6') }}</div>
          <div class="text-right q-mt-lg">
            <img src="@/assets/img/base/slider_cross.png" width="80" />
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';

export default {
  components: {
    [Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
  },
  setup() {
    return {

    }
  }
}
</script>

<style scoped>
.landing-swiper {
  background-color: #0C1530;
}

.landing-swiper-top {
  padding-top: 40px;
  position: relative;
}

.landing-swiper-top img {
  width: 100%;
}

.my-swipe {
  margin-top: -100px;
}

.landing-swiper-title {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.landing-swiper-item {
  margin: 0 10px;
  padding: 70px 35px 10px;
  border-radius: 6px;
  background: url('~@/assets/img/base/swiper-bg.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
  min-height: 290px;
}
</style>