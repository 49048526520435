<template>
  <div class="title-name">
    <!-- <img src="@/assets/img/base/icon3.png" width="45" style="margin-bottom: 10px;" v-if="showImg" /> -->
    <div>{{ title }}</div>
    <div class="title-label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: String,
      default: "标题"
    },
    label: {
      type: String,
      default: ""
    },
    showImg: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
.title-name {
  margin-top: 20px;
  padding: 0 12px;
  font-size: 20px;
  font-weight: bold;
}

.title-label {
  margin-left: 3px;
  font-weight: 900;
  text-stroke: 1px rgba(0,0,0,.5);
  -webkit-text-stroke: 1px rgba(0,0,0,.5);
  color: #fff;
}
</style>