<template>
  <div class="time-line">
    <div class="text-center">
      <img src="@/assets/img/base/elfu_logo.png" width="100" />
    </div>
    
    <q-timeline color="blue-12">
      <q-timeline-entry :title="$t('Home.time1')">
        <div class="time-msg">
          <div>{{ $t('Home.timemsg1') }}</div>
          <div>{{ $t('Home.timemsg2') }}</div>
        </div>
      </q-timeline-entry>

      <q-timeline-entry :title="$t('Home.time2')">
        <div class="time-msg">
          <div>{{ $t('Home.timemsg3') }}</div>
          <div>{{ $t('Home.timemsg4') }}</div>
        </div>
      </q-timeline-entry>

      <q-timeline-entry :title="$t('Home.time3')">
        <div class="time-msg">
          <div>{{ $t('Home.timemsg5') }}</div>
          <div>{{ $t('Home.timemsg6') }}</div>
        </div>
      </q-timeline-entry>

      <q-timeline-entry :title="$t('Home.time4')">
        <div class="time-msg">
          {{ $t('Home.timemsg7') }}
        </div>
      </q-timeline-entry>

      <q-timeline-entry :title="$t('Home.time5')">
        <div class="time-msg">
         <div>{{ $t('Home.timemsg8') }}</div>
         <div>{{ $t('Home.timemsg9') }}</div>
        </div>
      </q-timeline-entry>

      <q-timeline-entry :title="$t('Home.time6')">
        <div class="time-msg">
          <div>{{ $t('Home.timemsg10') }}</div>
          <div>{{ $t('Home.timemsg11') }}</div>
        </div>
      </q-timeline-entry>

      <q-timeline-entry :title="$t('Home.time7')">
        <div class="time-msg">
          <div>{{ $t('Home.timemsg12') }}</div>
          <div>{{ $t('Home.timemsg13') }}</div>
        </div>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  setup() {
    return {

    }
  }
}
</script>

<style scoped>
.time-line {
  padding: 60px 20px 10px;
  background-color: #0C1530;
  color: #fff;
}

.time-msg {
  font-size: 13px;
  color: #828599;
}
</style>