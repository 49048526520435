<template>
  <div class="node-record">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="setLanguageAfter"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('AiTradeDetail.title')" label="Transaction Details"></title-name>
    </div>

    <div class="list">
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('NodeRecord.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <div class="item-cell">
            <!-- <div>{{ item.name }} {{$t('AiTradeDetail.yongxu')}}</div> -->
            <div>{{ item.name }} / USDT</div>
            <div class="row items-center">
              {{item.lever}}x <span class="item-tag">{{$t('AiTradeDetail.pingcang')}}</span>
            </div>
          </div>
          <div class="item-cell">{{ item.priceUpdate }}</div>
          <div class="item-row">
            <div class="item-col">
              <div class="label">{{$t('AiTradeDetail.jiage')}}</div>
              <div>{{ item.price }}</div>
            </div>
            <div class="item-col text-center">
              <div class="label">{{$t('AiTradeDetail.num')}}</div>
              <div>{{ item.num.toFixed(2) }}</div>
            </div>
            <div class="item-col text-right">
              <div class="label">{{$t('AiTradeDetail.shouyi')}}</div>
              <div v-if="item.shouyi > 0" style="color: green;">{{ item.shouyi }}</div>
              <div v-if="item.shouyi < 0" style="color: #e02020;">{{ item.shouyi }}</div>
              <div v-if="item.shouyi == 0">{{ item.shouyi }}</div>
            </div>
          </div>
        </div>
      </van-list>
      <empty text="No records" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List  } from "vant";

export default {
  name: 'Bill',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    setLanguageAfter() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.list = []
      this.getdata()
    },
    // 顶部组件的后退方法
    onBack() {
      this.$emit("close")
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/FakeDetails/DetailsList",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          pageSize: 10,
          pageIndex: _this.pageIndex
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          if(data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
          // console.log(data)
        }
      )
    }
  }
}
</script>

<style scoped>
.node-record {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 15px 12px 0;
}

.item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 12px;
  font-size: 13px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.item-cell {
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-row {
  margin-top: 6px;
  display: flex;
}

.item-col {
  flex: 1;
}

.label {
  color: gray;
}

.item-tag {
  padding: 3px 10px;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #6EADFD;
  color: #6EADFD;
}
</style>