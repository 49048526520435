<template>
  <div class="node-record">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="setLanguageAfter"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('NodeRecord.title')" label="Purchase Record"></title-name>
    </div>

    <div class="list">
      <div class="list-name">
        <div>
          {{ $t('NodeRecord.date') }}
        </div>
        <div class="justify-center">
          {{ $t('NodeRecord.node') }}
        </div>
        <div class="justify-end">
          {{ $t('NodeRecord.amount') }}
        </div>
      </div>
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('NodeRecord.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="list-val" v-for="item in list" :key="item.id">
          <div>{{ item.cdate }}</div>
          <div class="justify-center text-center">{{ item.name }}</div>
          <div class="justify-end">{{ item.price }}</div>
        </div>
      </van-list>
      <empty text="No records" v-if="list.length == 0" />
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List  } from "vant";

export default {
  name: 'Bill',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    setLanguageAfter() {
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.list = []
      this.getdata()
    },
    // 顶部组件的后退方法
    onBack() {
      this.$emit("close")
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/JiedianUser/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          pageSize: 10,
          pageIndex: _this.pageIndex
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          let language = this.$utils.getloc("language")
          data.forEach(item => {
            if(language == 'zh') {
							item.name = item.jnameZh
						} else if(language == 'kor') {
							item.name = item.jnameKor
						} else if(language == 'may') {
							item.name = item.jnameMay
						} else if(language == 'vie') {
							item.name = item.jnameVie
						} else if(language == 'th') {
							item.name = item.jnameTh
						} else {
							item.name = item.jnameEn
						}
          })
          if(data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
        }
      )
    }
  }
}
</script>

<style scoped>
.node-record {
  min-height: 100vh;
  background: url('~@/assets/img/base/banner-background.jpg') no-repeat;
  background-size: 100% 100%;
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 24px 12px 0;
  padding: 0 10px;
}

.list-name {
  display: flex;
  color: gray;
}

.list-name > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.list-val > div {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>