/* import TronWeb from 'tronweb';
const HttpProvider = TronWeb.providers.HttpProvider;
const fullNode = new HttpProvider("https://api.trongrid.io");
const solidityNode = new HttpProvider("https://api.trongrid.io");
const eventServer = new HttpProvider("https://api.trongrid.io");

const tronWeb = new TronWeb(
	fullNode,
	solidityNode,
	eventServer
);

tronWeb.setHeader({
	"TRON-PRO-API-KEY": '2d2aeb20-6302-4408-9e2c-96c535082ee8'
}); */

import utils from './utils.js';

import Loading from 'quasar/src/plugins/Loading.js';
import Dialog from 'quasar/src/plugins/Dialog.js';

const tronWeb = window.tronWeb;

const addTransactionStorage = (amount, tokenName, txid) => {
	let transactionStorage = {};
	transactionStorage.timestamp = Date.parse(new Date());
	transactionStorage.txid = txid;
	transactionStorage.amount = amount;
	transactionStorage.tokenName = tokenName;
	
	if (utils.getloc(window.tronWeb.defaultAddress.base58)) {
		let transactionStorageList = JSON.parse(utils.getloc(window.tronWeb.defaultAddress.base58));
		transactionStorageList.push(transactionStorage);
		utils.setloc(window.tronWeb.defaultAddress.base58,JSON.stringify(transactionStorageList));
	} else {
		let transactionStorageList = [];
		transactionStorageList.push(transactionStorage);
		utils.setloc(window.tronWeb.defaultAddress.base58,JSON.stringify(transactionStorageList));
	}
}

const checkAddress = (addressBase58) => {
	return addressBase58.length == 34 ? true : false
}

const checkTronWeb = (showLoading = false) => {
	if (showLoading){
		Loading.show({
			message:'正在连接TronLink...'
		});
	}
	return new Promise(function(resolve, reject) {
		let i = 0;
		var obj = setInterval(async () => {
			if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
				clearInterval(obj)
				Loading.hide();
				if(checkAddress(window.tronWeb.defaultAddress.base58)){
					resolve(window.tronWeb);
				}else{
					alert('请切换Tron地址');
				}
			}
			i++;
			if (i >= 50) {
				clearInterval(obj)
				Loading.hide();
				alert('请连接TronLink');
			}
		}, 200);
	});
}

const getContract = (contractAddress) => {
	return new Promise(function(resolve, reject) {
		window.tronWeb.contract().at(contractAddress).then(contract => {
			resolve(contract);
		});
	});
}

const transfer = (from, amount, to, contractInfo) => {
	return new Promise(function(resolve, reject) {
		tronWeb.transactionBuilder.triggerSmartContract(
			tronWeb.address.toHex(contractInfo.contractAddress),
			"transfer(address,uint256)", {},
			[{
				type: 'address',
				value: to
			}, {
				type: 'uint256',
				value: amount * Math.pow(10, contractInfo.decimals)
			}], tronWeb.address.toHex(from)).then(transaction => {
			resolve(transaction)
		});
	});
}

export default {
	getContract,
	tronWeb,
	transfer,
	checkTronWeb,
	checkAddress,
	addTransactionStorage
}