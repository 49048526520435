<template>
  <div class="page">
    <NavBar />

    <div class="container">
      <div class="title">{{ $t('Trading.tubiao') }}</div>
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="tabs q-mt-sm">
            <div class="tabs-name">
              <div>{{ $t('Trading.huobi') }}</div>
              <div>{{ $t('Trading.jiage') }}</div>
            </div>
            <div class="tabs-val" v-for="item in list" :key="item.id">
              <div>{{ item.symbol }}</div>
              <div>{{ item.priceOut.toFixed(6) }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <Echarts class="q-mt-lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import NavBar from './components/NavBar'
import Echarts from './components/Echarts' 

export default {
  components: {
    NavBar,
    Echarts
  },
  setup() {
    return {
      list: ref(false)
    }
  },
  created() {
    this.getEthContract()
  },
  methods: {
    getEthContract() {
      let _this = this
      _this.$request.post(
        "api/EthContract/List",
        {},
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
					}
          let data = res.data.data
          let dogeIndex = data.findIndex(item => item.symbol === 'DOGE')
          let doge = data.splice(dogeIndex, 1)
          data.splice(3, 0, ...doge)
          _this.list = data
          // console.log(data)
        }
      )
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-color: #1F2937;
}

.container {
  padding: 15px;
}

.title {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}

.tabs-name {
  padding: 10px 20px;
  display: flex;
  color: #fff;
  background-color: #374151;
}

.tabs-name > div {
  flex: 1;
}

.tabs-val {
  padding: 15px 20px;
  display: flex;
  color: #fff;
  border-bottom: 1px solid #374151;
}

.tabs-val > div {
  flex: 1;
}
</style>