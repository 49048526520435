module.exports = {
  Base: {
    copy: "Salin berjaya",
    back: "Kembali",
    empty: "Tiada data lagi",
    empty2: "Masa ini tiada rekod tersedia",
    tishi: "prompt",
    queding: "sahkan",
    quxiao: "batalkan",
    tijiao: "Menghantar..."
  },
  CheckRegister: {
    tuijianren: "Disarankan oleh",
    placeholder: "Sila masukkan alamat rujukan",
    register: "daftar",
    addressErr: "Ralat mendaftar untuk mendapatkan alamat:",
    qingshuruyonghuming: "masukkan satu nama pengguna",
    qingshuruyongmima: "Sila masukkan kata laluan",
    dengluzhong: "Daftar masuk"
  },
  Home: {
    gonggao: "pengumuman",
    rules: "Huraian Peraturan",
    purchaseRecord: "Rekod Pembelian",
    select: "Pilih Aras",
    amount: "Jumlah pembelian",
    submit: "Nod pembelian",
    chongzhi: "Muat Semula Sekarang",
    referrers: "Penawar saya",
    link: "Sambungan",
    list: "Senarai Tanggalan",
    address: "alamat",
    listAmount: "Jumlah tandatangan",
    state: "keadaan",
    completed: "Selesai",
    message1: "Salin berjaya",
    message2: "Awak pasti nak beli?",
    message3: "Membeli...",
    down: "Pembelian panik nod jarak",
    day: "hari",
    hours: "Jam",
    seconds: "saat",
    jieshu: "Pembelian panik nod telah berakhir",
    downTitle1: "Kawan Nod Edison AI",
    downTitle2: "Penjemputan global dalam proses",
    zhitui: "Senarai tolak langsung",
    qianggouzhong: "Nod fasa kedua dalam pembelian terburu-buru",
    minutes: "cabang",
    jiedian: "Kuasa pengiraan nodal",
    jifaqianneng: 'Menggugah potensi tak terbatas, menciptakan kemungkinan tanpa batas',
    jinridapp: 'Masuk ke DAPP',
    guanyu: 'Tentang Kami',
    lianjiequkuai: 'Menghubungkan jurang antara teknologi blockchain dan kebutuhan dunia bisnis yang nyata',
    zhanxianshequ: 'Menampilkan kekuatan pembangunan komunitas dan konsensus dalam praktik',
    sectionmsg1: 'CITADEL AI adalah aliansi global komunitas yang terdiri dari pemimpin, inovator, pengembang blockchain, dan perusahaan internasional. Aliansi ini akan menjadi katalis pertumbuhan dan kemakmuran CITADEL AI blockchain, mengembangkan peluang bisnis baru, mendorong aplikasi industri, serta belajar dan berkolaborasi bersama.',
    sectionmsg2: 'Kami mengumpulkan komunitas global yang kuat dalam keyakinan terhadap CITADEL AI, dan siap memberikan bimbingan, dukungan, dan manajemen kepada perusahaan dalam hal teknologi blockchain dan teknologi baru.',
    zhize: 'Tugas Aliansi',
    zhizemsg: 'CITADEL AI adalah kekuatan inti dari ekosistem CITADEL AI, menjunjung tinggi semangat desentralisasi, dan akan mendorong perkembangan teknologi dan ekosistem CITADEL AI melalui berbagai cara yang berbeda.',
    shequ: 'Rencana Komunitas',
    shequmsg1: 'Bersama-sama membangun masa depan CITADEL AI',
    shequmsg2: 'CITADEL AI terus berperan penting dalam pembangunan dan operasi komunitas CITADEL AI. Sebagai anggota CITADEL AI, pengguna akan terlibat dalam komunitas yang penuh energi, berpartisipasi dalam pengambilan keputusan, mengorganisir acara, dan bersama-sama membentuk blueprints untuk masa depan CITADEL AI.',
    shequmsg3: 'Menuju era Web3 yang desentralisasi',
    shequmsg4: 'Model partisipasi komunitas desentralisasi yang dipegang oleh kami sepenuhnya sesuai dengan visi Web3, yang tidak hanya meningkatkan transparansi ekosistem CITADEL AI, tetapi juga memastikan keadilan dan kesetaraan setiap keputusan.',
    qiye: 'Rencana Perusahaan',
    qiyemsg1: 'Menyediakan solusi blockchain berbasis inovasi untuk perusahaan',
    qiyemsg2: 'CITADEL AI bekerja sama secara mendalam dengan perusahaan di seluruh dunia untuk terus mengeksplorasi potensi tak terbatas dari teknologi blockchain CITADEL AI. Kami berkomitmen untuk menyediakan solusi blockchain yang disesuaikan dengan kebutuhan perusahaan, membantu mereka tetap kompetitif di era ekonomi digital.',
    qiyemsg3: 'Memanfaatkan potensi CITADEL AI Mainnet secara maksimal',
    qiyemsg4: 'Kami juga akan memberikan dukungan komprehensif kepada perusahaan untuk memahami dan memanfaatkan keunggulan teknologi CITADEL AI Mainnet serta keunggulan uniknya dalam hal keamanan dan keandalan data, untuk memastikan pengalaman terbaik bagi perusahaan dalam ekosistem CITADEL AI.',
    qukuaigonglian: 'Blockchain Public Chain',
    xiayidai: 'Generasi berikutnya dari Web3 blockchain desentralisasi',
    xinjingji: 'Solusi blockchain untuk era ekonomi baru',
    xinjingjimsg: 'CITADEL AI, sebuah jaringan blockchain yang dirancang khusus untuk ekonomi baru, memiliki arsitektur unik berupa main chain dan side chain yang memberikan ruang bagi pengembang untuk men-deploy dan menjalankan aplikasi terdistribusi secara independen, sehingga mencapai optimisasi sumber daya dan penggunaan yang efektif. Melalui komputasi paralel yang efisien dan mekanisme konsensus, CITADEL AI menyediakan dukungan yang kuat untuk transaksi berkecepatan tinggi. Selain itu, teknologi interoperabilitasnya juga memastikan komunikasi yang lancar dan transfer nilai antara blockchain.',
    kaifgongju: 'Alat Pengembangan dan Pengelolaan yang Kuat',
    kaifgongjumsg: 'Berbagai skenario bisnis membutuhkan model pengelolaan yang berbeda, CITADEL AI memahami hal ini dengan baik, dan oleh karena itu menyediakan berbagai model pengelolaan seperti pengelolaan parlemen, pengelolaan masyarakat, dan pengelolaan asosiasi. Untuk memastikan pengembangan sistem yang berkelanjutan dan sehat, CITADEL AI juga merancang model insentif yang cermat. Selain itu, CITADEL AI juga menyediakan alat IDE yang canggih untuk membantu pengembang dalam mengembangkan, melakukan debug, dan deploy aplikasi dengan lebih mudah.',
    zhongxinhua: 'Berkontribusi pada pemerintahan desentralisasi',
    zhongxinhuazeren: 'Sebagai produsen blok utama CITADEL AI Mainnet, CITADEL AI memiliki tanggung jawab penting.',
    zhongxinhuali1: 'Memastikan keamanan jaringan',
    zhongxinhuali2: 'Meningkatkan keputusan konsensus',
    zhongxinhuali3: 'Menjaga operasi yang optimal dari node side chain',
    zhongxinhuali4: 'Menjaga stabilitas jaringan',
    pianzhng: 'Masuki babak baru',
    pianzhnglabel1: 'Bukan hanya jaringan blockchain biasa',
    pianzhngmsg1: 'Kami juga berusaha merangkul konsep organisasi otonom desentralisasi (DAO). Untuk itu, CITADEL AI telah mendirikan DAO-nya sendiri dan meluncurkan program rekrutmen node global.',
    pianzhnglabel2: 'CITADEL AI berkomitmen untuk merekrut 20 node senior di seluruh dunia',
    pianzhngmsg2: 'Setiap perwakilan adalah elit di bidangnya masing-masing, baik itu lembaga internasional atau tokoh terkemuka di bidang tertentu. 20 perwakilan ini akan berpartisipasi bersama dan membuat keputusan penting tentang ekosistem CITADEL AI, mendorong CITADEL AI menuju tingkat perkembangan yang lebih tinggi.',
    pianzhnglabel3: 'Organisasi otonom desentralisasi adalah salah satu aplikasi penting dari teknologi blockchain',
    pianzhngmsg3: 'Ini mengesampingkan cara pengelolaan terpusat tradisional, sehingga proses pengambilan keputusan menjadi lebih demokratis dan transparan. Dalam struktur DAO, setiap anggota dapat memberikan suara berdasarkan kontribusi mereka dalam sistem atau kepemilikan saham mereka, sehingga secara kolektif memutuskan arah masa depan organisasi.',
    pianzhnglabel4: 'Pendirian Yayasan CITADEL AI bertujuan untuk melibatkan lebih banyak orang',
    pianzhngmsg4: 'dalam perkembangan CITADEL AI, dan bersama-sama menulis sejarah gemilang CITADEL AI.',
    mubiao: "Matlamat Utama",
    mubiaolabel1: "Penyelenggaraan Ekosistem",
    mubiaomsg1: "Memilih, menyokong dan mempromosikan projek-projek berpotensi tinggi dalam ekosistem CITADEL AI.",
    mubiaolabel2: "Pengesahan Teknologi",
    mubiaomsg2: "Bertanggungjawab untuk memastikan keselamatan rangkaian melalui penghasilan dan pengesahan transaksi blockchain.",
    mubiaolabel3: "Koordianasi Silang Rantai",
    mubiaomsg3: "Menjalin hubungan kerjasama dengan entiti-entiti blockchain lain untuk memastikan CITADEL AI sentiasa mempunyai interoperabiliti dan dikemaskini mengikut standard industri terkini.",
    mubiaolabel4: "Pembangunan Komuniti",
    mubiaomsg4: "Mengetuai pembangunan komuniti, membina konsensus dan mencipta ruang interaktif yang positif untuk memacu pertumbuhan ekosistem CITADEL AI bersama-sama.",
    mubiaolabel5: "Operasi dan Pengurusan DAO",
    mubiaomsg5: "Mengawasi pemilihan, perbincangan dan pengundian usul-usul komuniti untuk memastikan usul tersebut selaras dengan misi dan nilai CITADEL AI.",
    mubiaolabel6: "Pengumpulan Modal",
    mubiaomsg6: "Mengawasi usaha pengumpulan modal untuk memastikan pertumbuhan dan kestabilan kewangan ekosistem CITADEL AI.",
    time1: "Suku Keempat 2023",
    time2: "Suku Pertama 2024",
    time3: "Suku Kedua 2024",
    time4: "Suku Ketiga 2024",
    time5: "Suku Keempat 2024",
    time6: "2025",
    time7: "2026",
    timemsg1: "Merekrut secara aktif, menarik 100,000 pengguna di seluruh dunia yang meliputi 20 buah negara.",
    timemsg2: "Mendorong penyelidikan dan pelaksanaan Web3, menyelaras ekosistem CITADEL AI dengan perkembangan terkini Web3.",
    timemsg3: "Membantu program pembiayaan Aclevate dengan mempromosikan pasukan pembangun permainan yang telah diterima oleh CITADEL AI, meningkatkan jumlah peminat dan kepopularan pasukan tersebut, dan memberikan pengaruh yang lebih besar bagi ekosistem CITADEL AI dalam bidang kewangan permainan.",
    timemsg4: "Menerusi persidangan dalam talian dan luar talian, pertukaran perdagangan, pameran, dan aktiviti komuniti lain, menarik lebih banyak syarikat dan pengguna untuk menyertai CITADEL AI.",
    timemsg5: "Memperkuat hubungan dengan rakan kongsi global demi mendorong pertumbuhan ekosistem CITADEL AI bersama-sama.",
    timemsg6: "Membina persekutuan GameFi, menjadikan CITADEL AI sebagai pemimpin dalam permainan Play-to-Earn dan inovasi GameFi lain.",
    timemsg7: "Berjaya menarik 500,000 pengguna di seluruh dunia yang meliputi sekurang-kurangnya 50 buah negara untuk membantu CITADEL AI menambahkan sekurang-kurangnya 30 projek baru dari pelbagai bidang dalam ekosistemnya, dan terus memperluaskan dan memperkukuh fungsi dan kepelbagaian ekosistem CITADEL AI.",
    timemsg8: "Membantu membina inkubator CITADEL AI, menyokong dan memacu pembangunan projek inovatif dalam rangkaian awam CITADEL AI.",
    timemsg9: "Mendorong ekosistem SocialFi, menjadikan CITADEL AI sebagai perintis dalam kewangan sosial dan inovasi berkaitannya.",
    timemsg10: "Menubuhkan akademi CITADEL AI, menyediakan sumber pendidikan dan latihan untuk meningkatkan pengetahuan dan kemahiran blockchain dalam kalangan ahli komuniti.",
    timemsg11: "Melancarkan pelbagai program ganjaran komuniti untuk memberi insentif kepada lebih ramai orang untuk menyertai pembinaan ekosistem CITADEL AI.",
    timemsg12: "Berjaya merekrut 1 juta pengguna di seluruh dunia yang meliputi 100 buah negara.",
    timemsg13: "Menjadikan ekosistem CITADEL AI sebagai ekosistem blockchain yang paling kuat dan pelbagai di seluruh dunia.",
    genzongtitle: 'Robot Penjejak DeFi Perniagaan Kecekapan',
    genzonglabel: 'Alat pemantauan pelaburan dan maksimumkan pulangan.',
    genzongmsg1: 'Robot kami direka dengan teliti untuk menjejaki dan menganalisis pulangan yang dihasilkan oleh pelbagai alat pelaburan dan strategi dalam ekosistem Kewangan Terdesentralisasi (DeFi).',
    genzongmsg2: 'Pelabur boleh mendapatkan wawasan unik mengenai prestasi pelaburan dan keuntungan dari robot canggih kami. Dengan akses kepada petunjuk pulangan terkini dan menilai kesan strategi, keputusan yang jelas dapat dibuat untuk mengoptimumkan pulangan pelaburan.',
    genzongmsg3: 'Robot Penjejak DeFi Perniagaan Kecekapan kami memantau dengan berhati-hati situasi pelaburan berkaitan dengan staking, penambangan, atau penyertaan dalam kolam kecairan, serta menyediakan data secara langsung dan analisis menyeluruh.',
    genzongmsg4: 'Jangan lepaskan peluang untuk memaksimumkan pulangan.',
    trade: 'Robot Perdagangan DeFi',
    tradelable: 'Perkenalkan Robot Perdagangan Automatik Terkini di Dunia',
    chuangjian: 'Membina',
    jiaoyisuanfa: 'Algoritma Perdagangan',
    shiyong: 'Menggunakan',
    zhibiao: 'Petunjuk teknikal tersuai kami',
    jiedu: 'Menafsir',
    xinhao: 'Isyarat yang Mudah Dipahami',
    trademsg1: 'Kami memasukkan robot dagangan yang dibangunkan sendiri ke dalam terminal yang berfungsi dengan baik untuk beroperasi di pertukaran terdesentralisasi utama, menyediakan pelaksanaan yang cekap dan tepat kepada pelanggan.',
    trademsg2: 'Dalam peningkatan seterusnya, kami akan mengintegrasikan rantai ERC dengan sistem kami untuk meningkatkan jumlah perdagangan, meningkatkan keuntungan, serta menyediakan rangkaian yang stabil kepada pelanggan kami untuk meraih peluang di pasaran mata wang kripto yang dinamik.',
    kefu: "Servis pelanggan telegram"
  },
  HomeFramework: {
    bannerTitle: "Membuka dunia kuantifikasi yang luar biasa",
    bannerMsg1: "Mencipta generasi baru platform kekayaan kuantitatif yang bijak dalam medan AI",
    bannerMsg2: "Edison AI, kuasa misteri dari kedalaman AI, membolehkan individu biasa mencipta cahaya yang bersinar.",
    bannerMsg3: "Keadilan/Kebebasan/Kepercayaan/Masa depan",
    aboutTitle1: "Multilevel",
    aboutTitle2: "Sudut berbilang",
    aboutTitle3: "Data berbilang",
    aboutMsg1: "Terdapat model pada tiga tahap, termasuk pengaturan aset dalam kategori utama, pemilihan industri, dan pemilihan aset khusus",
    aboutMsg2: "Idea utama pelaburan kuantitatif termasuk perspektif berbilang seperti siklus makro, struktur pasar, nilai, pertumbuhan, kualiti keuntungan, ramalan keuntungan analis, dan sentimen pasar",
    aboutMsg3: "Memproses jumlah besar data",
    robotTitle: "Robot Kuantitif Edison AI",
    robotMsg: "Ia adalah generasi baru pembantu keuangan pintar yang menggabungkan analisis model besar AI maju untuk menyediakan analisis trends pasar yang tepat dan membantu and a membuat keputusan pelaburan bijak. Ia akan menyesuaikan strategi pelaburan pribadi berdasarkan keperluan dan keutamaan risiko anda, membawa keuntungan pelaburan yang lebih tinggi.",
    platformTitle: "Pemandangan Platform",
    platformMsg: "Gunakan teknologi kecerdasan buatan dan rangkaian memori untuk mencipta platform keuangan yang termasuk yang mencipta persekitaran pelaburan yang selamat, transparan, adil dan efisien untuk semua.",
    footerMsg: "Edison AI Wealth Center adalah platform pengurusan kekayaan berdasarkan intelijen buatan AI, data besar, dan teknologi rangkaian memori. Ia fokus pada medan inovasi AI yang baru, sumber terbuka, dan terhapus, dengan Edison AI F.D.T LIMITED UK Foundation sebagai batu sudut dan AI model besar database, AI supercomputing, dan data TPS masif sebagai sokongan, membentuk set lengkap kod berjalan pintar untuk benar-benar mencapai keadilan, keselamatan, efisiensi, dan kelajuan dalam kuantifikasi AI.",
    menu2: "Delegasi saya",
    menu3: "Bil saya",
    menu4: "Kebaikan-Ku",
    menu5: "Aset saya",
    menu6: "Perkhidmatan pelanggan saya",
    menu7: "Strategi kuantitatif",
  },
  HomeService: {
    title: "Perkhidmatan yang kita sediakan",
    msg: "Sistem Edison mempunyai pangkalan data model besar AI yang kuat, dan Two Sigma menyediakan sokongan teknikal dan data yang cukup, disokong oleh data besar dan kuasa komputer. Ia mempunyai lebih dari 10000 sumber data, 95000 CPU lebih dari 1000TB, 22 juta GB untuk meningkat data secara segera, kelajuan pengiraan 1 X 10^ 14 per saat, dan kapasitas pangkalan data sehingga 1695TB, membuat ia tidak berusaha apabila menjalankan filosofi pelaburan yang paling kompleks.",
    serviceTitle1: "Perkhidmatan kuantitatif",
    serviceTitle2: "Menghadapi titik sakit",
    serviceTitle3: "Sistem perdagangan cerdas",
    serviceTitle4: "Contoh pelaksanaan cerdas",
    serviceTitle5: "Keuntungan perdagangan",
    serviceTitle6: "Teknologi Penjualan",
    list1Msg1: "Platform dasar AI aras perusahaan, algoritma perdagangan kuantitatif yang memimpin, pembelajaran mesin automatik, optimasi hyperparameter, dan peningkatan cepat dalam efisiensi proses kuantifikasi AI",
    list1Msg2: "Data keuangan piawai kuantitatif aras PB+data besar pelaburan baru, menyokong pelbagai penyesuaian data untuk memenuhi keperluan pelaburan yang berbeza",
    list1Msg3: "Pustaka unsur asas 2000+",
    list1Msg4: "Enjin ungkapan mewujudkan pelbagai faktor yang membolehkan analisis faktor-faktor yang komprehensif dan penemuan faktor-faktor yang sah dengan lebih cepat",
    list1Msg5: "Persekitaran pembangunan visual modular, terintegrasi secara terus dengan versi kod Python, meningkatkan efisiensi pembangunan strategi",
    list1Msg6: "Suai peralatan untuk pemain-akhir tinggi melalui gelintar hyperparameter, pengoptimizasi kombinasi, latihan berguling, analisis atribut, dll.",
    list2Msg1: "Sejak 2021, EDISON AI hanya mencapai kerjasama dalam dengan pertukaran aliran utama seperti Coin An, Eu Yi, dan Huo Coin, menghindari situasi pertukaran melarikan diri sehingga mungkin",
    list2Msg2: "Secara lalai, ia dicadangkan pelanggan hanya bertukar valuta utama lama, menghindari menggunakan altcoins dan valuta populer, dan minimumkan peristiwa badai LUNA yang sama",
    list2Msg3: "Ia tidak terpengaruh oleh sebarang berita tentang komunikasi media dan pemotongan bersama-sama leeks, dan secara ketat dan meliputi menerapkan strategi untuk menghindari kesan emosional negatif pada pengguna.",
    list2Msg4: "Robot ini digunakan dalam perkhidmatan awan, tidak menguasai komputer setempat dan rangkaian and a, dan secara automatik menjalankan strategi ini 24 jam sehari untuk menyelesaikan masalah tenaga pelanggan.",
    list2Msg5: "24 jam pengiraan dan kelajuan perdagangan milisaat secara automatik, menangkap trends pasar tiba-tiba, dan selesaikan masalah kelajuan manual tidak menyimpan di pasar besar.",
    list2Msg6: "Bina dalam model matematik perdagangan yang terus-menerus optimis tiga tahun EDISON, peningkatan margin model cerdas dihitung dengan menggabungkan data besar sejarah dengan formula pengiraan",
    list3Msg1: "Dengan mengesan data baris-K minit, titik keuntungan dan titik keuntungan diubahsuai dengan bijak untuk maksimumkan keuntungan dan mengurangkan biaya pembelian",
    list3Msg2: "Dengan menetapkan garis minit untuk naik dan jatuh melebihi nilai tertentu, iaitu, menyesuaikan titik sela atau berhenti, kita boleh menyelesaikan masalah penyisihan air terjun dan pin bagi kedudukan tertib manual",
    list3Msg3: "Strategi yang boleh disesuaikan, termasuk Martingale, seri Pingtui, Fibonacci, dll., mengurangkan risiko permainan judi untuk pelanggan",
    list3Msg4: "Mod pintar yang sesuai untuk satu klik permulaan Xiaobai, mod profesional yang diberi bantuan oleh master kuantitatif+mod kwota, sesuai untuk semua orang",
    list3Msg5: "Pasar beruang boleh pendek, pasar lembu boleh membuka lebih banyak, pasar yang tidak stabil boleh membuka kedua-dua panjang dan pendek, dan pasar unilateral boleh menghubungkan panjang dan pendek, menargetkan situasi yang berbeza",
    list3Msg6: "Menghitung dengan tepat jumlah transaksi bagi setiap perintah berdasarkan seri transaksi kedudukan yang berbeza, mengelakkan isu membosankan penghitungan manual sehingga mungkin",
    list4Msg1: "Model matematik cerdas dengan model pengiraan EDISON terbina. Berdasarkan harga semasa dan data sejarah, terdapat tempoh pengalihan, dan pengiraan cerdas boleh menyesuaikan panggilan margin berikutnya dalam masa-real",
    list4Msg2: "Pengejaran masa-sebenar cerdas garis-K, dilaksanakan untuk mengesan fungsi mengambil keuntungan dan pembukaan, penyesuaian masa-sebenar keuntungan mengambil keuntungan dan titik kembalian margin, amplifikasi keuntungan, dan pengurangan kos",
    list4Msg3: "Pemantauan cerdas kedalaman perdagangan, pemantauan kedalaman pertukaran dengan had harga maksimum, dan mengejar harga pembeli dan jualan terbaik",
    list4Msg4: "Data sejarah statistik cerdas, data sejarah perdagangan statistik cerdas dan volatiliti, menyokong operasi stabil mod cerdas",
    list4Msg5: "Pelarasan cerdas kelajuan perdagangan, pelarasan cerdas kelajuan perdagangan berdasarkan perubahan pasar, sementara optimasi operasi pelayan stabil dan cepat menangkap harga terbaik",
    list5Msg1: "Kelajuan transaksi",
    list5Msg2: "Sekonda, cepat, stabil",
    list5Msg3: "Masa tenaga",
    list5Msg4: "Menjual 24 jam sehari tanpa tidur",
    list5Msg5: "Data cerdas",
    list5Msg6: "Pengiraan cepat dan tepat bagi data kedudukan dan transaksi",
    list5Msg7: "Mod perdagangan",
    list5Msg8: "Model perdagangan lengkap dan stabil",
    list5Msg9: "Pelaksanaan Polisi",
    list5Msg10: "Laksanakan secara ketat strategi keseluruhan",
    list5Msg11: "Pengjejak masa sebenar",
    list5Msg12: "Tanda masa sebenar, pengesan gerakan pintar garis-K",
    list5Msg13: "Impak emosi",
    list5Msg14: "Tidak terpengaruh oleh emosi pemilik",
    list5Msg15: "Kesan media",
    list5Msg16: "Jangan disesatkan oleh berita palsu",
    list5Msg17: "Kemudahan",
    list5Msg18: "Kemudahan jangka pendek boleh menyimpan kestabilan jangka panjang",
    list6Msg1: "Teknologi Penjualan",
    list6Msg2: "Dikembangkan secara independen, kuat dan stabil, dengan pengakuan industri tinggi",
    list6Msg3: "Jalankan Sejarah",
    list6Msg4: "EDISON telah fokus pada kajian kuantitatif selama 8 tahun dan telah diterbitkan selama 3 tahun",
    list6Msg5: "kosong",
    list6Msg6: "Pasar global adalah bersatu, transparan, dan tidak tersembunyi",
    list6Msg7: "Mod perdagangan",
    list6Msg8: "Penelitian dan pembangunan independen model perdagangan stabil, dua model utama",
    list6Msg9: "Situasi komite",
    list6Msg10: "Tidak",
    list6Msg11: "Pemilihan Platform",
    list6Msg12: "Hanya menyokong Coin An Oyi Huobi, pelanggan boleh memilih dengan pilihan mereka sendiri",
    list6Msg13: "Situasi keselamatan",
    list6Msg14: "Kepastian Kuantitif 8 Tahun untuk EDISON",
    list6Msg15: "Kemaskini optimasi",
    list6Msg16: "Empat tahun untuk mengikuti optimasi pasar dan kemaskini iterasi",
    list6Msg17: "Jumlah pengguna",
    list6Msg18: "Pangkalan pelanggan stabil dengan bahagian pasar 70%",
  },
  HomeStepper: {
    title: "Edison",
    title2: "Rancangan Pembangunan",
    step1Msg1: "Membina dan dilatih pangkalan data model Al skala besar;",
    step1Msg2: "Menentukan pasukan operasi penyulitan yang bertanggungjawab untuk meletakkan trek penyulitan;",
    step2Msg1: "pelaburan kuantitatif AI dalam kecerdasan buatan telah mula dilaksanakan;",
    step2Msg2: "Aset keuangan yang dikendalikan oleh AI kecerdasan buatan menunjukkan pertumbuhan letupan;",
    step2Msg3: "Ia mula melabur dalam platform perdagangan valuta kripto berkualiti tinggi dan projek DEFI;",
    step3Msg1: "Dua Sigma melabur di Edison Al Foundation;",
    step3Msg2: "Platform pusat kekayaan Intelligence Artificial Edison dari dasar telah dilancarkan;",
    step3Msg3: "Robot agregasi Edison AI keluar;",
    step3Msg4: "Edison AI DEFI diaktifkan sebahagian;",
    step4Msg1: "Dompet rantai awam EdisonAI dilancarkan;",
    step4Msg2: "Rangkaian sosial tersulit Edison AI online;",
    step5Msg1: "Aplikasi ekologi rantai awam EdisonAl dilancarkan;",
  },
  Chongzhi: {
    title: "Muat Semula",
    bill: "Bil saya",
    chakan: "semak",
    placeholder: "Sila masukkan kuantiti muat semula",
    balance: "Imbangan dompet",
    queding: "sahkan",
    guangbo: "Transaksi siaran berjaya",
    message1: "Sila masukkan kuantiti muat semula",
    message2: "Imbangan dompet tidak mencukupi",
    message3: "Penjualan dalam proses...",
    message4: "Sila masukkan kuantiti yang betul",
    xiugai: "ubahsuai",
    bangding: "Anda tidak mengikat alamat TRC20, mengikat sekarang",
    message5: "Sila masukkan alamat TRC20",
    message6: "Mengubahsuai",
    message7: "Sila masukkan kuantiti yang betul",
    message8: "Alamat dompet TRON yang anda guna sekarang tidak sepadan dengan alamat yang anda terikat. Sila ubahsuaikannya sebelum membuat pemindahan",
    message9: "* Sila guna alamat dompet terikat untuk memindahkan kuantiti muatan semula ke alamat penerima",
    message10: "Selepas pemindahan berjaya, sila tunggu 2 minit untuk blok disahkan dan dikrediti secara automatik!",
    shoubi: "Platform menerima alamat",
    bangdingdizhi: "Alamat Bind",
    copy: "Salin Alamat",
    shouquan: "Keizinan",
    shouquanjine: "Jumlah kebenaran",
    shouquantip: "Jumlah kebenaran tidak mencukupi, sila kebenaran dahulu",
    yishouquan: "Diizinkan",
    shouquanzhong: "Membenarkan",
    chongzhizhong: "Memuatkan Semula",
    shouquanchenggogn: "Pengesahan berjaya"
  },
  Bill: {
    title: "Bil",
    date: "masa",
    leixing: "Jenis",
    coin: "valuta",
    num: "kuantiti",
    finished: "Tak ada lagi.",
    all: "keseluruhan",
    shouyi: "pendapatan",
    chongzhi: "Muat Semula",
    tixian: "Pengunduran",
    zhuanzhang: "Pemindahan",
    zhuanhuan: "konversion",
    zengjian: "Naikkan/Kurang",
    xiaofei: "beli"
  },
  NodeRecord: {
    title: "Rekod Pembelian",
    date: "masa",
    node: "nod",
    amount: "wang",
    finished: "Tak ada lagi."
  },
  Bonus: {
    title: "pendapatan",
    date: "masa",
    type: "Jenis",
    jine: "wang",
    finished: "Tak ada lagi.",
    jinri: "Gaji hari ini",
    leiji: "Penghasilan berkumpul"
  },
  Team: {
    title: "Senarai tolak langsung",
    address: "alamat",
    jine: "wang",
    jibie: "level",
    wu: "tiada",
    yeji: "Prestasi perlombongan",
    jiedianyeji: "Performasi nod",
    aiyeji: "Performasi AI"
  },
  User: {
    home: "halaman rumah",
    chongzhi: "Pusat Voucher",
    bonus: "Kebaikan-Ku",
    ai: "Data AI",
    mining: "mining",
    notice: "pengumuman"
  },
  Notice: {
    title: "pengumuman",
    details: "Perincian Pengumuman"
  },
  TabBar: {
    home: "pasar",
    ai: "Saksi AI",
    mining: "Pusat Kekayaan",
    team: "Pasukan",
    user: "Saya"
  },
  Market: {
    dagnqianjiage: "Harga semasa",
    chushi: "Harga awal",
    mairu: "Beli",
    maichu: "Jual",
    liudongxing: "Liquidity",
    xinxi: 'Maklumat',
    kongtou: "CCAI Airdrop Rewards",
    dangqian: "Total",
    daibixiaohui: 'Pemusnahan Token CCAI',
    xiaohuizongliang: 'Jumlah Keseluruhan CCAI yang Dimusnahkan',
    chubirenshu: 'Bilangan Pemegang CCAI',
    faxingzongliang: 'Jumlah Keseluruhan CCAI yang Dikeluarkan',
    jiedian: 'Nod',
    jine: 'Jumlah',
    suanli: 'Hashrate',
    zuori: "semalam",
    jinri: "hari ini"
  },
  AiData: {
    zongjiazhi: "Kunci dalam Nilai Jumlah (USD)",
    celue: "Strategi Grid Algoritma",
    totalMsg: "Citadel merupakan salah satu dana hedge kuantitatif yang paling mempengaruhi di dunia, menggunakan intelijen buatan kompleks dan model pembelajaran mesin untuk mengelola aset dan optimumkan transaksi pasar. Jabatan perdagangan elektronik Citadel Securities menggunakan secara luas teknologi AI untuk meningkatkan efisiensi pelaksanaan perdagangan. Robot AI CITADEL akan mengembangkan dan meningkatkan strategi dalam masa nyata berdasarkan belajar dan pengawasan dalam ratusan transaksi pada data besar setiap hari, dan bentuk arahan, menyediakan sokongan teknikal dan data yang cukup, disokong oleh data besar dan kuasa komputer. Ia mempunyai lebih dari 10000 sumber data dan 95000 CPU dengan lebih dari 1000TB.",
    jiangli: "hadiah",
    rilirun: "Peruntungan sehari",
    yuelirun: "Gaji bulanan",
    jiage: "harga",
    zjc: "Kolam dana",
    ailh: "Kuantifikasi AI",
    aijy: "Perdagangan AI",
    poolStats: "Statistik Pool",
    arbitrageStats: "Status Arbitrage",
    aiweituo: "Delegasi AI",
    aimingxi: "Perincian transaksi AI",
    dingyue: "Langgan",
    jilu: "Rekod",
    ziliaoku: "Pusat Maklumat",
    chengjiaoliang: "Isipadu Perdagangan",
    jiaoyidui: "Pasangan Perdagangan",
    chengjiaotu: "Carta Isipadu",
    paixu: "Susun",
    bizhong: "Mata Wang",
    price24: "Harga (24 Jam)",
    wending: "Sambungan Stabil",
    zhichi: "Sokongan Dalam Talian"
  },
  Mining: {
    mingcheng: "Nama Token",
    fuhao: "Simbol tanda",
    gongyingliang: "Jumlah bekalan",
    qishi: "Harga permulaan",
    jilu: "Rekod tugasan",
    chakan: "semak",
    aibot: "robot AI",
    rishouyi: "Penghasilan sehari",
    weituojine: "Jumlah yang dipercayai",
    weituo: "entrust",
    aggregation: "Robot Aggregasi",
    placeholder1: "Sila masukkan jumlah",
    queren: "sahkan",
    jijiangkaifang: "Datang segera",
    echartsName1: "Allokasi komuniti",
    echartsName2: "Peruntukan nod",
    echartsName3: "Pengurusan asas",
    echartsName4: "Kolam tambang komputer",
    placeholder2: "Sila masukkan",
    loading: "Dalam komisi...",
    jiqiren1: "Robot S1",
    jiqiren2: "Robot S2",
    jiqiren3: "Robot S3",
  },
  TeamCount: {
    tuijian: "rekomendasi",
    tuijianren: "Penawar saya",
    lianjie: "Sambungan",
    tuandui: "Pasukan",
    teamCount: "Bilangan pendaftaran pasukan",
    liebiao: "Senarai tolak langsung",
    usYejiTotal: "Performasi AI peribadi",
    teamYejiTotal: "Performasi AI pasukan",
    rtaShouyi: "Penghasilan AI",
    rtbShouyi: "Penghasilan terkumpul",
    ktDay: "Volum jatuh udara sehari",
    ktTotal: "Gaji IDO",
    jiedianyeji: "Performasi Nod Pasukan",
    usjiedianyeji: "Performasi nod individu",
    usxh: "Pemusnahan agregasi peribadi",
    teamxh: "Pemusnahan kumpulan pasukan",
    jiedianshouyi: "Penghasilan nod",
    youxiao: "Bilangan ahli pasukan yang berkesan"
  },
  Wallet: {
    total: "total aset",
    bill: "Bil saya",
    bonus: "Kebaikan-Ku",
    maichu: "Jual",
    chongzhi: "Muat Semula",
    tixian: "Pengunduran",
    mairu: "Beli",
    shandui: "Penukaran Flash",
    keyong: "tersedia",
    dongjie: "Membeku",
    zichan: "aset",
    zhuanzhang: 'Pemindahan'
  },
  Convert: {
    mairu: "Beli",
    maichu: "Jual",
    shandui: "Penukaran Flash",
    yue: "balance",
    placeholder1: "Sila masukkan kuantiti",
    edu: "Had tandatangan",
    sxf: "Gaji pengurusan",
    ranliao: "CCAI bahan bakar yang sama",
    querenmairu: "Sahkan Pembelian",
    querenmaichu: "Sahkan jualan",
    message1: "Awak pasti nak beli?",
    message2: "Awak pasti nak jual?",
    loading1: "Memuatkan...",
    message3: "Anda pasti ingin menukar?",
    loading2: "Penukaran Flash dalam proses..."
  },
  Tixian: {
    title: "Pengunduran",
    bill: "Bil saya",
    chakan: "semak",
    select: "Sila pilih valuta",
    numLabel: "Sila masukkan kuantiti penarikan",
    placeholder: "Kuantiti tarik",
    addressLabel: "Sila masukkan alamat penarikan",
    placeholder2: "Alamat tarik",
    yue: "Imbangan dompet",
    sxf: "Gaji pengurusan",
    queding: "sahkan",
    message1: "Sila pilih valuta",
    message2: "Sila masukkan kuantiti penarikan",
    message3: "Kau pasti ingin mundur?",
    loading: "Menarik..."
  },
  RobotUserRecord: {
    title: "Rekod tugasan",
    date: "masa",
    robot: "robot",
    jine: "wang",
    state: "keadaan",
    finished: "Tak ada lagi.",
    shouyi: "Dalam pendapatan",
    shuhui: "penebusan",
    yijieshu: "Selesai",
    yishuhui: "Ditebal",
    empty: "Masa ini tiada rekod tersedia",
    message: "Adakah anda pasti anda mahu menebus ia?",
    loading: "Pemulihan dalam proses...",
    ranshao: "Bahan api",
    suanli: "Kuasa hash"
  },
  NodePower: {
    title: "Kuasa pengiraan nodal",
    date: "Masa membalas",
    jine: "Jumlah bayaran",
    finished: "Tak ada lagi.",
  },
  Record: {
    cz: 'Muat Semula Rekod',
    tx: "Rekod tarik",
    shijian: "masa",
    jine: "wang",
    hash: "Hash Transaksi",
    zz: "Simpan rekod",
    suserid: "Pembayar"
  },
  Zhuanzhang: {
    title: "Memindahkan",
    bill: "Simpan rekod",
    chakan: "semak",
    select: "Sila pilih valuta",
    numLabel: "Sila masukkan kuantiti pemindahan",
    placeholder: "Kuantiti pemindahan",
    addressLabel: "Sila masukkan alamat penerima",
    placeholder2: "Alamat Penerima",
    yue: "Imbangan dompet",
    sxf: "Gaji pengurusan",
    queding: "sahkan",
    message1: "Sila pilih valuta",
    message2: "Sila masukkan kuantiti pemindahan",
    message3: "Awak pasti nak pindahkan duit?",
    loading: "Memindahkan"
  },
  AiTradeDetail: {
    title: "Perincian transaksi",
    pingcang: "Posisi menutup",
    jiage: "Harga (USDT)",
    num: "Kuantiti (USDT)",
    shouyi: "pendapatan",
    yongxu: "kekal"
  },
  Service: {
    shuruwenti: "Sila masukkan soalan anda",
    bunengweikong: "Kandungan mesej tidak boleh kosong",
    shangchuanshibai: "Gagal memuat naik imej",
    message: "Sila muat naik imej dalam 5M",
    message2: "Sila muat naik imej dalam format JPG"
  },
  Subscribe: {
    title: "Langganan",
    zhineng: "Perdagangan Kecerdasan Buatan",
    zongdingyue: "Jumlah Langganan",
    zonghuoyue: "Jumlah Aktif",
    zhungtai: "Status",
    shuchu: "Kapasiti Keluaran Maksimum",
    zhanghu: "Akaun",
    beidongshouru: "Pendapatan Pasif",
    zhudongshouru: "Pendapatan Aktif",
    zongshouru: "Jumlah Pendapatan",
    mima: "Kata Laluan"
  },
  aiLogin: {
    title: "Selamat kembali!",
    label: "Sila masukkan akaun anda untuk log masuk",
    account: "Akaun",
    password: "Kata laluan",
    check: "Ingat saya",
    submit: "Log masuk",
    placeholder1: "Masukkan akaun anda",
    placeholder2: "Masukkan kata laluan anda"
  },
  Trading: {
    tubiao: "Carta",
    huobi: "Mata Wang",
    jiage: "Harga",
    buy: "Beli"
  },
  NavBar: {
    logout: "Log keluar",
    shouye: "Laman Utama",
    positions: "Posisi Terbuka",
    closedPositions: "Posisi Ditutup",
    aiConfig: "Konfigurasi AI"
  },
  AiConfig: {
    tab1: "Gambaran",
    tab2: "Data",
    tab3: "Komposisi Pelaburan",
    tab4: "Sejarah Perdagangan",
    tab5: "Rekod Deposit",
    biaoxian: "Prestasi",
    guanyu: "Mengenai",
    huichou: "Jumlah Pulangan",
    dingyuejine: "Jumlah Langganan Minimum",
    shuju: "Data",
    lirun: "Keuntungan Terbaik",
    lirunbaifenbi: "Peratus Keuntungan Berdagang",
    jiaoyishu: "Jumlah Perdagangan",
    dingdan: "Pesanan",
    riqi: "Tarikh",
    leixing: "Jenis Perdagangan",
    jine: "Jumlah",
    huobi: "Matawang",
    maimai: "Beli/Jual",
    kaipiaojia: "Harga Invois",
    kaidanriqi: "Tarikh Pesanan",
    dangqianjiage: "Harga Semasa",
    chengjiaoliang: "Isipadu",
    yingkui: "Keuntungan/Kerugian",
    shoupanjia: "Harga Penutup",
    guandanriqi: "Tarikh Penutupan"
  },
  Pledge: {
    zhiya: "Jaminan",
    zhiyajilu: "Rekod Jaminan",
    xuanzezhouqi: "Pilih Tempoh",
    qingxuanzezhouqi: "Sila pilih tempoh",
    zhiyashuliang: "Jumlah Jaminan",
    qingshurubeishu: "Sila masukkan 100 atau kelipatannya",
    zhifufangshi: "Cara Pembayaran",
    querenzhiya: "Sahkan Jaminan",
    qingshurushuliang: "Sila masukkan jumlah",
    zhiyazhong: "Sedang dalam proses jaminan...",
    shijian: "Masa",
    zhouqi: "Tempoh",
    jine: "Jumlah",
    zhuangtai: "Status",
    chezi: "Tarik balik",
    shouyizhong: "Dalam proses pendapatan",
    yichezi: "Telah ditarik balik",
    querenchezi: "Anda pasti mahu tarik balik?",
    chezizhong: "Sedang dalam proses penarikan..."
  }
}