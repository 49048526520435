<template>
  <div class="trade">
    <div class="server">
      <div class="server-item">
        <div class="server-top">Server 1</div>
        <div class="server-label">Volume <span>{{ pageData.volume1 }}%</span></div>
        <div class="server-val">{{ pageData.server1 }}</div>
      </div>
      <div class="server-item">
        <div class="server-top">Server 2</div>
        <div class="server-label">Volume <span>{{ pageData.volume2 }}%</span></div>
        <div class="server-val">{{ pageData.server2 }}</div>
      </div>
      <div class="server-item">
        <div class="server-top">Server 3</div>
        <div class="server-label">Volume <span>{{ pageData.volume3 }}%</span></div>
        <div class="server-val">{{ pageData.server3 }}</div>
      </div>
      <div class="server-item">
        <div class="server-top">Server 4</div>
        <div class="server-label">Volume <span>{{ pageData.volume4 }}%</span></div>
        <div class="server-val">{{ pageData.server4 }}</div>
      </div>
    </div>

    <div class="coin">
      <div class="coin-row">
        <div class="coin-col">
          <img src="@/assets/img/coin/BTC.png" width="20" />
          <div>Bitcoin（USDT）</div>
        </div>
        <div class="coin-col">
          <img src="@/assets/img/coin/ETH.png" width="20" />
          <div>Ethereum（USDT）</div>
        </div>
        <div class="coin-col">
          <img src="@/assets/img/coin/LTC.png" width="20" />
          <div>LTC（USDT）</div>
        </div>
        <div class="coin-col">
          <img src="@/assets/img/coin/BNB.png" width="20" />
          <div>WBNB（USDT）</div>
        </div>
      </div>
    </div>

    <div style="margin-bottom: 5px;">
      <OfficialScroll :showTitle="false"></OfficialScroll>
    </div>

    <div class="tabs">
      <div class="tabs-item" @click="tolink('Mining')">{{ $t('AiData.aiweituo') }}</div>
      <div class="tabs-item" @click="tolink('AiTradeDetail')">{{ $t('AiData.aimingxi') }}</div>
    </div>

    <div class="list">
      <div class="bot-item" v-for="item in list" :key="item.id">
        <div class="bot-title">
          <div class="row items-center">
            <img :src="item.img" />
            {{ item.name }}
          </div>
          <img src="@/assets/img/base/qushi.gif" style="width: 30px;" />
        </div>
        <div class="bot-cell">
          <div>
            <div>{{ $t('AiData.botTitle') }}</div>
            <div>{{ item.count }}</div>
          </div>
        </div>
        <div class="bot-cell">
          <div>
            <div>{{ $t('AiData.rilirun') }}</div>
            <div>{{ item.rilirun }}%</div>
          </div>
          <div class="text-right">
            <div>{{ $t('AiData.yuelirun') }}</div>
            <div>{{ item.yuelirun }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import OfficialScroll from '@/views/official/components/OfficialScroll'

export default {
  name: "AiDataTradeList",
  props: {
    pageData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    OfficialScroll
  },
  setup() {
    return {
      list: ref([
        {
          id: 1,
          img: require("@/assets/img/base/ai1.png"),
          name: "Binance Bot (10Units)",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 20
        },
        {
          id: 2,
          img: require("@/assets/img/base/ai2.png"),
          name: "Coinbase Bot (5Units)",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 80
        },
        {
          id: 3,
          img: require("@/assets/img/base/ai3.png"),
          name: "Kraken Bot (2Units)",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 220
        },
        {
          id: 4,
          img: require("@/assets/img/base/ai4.png"),
          name: "Kucoin Bot (1Units)",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 500
        },
        {
          id: 5,
          img: require("@/assets/img/base/ai5.png"),
          name: "Bitfinex Bot (1Units)",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 1000
        },
        {
          id: 6,
          img: require("@/assets/img/base/ai6.png"),
          name: "Uniswap Bot",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 1800
        },
        {
          id: 7,
          img: require("@/assets/img/base/ai7.png"),
          name: "PancakeSwap Bot",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 1000
        },
        {
          id: 8,
          img: require("@/assets/img/base/ai8.png"),
          name: "SushiSwap Bot",
          count: 0,
          agpt: 0,
          rilirun: "0",
          yuelirun: "0",
          price: 1600
        }
      ])
    }
  },
  mounted() {
    this.getAiData()
  },
  methods: {
    showTips() {
      this.$q.dialog({
				message: "即将开放展示",
			});
    },
    tolink(path) {
      this.$router.push(path)
    },
    getAiData(){
      let _this = this
      _this.$request.post(
        "api/SystemSetting/GetAiData",{},
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
					}
          let bsModList = res.data.data
          /* console.log(_this.list.length)
          console.log(bsModList.length) */
          for(let i = 0; i < bsModList.length; i++){
            //console.log(bsModList[i])
            _this.list[i].count = bsModList[i].count
            _this.list[i].rilirun = bsModList[i].rilirun
            _this.list[i].yuelirun = bsModList[i].yuelirun
            _this.list[i].agpt = bsModList[i].agpt
          }
          
        }
      )
    },
  }
}
</script>

<style scoped>
.trade {
  margin-top: 15px;
}

.bot-item {
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 10px 12px;
  border-radius: 6px;
  /* box-shadow: 0 0 6px 0 rgba(0,0,0,.37); */
  background: url(~@/assets/img/base/aiBg.png) no-repeat;
  background-size: contain;
  background-position: 0 100%;
  color: #fff;
}

.bot-item:last-child {
  margin: 0;
}

.bot-cell {
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
}

.bot-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bot-title img {
  margin-right: 8px;
  width: 22px;
}

.bot-jine {
  font-size: 25PX;
  font-weight: bold;
}

.bot-jine span {
  font-size: 14px;
}

.bot-item:nth-child(1) {
  background-color: rgb(242, 190, 17);
}

.bot-item:nth-child(2) {
  background-color: rgb(52, 168, 243);
}

.bot-item:nth-child(3) {
  background-color: rgb(84, 71, 209);
}

.bot-item:nth-child(4) {
  background-color: rgb(250, 137, 105);
}

.bot-item:nth-child(5) {
  background-color: rgb(204, 138, 226);
}

.bot-item:nth-child(6) {
  background-color: rgb(255, 2, 123);
}

.bot-item:nth-child(7) {
  background-color: rgb(58, 177, 157);
}

.bot-item:nth-child(8) {
  background-color: rgb(130, 183, 217);
}

.server {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.server-item {
  width: 24%;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  overflow: hidden;
}

.server-item > div {
  padding: 0 4px;
}

.server-top {
  height: 20px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #27304E;
}

.server-label {
  margin-top: 4px;
  color: gray;
}

.server-val {
  font-size: 15px;
  font-weight: bold;
  color: #6EADFD;
}

.server-label span {
  color: #fff;
  padding: 0 2px;
  border-radius: 3px;
  background-color: rgb(0, 182, 145);
}

.coin {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
}

.coin-row {
  display: flex;
  flex-wrap: wrap;
}

.coin-col {
  padding: 6px 0;
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.coin-col img {
  margin-right: 8px;
}

.trade :deep(.item-wrap) {
  margin: 0 10px;
  width: 100px;
  height: 40px;
  border-radius: 6px;
  background-color: #586ba0;
}

.trade :deep(.item-wrap img) {
  width: 60px;
}

.trade :deep(.scroll-item) {
  margin-bottom: 10px;
}

.tabs {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.tabs-item {
  text-align: center;
  text-decoration: underline;
}
</style>