<template>
    <div class="card q-mt-md">
        <div class="swipe-item" v-for="(item, index) in list" :key="index">
            <div class="card-content">
                <div style="display: flex;justify-content: space-between;">
                    <div class="name">{{ item.name }}</div>
                    <img style="width: 80px;" :src="getimg(item.cover)" />
                </div>
                <div class="row justify-between">
                    <div>
                        <div class="text-gray">日收益</div>
                        <div>{{ item.shouyiDay }}CCAI / {{ item.dayOut }}天</div>
                        <div>总量：{{item.stockMax}} 台</div>
                    </div>
                    <div class="text-right">
                        <div class="text-gray">质押金额</div>
                        <div>{{ item.priceCcaiPledge }}CCAI质押+{{ item.priceCcaiBurn }}CCAI销毁</div>
                        <div>剩余：{{item.stock}} 台</div>
                    </div>
                </div>
                <div class="btn" @click="submit(item)">购买</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { Picker, Popup } from 'vant';
import Web3Helper from "@/assets/js/web3helper.js";

export default {
    components: {
        [Picker.name]: Picker,
        [Popup.name]: Popup,
    },
    setup() {
        return {
            us: ref(null),
            list: ref([])
        }
    },
    mounted() {
        this.us = JSON.parse(this.$utils.getloc("us"))
        this.getdata()
    },
    methods: {
        getimg(imgname) {
            let path = this.$config.send_url + "Upload/" + imgname;
            if (typeof imgname == "undefined") {
                path = this.defaulttx;
            }
            return path;
        },
        getdata() {
            let _this = this
            _this.$request.post(
                "api/PledgeCcaiGoods/List",
                {
                    token: _this.$utils.getloc("token"),
                    userid: _this.us.userid,
                    pageIndex: 1,
                    pageSize: 10
                },
                (res) => {
                    console.log(res)
                    if (res.data.code == 100) {
                        let data = res.data.data
                        console.log(data)
                        _this.list = data
                    }
                }
            )
        },
        submit(item) {
            let _this = this
            _this.$q.dialog({
                title: `确定要购买吗？`,
                message: "请选择支付方式",
                options: {
                    type: 'radio',
                    model: '6',
                    items: [
                        { label: `CCAI`, value: '6', },
                        { label: `LP CCAI`, value: '7', },
                    ]
                },
                ok: {
                    label: '确定'
                },
                cancel: {
                    label: '取消',
                    color: 'white',
                    textColor: "black"
                },
            }).onOk((cid) => {
                _this.$q.loading.show({
                    message: "购买中..."
                })
                let web3Helper = new Web3Helper();
                web3Helper.getSign().then((signStr) => {
                    _this.$request.post(
                        "api/PledgeCcaiOrder/Buy",
                        {
                            token: _this.$utils.getloc("token"),
                            userid: _this.us.userid,
                            address: _this.us.address,
                            signStr,
                            pledgeCcaiGoodsId: item.id,
                            cid
                        },
                        (res) => {
                            _this.$q.loading.hide();
                            _this.getdata()
                            _this.$q.dialog({
                                message: res.data.msg,
                            });
                        }
                    )
                })
            })
        }
    }
}
</script>

<style scoped>
.node {
    margin-top: 15px;
    padding: 0 15px;
}

.node-item {
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.node-header {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.node-header img {
    margin-right: 10px;
    width: 24px;
}

.node-content {
    border-top: 1px dashed rgba(202, 197, 197, .4);
    margin-top: 10px;
    padding-top: 10px;
}

.node-value {
    font-weight: bold;
    font-size: 13px;
}

.node-label {
    color: gray;
    font-size: 12px;
}

.node-btn {
    margin: 15px 0 10px;
    padding: 0 10px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99rem;
    color: #fff;
    background-color: #2C8B9F;
    font-size: 13px;
    word-break: break-all;
    text-align: center;
}

.mining {
    padding: 10px;
    background-color: #F5F7FA;
}

.card {
    position: relative;
    /* background-color: #fff; */
    /* background-image: url('~@/assets/img/base/arobot.png'); */
    /* background-size: 100% 100%; */
}

.card:nth-child(2) {
    /* background-image: url('~@/assets/img/base/brobot.png'); */
    /* background-size: 100% 100%; */
}

.card-content {
    margin-bottom: 15px;
    position: relative;
    padding: 20px;
    background-image: url('~@/assets/img/base/arobot.png');
    background-size: 100% 100%;
    border-radius: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.name {
    font-size: 24px;
    font-weight: bold;
    color: #1A2648;
}

.desc {
    margin-top: 40px;
    font-size: 12px;
}

.text-gray {
    color: gray;
}

.btn {
    margin: 20px 0 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99rem;
    color: #fff;
    background-color: #2C8B9F;
    font-size: 13px;
}

.indicators {
    margin-top: 20px;
    display: flex;
}

.indicators>div {
    margin-right: 6px;
    background: rgb(36, 96, 215);
    opacity: .1;
    width: 14px;
    height: 6px;
    border-radius: 99rem;
}

.indicators-active {
    opacity: 1 !important;
}

.robot-img {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 80px;
}

.card:nth-child(2) .indicators>div {
    background-color: rgb(88, 0, 200);
}

.card:nth-child(2) .btn {
    background-color: rgb(88, 0, 200);
}

.popup-box {
    padding: 10px;
}

.tr-name {
    padding: 5px 0 20px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.input-wrap {
    margin-top: 20px;
    padding: 0 16px;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.popup-btn {
    margin-top: 20px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    border-radius: 10px;
    color: #fff;
    background-color: #2C8B9F;
}
</style>