<template>
  <div class="chongzhi">
    <header-bar :showNotice="false" :showBack="true" :transparent="true"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Chongzhi.title')" label="Recharge"></title-name>
    </div>

    <div class="menu-item" @click="tolink('ChongzhiRecord')">
      <div class="menu-row">
        <img src="@/assets/img/user/menu4.png" class="menu-icon" />
        <div>{{ $t('Record.cz') }}</div>
      </div>
      <div class="row items-center">
        <div style="margin-right: 8px;">{{ $t('Chongzhi.chakan') }}</div>
        <img src="@/assets/img/user/link.png" width="19" />
      </div>
      <img src="@/assets/img/base/kl.png" class="menu-left" />
      <img src="@/assets/img/base/kr.png" class="menu-right" />
    </div>

    <div class="form">
      <div class="form-title">{{ $t('Chongzhi.title') }}</div>
      <div>
        <q-radio v-model="czlx" val="1" label="BEP-20" class="q-mr-sm" @update:model-value="change" />
        <!-- <q-radio v-model="czlx" val="2" label="TRC-20" @update:model-value="change" /> -->
      </div>
      <div v-if="czlx == 2">
        <div class="form-label">{{ $t('Chongzhi.bangdingdizhi') }}</div>
        <div class="address">
          <div class="address-text" v-if="addressTron">{{ addressTron }}</div>
          <span v-if="addressTron" @click="onShowTron">{{ $t('Chongzhi.xiugai') }}</span>
          <span v-else @click="onShowTron">{{ $t('Chongzhi.bangding') }}</span>
        </div>
      </div>
      <div v-if="czlx == 1">
        <div class="form-label">{{ $t('Chongzhi.placeholder') }}</div>
        <div class="input-wrap">
          <q-input borderless v-model="num" type="number" :placeholder="$t('Chongzhi.placeholder')" />
        </div>
      </div>
      <div v-if="czlx == 2">
        <div class="form-label">{{ $t('Chongzhi.shoubi') }}</div>
        <div class="input-wrap">
          <q-input borderless v-model="tronToAddress" readonly />
        </div>
      </div>
      <div class="row justify-between items-center q-mt-md" v-if="czlx == 1">
        <div>
          {{ $t('Chongzhi.balance') }}：<span>{{ balance }}</span>
        </div>
        <q-icon name="autorenew" size="16px" class="q-ml-sm" @click="balanceOf(us.address)"></q-icon>
      </div>
      <div class="row justify-between items-center q-mt-md" v-if="czlx == 1">
        <div>
          {{ $t('Chongzhi.yishouquan') }}：<span>{{ userAllowance }}</span>
        </div>
      </div>
      <div class="row justify-between items-center q-mt-md" style="color: #e02020;" v-if="czlx == 2">
        <div>{{ $t('Chongzhi.message9') }}</div>
        <div>{{ $t('Chongzhi.message10') }}</div>
      </div>
      <div class="btn" style="margin: 20px 0 0;" v-if="czlx == 2" @click="copy(tronToAddress)">{{ $t('Chongzhi.copy') }}
      </div>
    </div>

    <div class="row justify-between q-px-md">
      <div class="btn row-btn" @click="approve" v-if="czlx == 1">{{ $t('Chongzhi.shouquanjine') }}</div>
      <div class="btn row-btn" @click="submit" v-if="czlx == 1">{{ $t('Chongzhi.title') }}</div>
    </div>


    <q-dialog v-model="showTron" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">TRC-20</div>
        </q-card-section>
        <q-card-section>
          <q-input outlined v-model="upAddressTron" label="TRC-20" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat :label="$t('Base.quxiao')" color="primary" @click="showTron = false" />
          <q-btn flat :label="$t('Base.queding')" color="primary" @click="updateAddress" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('Chongzhi.guangbo') }}</div>
        </q-card-section>
        <q-card-actions>
          <div class="txid">
            txid: {{ txid }}
            <q-icon name="content_copy" @click="copy(txid)"></q-icon>
          </div>
        </q-card-actions>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" @click="showTxid = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 选择币种 -->
    <van-popup v-model:show="show" round position="bottom">
      <van-picker :columns="selectList" @cancel="show = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import { ref } from 'vue'
import { Picker, Popup, Uploader } from 'vant';
import Web3Helper from "../../assets/js/web3helper.js";
import tronUtils from "../../assets/js/tron-utils.js";
import useClipboard from 'vue-clipboard3';

export default {
  name: '',
  components: {
    HeaderBar,
    TitleName,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Uploader.name]: Uploader,
  },
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      zfimg: ref(""),
      show: ref(false),
      selectList: ref([]),
      select: ref(null),
      fileList: ref([]),
      balance: ref(0),
      contractInfo: ref(null),
      web3Helper: ref({}),
      swapRouterV3ContractInfo: ref({}),
      swapRouterV3Web3Helper: ref({}),
      showTxid: ref(false),
      txid: ref(""),
      inAddress: ref(""),

      userAllowance: ref(0),  //授权金额


      czlx: ref("1"),
      addressTron: ref(null),
      upAddressTron: ref(""),
      showTron: ref(false),

      tronContractInfo: ref({}),
      tronToAddress: ref(""),
      balance2: ref(0) // 波场钱包余额
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getInfo()
      this.getTronInfo()
    }
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          message: this.$t('Base.copy')
        })
      } catch (e) {
        console.log(e)
      }
    },
    back() {
      this.$router.back()
    },
    tolink(path) {
      this.$router.push(path)
    },
    onConfirm(e) {
      this.select = e
      this.show = false
    },
    change(e) {
      if (this.czlx == 2) {
        if (this.us) {
          this.getAddressTron()
        }
      }
    },
    onShowTron() {
      if (this.addressTron) {
        this.upAddressTron = this.addressTron
      }
      this.showTron = true
    },
    // 获取绑定地址
    getAddressTron() {
      let _this = this
      _this.$request.post(
        "api/Users/GetAddressTron",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
            return
          }
          let data = res.data.data.addressTron
          if (data) _this.addressTron = data
        }
      )
    },
    // 修改地址
    updateAddress() {
      let _this = this
      if (this.upAddressTron == "") {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message5'),
        });
        return
      }
      _this.$q.loading.show({
        message: _this.$t('Chongzhi.message6')
      })
      _this.$request.post(
        "api/Users/UpdateAddressTron",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          addresstron: _this.upAddressTron
        },
        (res) => {
          _this.$q.loading.hide();
          _this.$q.dialog({
            message: res.data.msg,
          })
          if (res.data.code == 100) {
            _this.addressTron = _this.upAddressTron
            _this.upAddressTron = ""
            _this.showTron = false
          }
        }
      )
    },
    // 获取波场相关信息
    getTronInfo() {
      let _this = this
      _this.$request.post(
        "api/Users/GetZzInfo",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
            return
          }
          let data = res.data.data
          _this.tronContractInfo = {
            contractAddress: data.contractAddress,
            decimals: data.decimals
          }
          _this.tronToAddress = data.inAddress;
          _this.tronBalance()
        }
      )
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          _this.inAddress = data.inAddress;
          _this.contractInfo = data.contractList.find(item => item.id == 1)
          _this.web3Helper = new Web3Helper(_this.contractInfo.abi, _this.contractInfo.address);
          _this.balanceOf(_this.us.address);

          _this.swapRouterV3ContractInfo = data.contractList.find(item => item.id == 101)
          _this.swapRouterV3Web3Helper = new Web3Helper(_this.swapRouterV3ContractInfo.abi, _this.swapRouterV3ContractInfo.address);

          _this.allowance(_this.us.address)
        }
      )
    },
    approve() {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message1'),
        });
        return
      }
      if (parseFloat(_this.num).toString() == 'NaN') {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message7'),
        });
        return
      }
      let payWei = _this.web3Helper.toWei(_this.num, _this.contractInfo.decimals);
      _this.$q.loading.show({
        message: _this.$t('Chongzhi.message3')
      })
      _this.web3Helper.contractApprove(_this.us.address, _this.swapRouterV3ContractInfo.address, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.allowance(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            title: err.code,
            message: err.message,
          });
        }
      });
    },
    allowance(address) {
      let _this = this;
      _this.web3Helper.contractAllowance(address, _this.swapRouterV3ContractInfo.address).then((wei) => {
        _this.userAllowance = _this.web3Helper.fromWei(wei, _this.contractInfo.decimals);
      });
    },
    balanceOf(address) {
      let _this = this;
      _this.web3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance = _this.web3Helper.fromWei(wei, _this.contractInfo.decimals);
      });
    },
    // 获取波场钱包
    tronBalance() {
      let that = this;
      let tronWeb = tronUtils.tronWeb;
      tronUtils.getContract(that.tronContractInfo.contractAddress).then(contract => {
        contract.methods.balanceOf(window.tronWeb.defaultAddress.base58).call().then(res => {
          that.balance2 = res / Math.pow(10, that.tronContractInfo.decimals);
        });
      });
    },
    selectSubmit() {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message1'),
        });
        return
      }
      if (parseFloat(_this.num).toString() == 'NaN') {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message7'),
        });
        return
      }
      if (this.czlx == 1) {
        this.submit()
      }
      if (this.czlx == 2) {
        this.tronChongzhi()
      }
    },
    // 波场充值
    tronChongzhi() {
      let _this = this
      let address = window.tronWeb.defaultAddress.base58
      if (_this.num > _this.balance2) {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message2'),
        });
        return
      }
      if (_this.addressTron != address) {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message8'),
        });
        return
      }
      _this.$q.loading.show({
        message: _this.$t('Chongzhi.message3')
      })
      let payWei = _this.num * Math.pow(10, _this.tronContractInfo.decimals);
      tronUtils.getContract(_this.tronContractInfo.contractAddress).then(contract => {
        contract.methods.transfer(_this.tronToAddress, payWei + '')
          .send().then((txid) => {
            _this.$q.loading.hide();
            _this.txid = txid
            _this.showTxid = true
            _this.tronBalance()
          }).catch(err => {
            _this.$q.loading.hide();
          });
      })
    },
    submit() {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message1'),
        });
        return
      }
      if (parseFloat(_this.num).toString() == 'NaN') {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.message7'),
        });
        return
      }
      if (_this.userAllowance < _this.num) {
        _this.$q.dialog({
          message: _this.$t('Chongzhi.shouquantip'),
        });
        return
      }


      let payWei = _this.web3Helper.toWei(_this.num, _this.contractInfo.decimals);
      let out = _this.num - (_this.num * 1 / 100);
      let outWei = _this.web3Helper.toWei(out, _this.contractInfo.decimals);

      _this.$q.loading.show({
        message: _this.$t('Chongzhi.message3')
      })

      _this.swapRouterV3Web3Helper.exactInputSingle(_this.us.address, "0x55d398326f99059fF775485246999027B3197955", "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d", "100", _this.inAddress, payWei.toString(), outWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.txid = txid
        _this.showTxid = true
        _this.balanceOf(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            title: err.code,
            message: err.message,
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.chongzhi {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
  padding: 0 0 20px 0;
}

.back-wrap {
  padding: 0 12px;
}

.page-content {
  margin: 24px 12px 0;
  padding: 0 1px;
  box-sizing: border-box;
  height: 57px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  border-radius: 16px;
  background-color: #000;
}

.page-item {
  height: 55px;
  line-height: 55px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
}

.page-item-active {
  color: #000;
  background-color: #91eb0d;
}

.menu-item {
  position: relative;
  margin: 24px 12px 0;
  padding: 0 20px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid #000;
  ;
  background-color: #fff;
}

.menu-row {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.menu-left {
  position: absolute;
  height: 85px;
  width: 60px;
  top: -13px;
  left: -12px;
}

.menu-right {
  position: absolute;
  height: 85px;
  width: 60px;
  top: -13px;
  right: -12px;
}

.select {
  padding: 0 20px;
  margin: 29px 12px 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
}

.form {
  margin: 32px 12px 0;
  padding: 20px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
}

.form-title {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.form-label {
  margin: 15px 0 6px;
  /* margin-bottom: 6px; */
}

.input-wrap {
  padding: 0 16px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.btn {
  margin: 32px 12px 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 14px;
  color: #fff;
  background-color: #2C8B9F;
}

.uploader-wrap :deep(.van-uploader__upload) {
  background-color: #f1f1f1;
}

.txid {
  padding: 0 10px;
  word-break: break-all;
}

.address {
  display: flex;
  margin-top: 10px;
  font-size: 13px;
  word-break: break-all;
}

.address-text {
  flex: 1;
  margin-right: 10px;
}

.address span {
  text-decoration: underline;
  color: #e02020;
}

.row-btn {
  margin: 30px 0 0;
  width: 48%;
  justify-content: space-between;
}
</style>